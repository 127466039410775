import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Paper } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { CustomButton } from '../../../components/Button';
import Grid from '@material-ui/core/Grid';


export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const AppBarStyled = styled(AppBar)`
  position: absolute;
  display: flex;
  height: 84px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 0;
  border-radius: 4px;
  margin-left: 8px;
  margin-top: -105px;
  width: 98%;
`;

export const ToolbarStyled = styled(Toolbar)`
  width: 100%;
`;


export const FormWrapperStyled = styled(Paper)`
  display: flex;
  width: 800px;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 60px;
`;

export const FormContentStyled = styled.div`
  display: flex;
  width: 100%;
`;

export const ListItemStyled = styled(ListItem)`
  display: flex;
  max-width: 890px;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 70px;
`;

export const CustomButtonStyled = styled(CustomButton)`
  width: 72px;
  font-weight: 400;
  font-size: 12;
  text-transform: uppercase;
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 0.9375rem 1.875rem;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.0625rem;
  line-height: 1.55em;
  margin-bottom: 15px;
  margin-top: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  text-transform: capitalize;
`;

export const AddOptionButton = styled(CustomButton)`
  display: flex;
  width: 104px;
  height: 29px;
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  color: '#ffffff';
`;

export const GridContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridItemIcon = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 38px;
  margin: 0 5px;
`;

export const MultiChoiceButtonContainer = styled.div`
  display: flex;
  height: 45px;
  margin-top: 30px;
  align-items: center;
`;

export const MultiChoiceErrorText = styled.div`
  display: flex;
  margin-left: 30px;
  color: #f44336;
  font-weight: 300;
`;
