import cn from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: 'auto',
    borderRadius: '0.2rem',
    maxWidth: 400,
  },

  small: {
    height: 29,
    padding: '0.40625rem 1.25rem',
    fontSize: '0.6875rem',
    lineHeight: 1.5,
  },

  regular: {
    height: 41,
    paddingLeft: 15,
    paddingRight: 15,
  },

  large: {
    height: 54,
    padding: '1.125rem 2.25rem',
    fontSize: '0.875rem',
    lineHeight: 1.333333,
    fontWeight: 400,
  },

  default: {
    backgroundColor: '#999999',
    borderColor: '#999999',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#999999',
      borderColor: '#999999',
      boxShadow: [
        '0 14px 26px -12px rgba(153, 153, 153, 0.42)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      ],
    },
  },

  primary: {
    backgroundColor: '#1049a7',
    borderColor: '#1049a7',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#1049a7',
      borderColor: '#1049a7',
      boxShadow: [
        '0 14px 26px -12px rgba(4, 46, 116, 0.42)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(4, 46, 116, 0.2)',
      ],
    },
  },

  info: {
    backgroundColor: '#00bcd4',
    borderColor: '#00bcd4',
  },

  success: {
    backgroundColor: '#4caf50',
    borderColor: '#4caf50',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#4caf50',
      borderColor: '#4caf50',
      boxShadow: [
        '0 14px 26px -12px rgba(76, 175, 80, 0.42)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(76, 175, 80, 0.2)',
      ],
    },
  },

  warning: {
    backgroundColor: '#ff9800',
    borderColor: '#ff9800',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ff9800',
      borderColor: '#ff9800',
      boxShadow: [
        '0 14px 26px -12px rgba(255, 152, 0, 0.42)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(255, 152, 0, 0.2)',
      ],
    },
  },

  danger: {
    backgroundColor: '#f44336',
    borderColor: '#f44336',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#f44336',
      borderColor: '#f44336',
      boxShadow: [
        '0 14px 26px -12px rgba(244, 67, 54, 0.42)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(244, 67, 54, 0.2)',
      ],
    },
  },

  rose: {
    backgroundColor: '#e91e63',
    borderColor: '#e91e63',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#b8124a',
      borderColor: '#b8124a',
      boxShadow: [
        '0 3px 5px 2px rgba(255, 105, 135, .3)',
        '0 4px 23px 0px rgba(0, 0, 0, 0.12)',
        '0 8px 10px -5px rgba(233, 30, 99, 0.2',
      ],
    },
  },

  exotic: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },

  fontColorWhite: {
    color: '#ffffff',
  },
  fontColorBlue: {
    color: '#1049a7',
  },
});

export function CustomButton({ children, onClick, ...other }) {
  const classes = useStyles();
  const color = other.color || '';
  const size = other.size || 'regular';
  const fillVariant = other.variant || 'text'; // 'text', 'contained', 'outlined';
  return (
    <Button
      onClick={onClick}
      {...other}
      variant={fillVariant}
      className={cn(classes.root, classes[color], classes[size])}
    >
      {children}
    </Button>
  );
}
