/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Reorder } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

import { QUESTION_TYPES, QUESTION_TYPE_METADATA, INPUT_LIMITS, QUESTION_DEFAULT_FROM_TYPE_FILTERS, COLUMN_TYPES, QUESTION_TYPE_OPTIONS, ANSWER_TYPES } from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';

//components
import { TextField, FormLabel, FormControl, FormControlLabel, Checkbox, Radio, RadioGroup, Typography, ListItemIcon, Grid } from '@material-ui/core';

import {
  CustomButtonStyled,
  AppBarStyled,
  ToolbarStyled,
  FormWrapperStyled,
  FormContentStyled,
  ListItemStyled,
  CardBody,
  CardHeader,
} from './styled';
import { optionsValidator, reduceValueLength } from '../helpers';
import OptionsForm from './OptionsForm';
import DefaultFromModal from './DefaultFromModal';
import TableForm from './TableForm';
import GroupForm from './GroupForm/index';
import DefaultOptionsForm from './DefaultOptionsForm';
import AdditionalLinkFrom from './AdditionalLinkForm';

export const QuestionForm = ({
  data,
  categoryId,
  wizardId,
  wizards,
  onDelete,
  setActive,
  onEdit,
  i,
  submitted,
  defaultFromOptions = []
}) => {
  const [valueError, setValueError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [additionalInfoError, setAdditionalInfoError] = useState('');
  const [moreInfoLabelError, setMoreInfoLabelError] = useState('');
  const [tableError, setTableError] = useState('');
  const [optionsFromError, setOptionsFromError] = useState('');
  const [optionError, setOptionError] = useState(null);

  const {
    active,
    id,
    tag = null,
    isOptional = false,
    sectionHeading,
    question,
    description,
    optionsFromPreviousAnswers,
    optionsFrom = null,
    options,
    optionsSpecifyOthers,
    type,
    moreInfoLabel,
    additionalInfo,
    additionalLink = null,
    waterSystemSourcePath,
    table = null,
    defaultFrom = null,
    questions = null,
    groupOptions = null
  } = data;
  const {
    MULTIPLECHOICE,
    SINGLECHOICE,
    SINGLESELECT,
    TABLE,
    GROUP,
    SIMPLETEXT,
    LONGTEXT
  } = QUESTION_TYPES;

  useEffect(() => {
    if (!question && submitted) {
      setValueError('this field is required');
    }

    if (!!table && submitted) {
      if (!table.columns.length) {
        setTableError('Must include at least one column');
      } else if (table.columns.find(column => !column.name)) {
        setTableError('Columns must have names');
      } else if (table.columns.find(column => column.type === COLUMN_TYPES.calculated && !column.formula)) {
        setTableError('Calculated columns must have a formula');
      }
    }

    if (optionsFromPreviousAnswers && !!optionsFrom && submitted) {
      if (!optionsFrom.wizardId || optionsFrom.wizardId === ANSWER_TYPES.empty
        || !optionsFrom.categoryId || optionsFrom.categoryId === ANSWER_TYPES.empty
        || !optionsFrom.questionId || optionsFrom.questionId === ANSWER_TYPES.empty
        || !optionsFrom.innerQuestionId || optionsFrom.innerQuestionId === ANSWER_TYPES.empty) {
        setOptionsFromError('Select a source question for the options values');
      }
    }


  }, [question, submitted]);

  const isOptionsInValid = optionsValidator(options);
  const isMultyChoiceError =
    (type === MULTIPLECHOICE || type === SINGLECHOICE) && submitted && !isOptionsInValid;

  const appBarStyles = {
    flexDirection: 'row',
    background: active
      ? 'linear-gradient(60deg, #055bbb, #1049a7)'
      : 'linear-gradient(60deg, #eee, #bdbdbd)',
  };

  const onChange = (value, key) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT,
      MAX_CHARACTERS_LOWER_LIMIT,
      MAX_CHARACTERS_DESCRIPTION_LIMIT,
      MAX_CHARACTERS_MOREINFOLABEL_LIMIT
    } = INPUT_LIMITS;
    const defaultValue = {
      id,
      tag,
      isOptional,
      sectionHeading,
      description,
      question,
      options,
      optionsSpecifyOthers,
      defaultFrom,
      optionsFromPreviousAnswers,
      optionsFrom,
      waterSystemSourcePath,
      moreInfoLabel,
      table,
      additionalInfo,
      additionalLink,
      questions,
      groupOptions
    };

    switch (key) {
      case 'question':
        setValueError('');
        defaultValue.question = reduceValueLength(value, MAX_CHARACTERS_UPPER_LIMIT, setValueError);
        break;
      case 'description':
        setDescriptionError('');
        defaultValue.description = reduceValueLength(value, MAX_CHARACTERS_DESCRIPTION_LIMIT, setDescriptionError);
        break;
      case 'additionalInfo':
        setAdditionalInfoError('');
        defaultValue.additionalInfo = reduceValueLength(value, MAX_CHARACTERS_LOWER_LIMIT, setAdditionalInfoError);
        break;
      case 'moreInfoLabel':
        setMoreInfoLabelError('');
        defaultValue.moreInfoLabel = reduceValueLength(value, MAX_CHARACTERS_MOREINFOLABEL_LIMIT, setMoreInfoLabelError);
        break;
      case 'table':
        setTableError('');
        defaultValue.table = value;
        break;
      case 'optionsFrom':
        setOptionsFromError('');
        defaultValue.optionsFrom = value;
        break;
      case 'isOptional':
        defaultValue.isOptional = value === 'true';
        break;
      default:
        defaultValue[key] = value;
        break;
    }
    onEdit({
      ...defaultValue,
    });
  }

  const onOptionChange = (option) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setOptionError(null);
    const index = options.findIndex((o) => o.id === option.id);
    const changedOptions = [...options];

    const reduceOptionLength = () => {
      if (option.value.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setOptionError({ id: option.id, error: constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT) });
      }
      return option.value.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    changedOptions[index] = { ...option, value: reduceOptionLength() };

    onEdit({
      id,
      description,
      additionalLink,
      question,
      isOptional,
      optionsFromPreviousAnswers,
      optionsFrom,
      options: [...changedOptions],
      optionsSpecifyOthers,
      defaultFrom,
      waterSystemSourcePath,
      moreInfoLabel,
      table,
      additionalInfo,
      questions,
      groupOptions
    });
  };

  const onOptionAdd = () => {
    const option = { id: uuidv4(), value: '' };
    onEdit({
      id,
      description,
      additionalLink,
      question,
      isOptional,
      optionsFromPreviousAnswers,
      optionsFrom,
      options: [...options, option],
      optionsSpecifyOthers,
      defaultFrom,
      waterSystemSourcePath,
      moreInfoLabel,
      table,
      additionalInfo,
      questions,
      groupOptions
    });
  };

  const onOptionRemove = (optionId) => {
    const filteredOptions = options.filter((o) => o.id != optionId);
    onEdit({
      id,
      description,
      additionalLink,
      question,
      isOptional,
      optionsFromPreviousAnswers,
      optionsFrom,
      options: [...filteredOptions],
      optionsSpecifyOthers,
      defaultFrom,
      moreInfoLabel,
      waterSystemSourcePath,
      table,
      additionalInfo,
      questions,
      groupOptions
    });
  };

  return (
    <FormWrapperStyled>
      <ListItemStyled ContainerComponent='div'>
        <AppBarStyled position='static' style={appBarStyles}>
          <ToolbarStyled
            onClick={() => {
              if (!active) {
                setActive(id);
              }
            }}
          >
            <DragHandle />
            <Typography variant='h6'>
              {`# ${i}`} {type === GROUP ? ` - ${question}` : '' }
            </Typography>
          </ToolbarStyled>
          <CustomButtonStyled
            color={active ? 'primary' : ''}
            variant={active ? 'contained' : 'outlined'}
            onClick={() => {
              onDelete(id);
            }}
          >
            Delete
          </CustomButtonStyled>
        </AppBarStyled>

        <FormContentStyled
          onClick={() => {
            if (!active) {
              setActive(id);
            }
          }}
        >
        <CardBody>
        {active || type !== GROUP && <CardHeader>{QUESTION_TYPE_METADATA[type].label}</CardHeader>}
            
            {active && type === GROUP ? <GroupForm  
              categoryId={categoryId}
              wizardId={wizardId}
              wizards={wizards}
              formData={{ submitted, active, localOptions: defaultFromOptions, questionError: valueError }} 
              questionData={{ type, tag, sectionHeading, questions, question, additionalLink, groupOptions, waterSystemSourcePath }} 
              onChange={(value, type) => onChange(value, type)} />
            : <Typography variant="caption">Click to view</Typography>}
            {type !== GROUP && (<>
              {<>
                <TextField
                  value={question}
                  onChange={({ currentTarget: { value } }) => {
                    onChange(value, 'question');
                  }}
                  error={valueError}
                  helperText={valueError || QUESTION_TYPE_METADATA[type].helperText}
                  disabled={!active}
                  label='Question'
                  style={{ margin: 8 }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin='dense'
                />
                {!!QUESTION_DEFAULT_FROM_TYPE_FILTERS[type] && <DefaultFromModal
                  type={type}
                  categoryId={categoryId}
                  wizardId={wizardId}
                  wizards={wizards}
                  defaultFrom={defaultFrom}
                  localOptions={defaultFromOptions}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  margin='dense'
                  onDefaultSelected={(value) => onChange(value, 'defaultFrom')}
                />}
                {QUESTION_TYPE_METADATA[type].options === QUESTION_TYPE_OPTIONS.DETAILED &&
                  <> <TextField
                    value={waterSystemSourcePath}
                    onChange={({ currentTarget: { value } }) => {
                      onChange(value, 'waterSystemSourcePath');
                    }}
                    label='Water System Source Path (optional)'
                    style={{ margin: 8 }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    margin='dense'
                  />
                    <TextField
                      value={moreInfoLabel}
                      onChange={({ currentTarget: { value } }) => {
                        onChange(value, 'moreInfoLabel');
                      }}
                      error={moreInfoLabelError}
                      helperText={moreInfoLabelError || 'Type your note label here'}
                      disabled={!active}
                      label='Note Label (optional)'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin='dense'
                      style={{ margin: 8 }}
                      fullWidth
                    />
                    <TextField
                      value={description}
                      onChange={({ currentTarget: { value } }) => {
                        onChange(value, 'description');
                      }}
                      disabled={!active}
                      label='Description (optional)'
                      style={{ margin: 8, marginTop: 20 }}
                      helperText={
                        descriptionError || "add description if it's necessary"
                      }
                      fullWidth
                      multiline
                      rows={2}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin='dense'
                      variant='outlined'
                      error={descriptionError}
                    />
                    <TextField
                      value={additionalInfo}
                      onChange={({ currentTarget: { value } }) => {
                        onChange(value, 'additionalInfo');
                      }}
                      disabled={!active}
                      label='Additional Info (optional)'
                      style={{ margin: 8, marginTop: 20 }}
                      helperText={
                        additionalInfoError || "Add additional info if it's necessary"
                      }
                      fullWidth
                      multiline
                      rows={2}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin='dense'
                      variant='outlined'
                      error={additionalInfoError}
                    />
                    <AdditionalLinkFrom disabled={!active} additionalLink={additionalLink} onChange={(value) => onChange(value, 'additionalLink')} />
                  </>
                }
                {type === SINGLESELECT &&
                  <>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Options From</FormLabel>
                      <RadioGroup row aria-label="optionsFromPreviousAnswers" name="optionsFromPreviousAnswers" value={optionsFromPreviousAnswers} onChange={({ currentTarget: { value } }) => {
                        onChange(value === 'true', 'optionsFromPreviousAnswers');
                      }}>
                        <FormControlLabel value={false} control={<Radio color='primary' />} label="Static List" />
                        <FormControlLabel value={true} control={<Radio color='primary' />} label="Previous Answers" />
                      </RadioGroup>
                    </FormControl>

                    {!optionsFromPreviousAnswers &&
                      <OptionsForm
                        options={options}
                        onOptionChange={onOptionChange}
                        onOptionAdd={onOptionAdd}
                        onOptionRemove={onOptionRemove}
                        active={active}
                        isMultyChoiceError={isMultyChoiceError}
                        optionError={optionError}
                      />}

                    {optionsFromPreviousAnswers &&
                      <DefaultOptionsForm
                        type={type}
                        categoryId={categoryId}
                        wizardId={wizardId}
                        wizards={wizards}
                        optionsFrom={optionsFrom}
                        localOptions={defaultFromOptions}
                        onOptionsFrom={(value) => onChange(value, 'optionsFrom')}
                        error={optionsFromError}
                      />
                    }
                  </>
                }
                {(
                  type === MULTIPLECHOICE ||
                  type === SINGLECHOICE
                ) && (<OptionsForm
                  options={options}
                  onOptionChange={onOptionChange}
                  onOptionAdd={onOptionAdd}
                  onOptionRemove={onOptionRemove}
                  active={active}
                  isMultyChoiceError={isMultyChoiceError}
                  optionError={optionError}
                />
                  )}
                 {(
                  type === MULTIPLECHOICE
                ) && (<FormControlLabel
                  control={
                    <Checkbox
                      checked={optionsSpecifyOthers}
                      onChange={( event ) => {
                        onChange(event.target.checked, 'optionsSpecifyOthers');
                      }}
                      name="optionsSpecifyOthers"
                      color="primary"
                    />
                  }
                  label="Allow User to Add Other Options"/>
                  )}
                {type === TABLE && (
                  <TableForm
                    showWaterSystemSourcePath={!!waterSystemSourcePath}
                    onChange={(value) => onChange(value, 'table')}
                    error={tableError}
                    table={table}
                  />
                )}
                {(type === SIMPLETEXT || type === LONGTEXT) && (
                  <Grid item style={{ margin: 8 }}>
                      <div>
                          Is Optional
                      </div>
                      <RadioGroup
                          value={isOptional}
                          onChange={({ target }) => {
                              onChange(target.value, 'isOptional');
                          }}
                          style={{ display: 'flex', flexDirection: 'row' }}
                      >
                      <FormControlLabel
                          value={true}
                          control={<Radio color='primary' />}
                          label='Yes'
                      />
                      <FormControlLabel
                          value={false}
                          control={<Radio color='primary' />}
                          label='No'
                      />
                      </RadioGroup>
                  </Grid>
                )}
              </>}
            </>)}
            
          </CardBody>
        
        </FormContentStyled>
      </ListItemStyled>
    </FormWrapperStyled>
  );
};

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <Reorder style={{ color: '#ffffff' }} />
  </ListItemIcon>
));