import styled from 'styled-components';
import { Button as MaterialButton, MenuItem } from '@material-ui/core';

export const Button = styled(MaterialButton)`
  display: flex;
  flex-direction: row;
`;

export const MenuItemStyled = styled(MenuItem)`
  font-weight: normal;
  text-decoration: none;
  font-size: 0.8125rem;
  border-radius: 0.125rem;
  margin: 0 0.3125rem;
  min-width: 7rem;
  padding: 0.625rem 1.25rem;
  overflow: hidden;
  line-height: 1.428571;
  text-overflow: ellipsis;
  word-wrap: break-word;
  :hover {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(156, 39, 176, 0.4);
    background-color: #1049a7 !important;
    color: #fff !important;
  }
`;

export const MenuItemText = styled.div`
  display: flex;
  margin: 0 8px;
  align-items: center;
  justify-content: flex-start;
`;

export const MenuTitle = styled.div`
  padding: 0.5rem 1.25rem;
  font-size: 0.75rem;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-transform: none;
  color: #777;
  line-height: 1.428571;
  font-weight: inherit;
`;