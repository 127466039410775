import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  addCategoryAdmin,
  deleteAdminCategory,
  editAdminCategory,
  adminUpdateCategoriesOrderCall,
  getWizard,
} from '../../../store/admin/actions';
import { setCurrentCategoryData } from '../../../store/common/actions';
import { history } from '../../../store';

// components
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  CategoryItem,
  ContentContainer,
  CategoryTitle,
  CategoryActions,
  CategorySubTitle,
  PaperStyled,
  Footer,
} from '../styled';
import { Reorder } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { NoCategoryPlaceholder } from '../Placeholder';
import { CustomButton } from '../../../components/Button';
import { BreadcrumbsComponent } from '../../../components/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';

import {
  INPUT_LIMITS,
  WIZARD_TYPES,
  DIRECTORY_TYPES
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';

export const AdminCategoriesContainer = () => {
  const dispatch = useDispatch();
  const directory = useSelector((store) => store.Common.currentDirectoryName);
  const sub_directory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );

  const wizard_id = useSelector((store) => {
    return directory && sub_directory
      ? store.Admin[directory][sub_directory].id
      : null;
  });

  const pullWizardFromObject = (directoryObject, sub_directory_name, directory_name) => {
    const reducedData = {
      directory: directory_name,
      sub_directory: sub_directory_name,
      wizard_id: directoryObject[sub_directory_name].id,
      wizard: directoryObject[sub_directory_name].wizard
    };

    return reducedData;
  }

  const wizards = useSelector((store) => {
    const {
      waste,
      drinking,
      contactinfo
    } = store.Admin;
    const {
      emergencyresponseplan,
      riskassessment,
      contactinfo: contactinfoWizType
    } = WIZARD_TYPES;
    const {
      waste: wasteType,
      drinking: drinkingType,
      contactinfo: contactinfoDirType
    } = DIRECTORY_TYPES;

    const simpleWizards = [
      pullWizardFromObject(waste, emergencyresponseplan, wasteType),
      pullWizardFromObject(waste, riskassessment, wasteType),
      pullWizardFromObject(drinking, emergencyresponseplan, drinkingType),
      pullWizardFromObject(drinking, riskassessment, drinkingType),
      pullWizardFromObject(contactinfo, contactinfoWizType, contactinfoDirType)
    ].filter(w => w.wizard_id !== wizard_id && w.wizard === null);

    return simpleWizards;
  });

  const wizard = useSelector((store) => {
    return directory && sub_directory
      ? store.Admin[directory][sub_directory].wizard
      : null;
  });

  useEffect(() => {
    if (!wizard_id) {
      history.push('/home');
    } else {
      const getAdminWizard = async () => {
        await dispatch(getWizard({ directory, sub_directory, wizard_id }));
      };
      const getAdminWizards = async () => {
        await Promise.all(wizards.map((w) => {
          return dispatch(getWizard({ directory: w.directory, sub_directory: w.sub_directory, wizard_id: w.wizard_id }));
        }));
      }

      if (wizard == null) {
        getAdminWizard();
      }
      
      getAdminWizards();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directory, sub_directory, wizard_id, wizard, dispatch]);



  const categories = useSelector((store) => {
    return directory && sub_directory && store.Admin[directory][sub_directory].wizard
      ? store.Admin[directory][sub_directory].wizard.categories
      : [];
  });

  if (!directory) {
    return <></>;
  }

  return <AdminCategories wizardId={wizard_id} categories={categories} />;
};

const AdminCategories = ({ categories, wizardId }) => {
  const dispatch = useDispatch();
  const directory = useSelector((store) => store.Common.currentDirectoryName);
  const sub_directory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );

  const [state, setState] = useState(categories || []);

  useEffect(() => {
    const unSetCurrentCategory = async () => {
      await dispatch(setCurrentCategoryData(null));
    };
    unSetCurrentCategory();
  }, [dispatch]);

  useEffect(() => {
    setState(categories);
  }, [categories]);

  const addNewCategory = async () => {
    const editedCategories = [...categories];
    if (editedCategories.length) {
      editedCategories.map((c) => {
        c.active = false;
        return c;
      });
    }

    await dispatch(
      addCategoryAdmin({
        directory,
        sub_directory,
        existingCategories: editedCategories.length ? editedCategories : [],
        wizardId,
      })
    );
  };

  const onDelete = async (id) => {
    const newCategories = categories.filter((c) => c.id !== id);
    const categoryToDelete = categories.find((c) => c.id === id);
    await dispatch(
      deleteAdminCategory({
        directory,
        sub_directory,
        categories: newCategories,
        id: categoryToDelete.id,
        wizardId,
      })
    );
  };

  let onChangeDelay = null;
  const onChange = ({ value, id }) => {
    clearTimeout(onChangeDelay);
    onChangeDelay = setTimeout(async () => {
      const index = categories.findIndex((c) => c.id === id);
      const newCategories = [...categories].map((c) => {
        c.active = false;
        return c;
      });
      newCategories[index].name = value;
      newCategories[index].active = true;
      setState(newCategories);
      await dispatch(
        editAdminCategory({
          directory,
          sub_directory,
          categories: categories,
          id: newCategories[index].id,
          name: newCategories[index].name,
        })
      );
    }, 1000);
  };

  const setActive = (id) => {
    const index = state.findIndex((c) => c.id === id);
    if (!state[index].active) {
      const newCategories = [...state].map((c) => {
        c.active = false;
        return c;
      });
      newCategories[index].active = true;
      setState(newCategories);
    }
  };

  const onSetCurrentCategoryData = async (data) => {
    await dispatch(setCurrentCategoryData(data));
    history.push('/questions');
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((el, i) => {
      el.orderindex = i;
      return el;
    });
    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(state, result.source.index, result.destination.index);
    setState(items);
    await dispatch(
      adminUpdateCategoriesOrderCall({
        directory,
        sub_directory,
        categories: items,
      })
    );
  };

  return (
    <Container>
      <PaperStyled elevation={3}>
        <BreadcrumbsComponent />
        <ContentContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {!!state.length ? (
                    state.map((c, index) => (
                      <Draggable key={c.id} draggableId={c.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Category
                              id={c.id}
                              name={c.name}
                              onDelete={onDelete}
                              onChange={onChange}
                              active={c.active}
                              setActive={setActive}
                              onSetCurrentCategoryData={onSetCurrentCategoryData}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <NoCategoryPlaceholder />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ContentContainer>
        <Footer>
          <CustomButton size='large' onClick={addNewCategory} color='primary'>
            add category
          </CustomButton>
        </Footer>
      </PaperStyled>
    </Container>
  );
};

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '& input': {
        color: '#ffffff',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
    },
  },
});

const DragHandle = () => (
  <ListItemIcon>
    <Reorder style={{ color: '#ffffff' }} />
  </ListItemIcon>
);

export const CategoryElement = ({
  i,
  id,
  name,
  onDelete,
  onChange,
  active,
  setActive,
  onSetCurrentCategoryData,
}) => {
  return (
    <Category
      id={id}
      name={name}
      onDelete={onDelete}
      onChange={onChange}
      active={active}
      setActive={setActive}
      onSetCurrentCategoryData={onSetCurrentCategoryData}
    />
  );
};

const Category = ({
  id,
  name,
  onDelete,
  onChange,
  active,
  setActive,
  onSetCurrentCategoryData,
}) => {
  const classes = useStyles();
  const [inputValue, setValue] = useState(name);
  const [isOverlimitedError, setOverlimitedError] = useState(false);
  const styles = {
    background: active ? 'linear-gradient(60deg, #055bbb, #1049a7)' : '#bdbdbd',
  };

  const onNameChange = ({ value, id }) => {
    const {
      MAX_CHARACTERS_LOWER_LIMIT
    } = INPUT_LIMITS; 
    setOverlimitedError(false);
    const reduceValueLength = () => {
      if (value.length > MAX_CHARACTERS_LOWER_LIMIT) {
        setOverlimitedError(true);
      }
      return value.slice(0, MAX_CHARACTERS_LOWER_LIMIT);
    };

    setValue(reduceValueLength());
    onChange({ value: reduceValueLength(), id });
  };

  return (
    <CategoryItem
      style={styles}
      onClick={() => {
        setActive(id);
      }}
    >
      <CategoryTitle>
        <DragHandle />
        <CategorySubTitle>
          <TextField
            variant='outlined'
            placeholder="Category's title"
            className={classes.root}
            defaultValue={name}
            value={inputValue}
            autoFocus={active}
            onChange={({ currentTarget: { value } }) => {
              onNameChange({ value, id });
            }}
            style={{ margin: 8, width: '90%', minWidth: 300 }}
            fullWidth
            margin='normal'
            error={isOverlimitedError}
            helperText={isOverlimitedError ? constructInputLimitErrorMessage(INPUT_LIMITS.MAX_CHARACTERS_LOWER_LIMIT) : ''}
          />
        </CategorySubTitle>
      </CategoryTitle>
      <CategoryActions>
        <IconButton
          onClick={() => {
            onSetCurrentCategoryData({currentCategoryId: id, currentCategoryName: name });
          }}
        >
          <Edit style={{ color: '#ffffff' }} />
        </IconButton>
        <CustomButton
          onClick={() => {
            onDelete(id);
          }}
          style={{ marginLeft: 15 }}
          color={active ? 'primary' : 'default'}
        >
          delete
        </CustomButton>
      </CategoryActions>
    </CategoryItem>
  );
};
