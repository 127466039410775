import React from 'react';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import { CustomButton } from '../Button';

// IIFE being used here
const useStyles = ({ paperStyles }) => (makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: '30%',
      width: '40%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      ...paperStyles
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%'
    }
  }))());

const BaseModal = ({ children, isOpen, toggle, buttonText, paperStyles = {} }) => {
    const classes = useStyles({ paperStyles });

    return (
        <div>
            <CustomButton 
                onClick={toggle}
                color='primary'
                variant='outlined'
                style={{ margin: 8 }}
            >
                {buttonText}
            </CustomButton>
            <Modal
                className={classes.modal}
                open={isOpen}
                onClose={toggle}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <div className={classes.paper}>
                        <div className={classes.modalBody}>
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default BaseModal;