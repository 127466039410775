import React, { useState, useEffect } from 'react';

import BaseModal from '../../../components/Modals/BaseModal';
import DropDown from '../../../components/Inputs/DropDown';
import { CustomButton } from '../../../components/Button';
import { ANSWER_TYPES, QUESTION_TYPES, QUESTION_DEFAULT_FROM_TYPE_FILTERS } from '../../../constants';

  const questionsFilter = (type, questions) => {
    const typeFilters = QUESTION_DEFAULT_FROM_TYPE_FILTERS[type];

    if (!typeFilters) {
      return [];
    }
  
    const itemsFilteredBasedOnValidTypes = questions.filter((q) => typeFilters.includes(q.type) ||
                                                            (q.type === QUESTION_TYPES.GROUP && !q.groupOptions?.isRepeatable));
  
    return itemsFilteredBasedOnValidTypes;
  };

  const createWizardOptions = (wizards) => wizards.map(w => ({ label: `${w.directoryName} / ${w.subDirectoryName}`, value: w.wizardId }));
  
  const DefaultFromModal = ({
    type,
    wizardId,
    wizards,
    categoryId,
    defaultFrom,
    localOptions,
    internalOptionsOnly = false,
    onDefaultSelected,
    buttonText,
    filteredOptions = []
  }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const wizardOptions = createWizardOptions(wizards);
  
    const [selectedWizardId, setSelectedWizardId] = useState(defaultFrom?.wizardId || wizardId);
    const [selectedCategoryId, setSelectedCategoryId] = useState(defaultFrom?.categoryId || categoryId);
    const [selectedQuestionId, setSelectedQuestionId] = useState(defaultFrom?.questionId || ANSWER_TYPES.empty);
    const [selectedInnerQuestionId, setSelectedInnerQuestionId] = useState(defaultFrom?.innerQuestionId || ANSWER_TYPES.empty);
    const [showInnerQuestions, setShowInnerQuestions] = useState(false);
    const [currentCategoryOptions, setCurrentCategoryOptions] = useState([]);
    const [currentCategoryQuestions, setCurrentCategoryQuestions] = useState([]);
    const [currentInnerQuestions, setCurrentInnerQuestions] = useState([]);

    const filteredOptionsOnly = !!filteredOptions?.length;
    const questions = (() => {
      let result = [];

      if (filteredOptionsOnly) {
        const simpleValues = filteredOptions.map(fO => {
          if (!fO.question) {
            console.log('An invalid filter option was passed in: expected { defaultFrom: Obj, question: Obj }');
            return null;
          } else {
            return { value: fO.question.id, label: fO.question.question };
          }
        });

        result = !simpleValues.find(sV => !sV) ? simpleValues : [];
      } else {
        result = selectedCategoryId === categoryId ?
          localOptions.map((lO, index) => ({ value: lO.id, label: `Question #${index + 1}` }))
        :
          currentCategoryQuestions;
      }

      return result;
    })();
  
    useEffect(() => {
      if (!filteredOptionsOnly) {
        const categories = wizards.find(w => w.wizardId === selectedWizardId).wizard.categories;
        const newCurrentCategory = categories.find(c => c.id === selectedCategoryId);

        setCurrentCategoryOptions(categories.map(c => ({ label: c.name, value: c.id })));
        setCurrentCategoryQuestions(
          !newCurrentCategory ?
            []
          :  
            questionsFilter(type, newCurrentCategory.questions).map(cCQ => ({ value: cCQ.id, label: cCQ.question }))
        );
        if (newCurrentCategory && selectedQuestionId !== ANSWER_TYPES.empty) {
          const newCurrentQuestion = newCurrentCategory.questions.find(q => q.id === selectedQuestionId);
          if (newCurrentQuestion && newCurrentQuestion.type === QUESTION_TYPES.GROUP) {
            setCurrentInnerQuestions(questionsFilter(type, newCurrentQuestion.questions).map(cCQ => ({ value: cCQ.id, label: cCQ.question })));
            setShowInnerQuestions(true);
          }
        } else {
          setCurrentInnerQuestions([]);
          setShowInnerQuestions(false);
        }
      } else {
        if (selectedQuestionId !== ANSWER_TYPES.empty) {
          const newCurrentQuestion = filteredOptions.find(fO => fO.question.id === selectedQuestionId);

          if (newCurrentQuestion && newCurrentQuestion?.question?.type === QUESTION_TYPES.GROUP) {
            const filteredQuestions = questionsFilter(type, newCurrentQuestion.question.questions).map(cCQ => ({ value: cCQ.id, label: cCQ.question }))

            setCurrentInnerQuestions(filteredQuestions);
            setShowInnerQuestions(true);
          }
        } else {
          setCurrentInnerQuestions([]);
          setShowInnerQuestions(false);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWizardId, selectedCategoryId, selectedQuestionId])
  
    const onSave = () => {
      toggle();

      if (filteredOptionsOnly) {
        const newCurrentQuestion = filteredOptions.find(fO => fO.question.id === selectedQuestionId);

        onDefaultSelected({
          ...newCurrentQuestion.defaultFrom,
          innerQuestionId: selectedInnerQuestionId
        })
      } else {
        onDefaultSelected({ wizardId: selectedWizardId, categoryId: selectedCategoryId, questionId: selectedQuestionId, innerQuestionId: selectedInnerQuestionId });
      }
    };

    const onClear = () => {
      onDefaultSelected(null);
      toggle();
    }

    return (
      <BaseModal isOpen={open} toggle={toggle} buttonText={buttonText || (!!defaultFrom?.questionId ?
          "Change the default value"
        :
          "Set the default value")}>
          <div>
            <h2>Where do you want the default value to come from</h2>
            {!filteredOptionsOnly && <>
              <DropDown
                includeEmptyOption={false}
                selectedValue={selectedWizardId}
                options={wizardOptions}
                onSelect={(value) => {
                  setSelectedWizardId(value);
                  setSelectedCategoryId(ANSWER_TYPES.empty);
                  setSelectedQuestionId(ANSWER_TYPES.empty);
                }}
                selectHelperText={"Select the wizard"}
              />
              <DropDown 
                selectedValue={selectedCategoryId}
                options={currentCategoryOptions}
                onSelect={setSelectedCategoryId}
                selectHelperText={"Select the category"}
              />
            </>}
            <DropDown
              selectedValue={selectedQuestionId}
              options={questions}
              onSelect={setSelectedQuestionId}
              selectHelperText={
                filteredOptionsOnly ? 
                  "Default answer from question" 
                :
                  selectedCategoryId === categoryId ?
                    "Default answer from previous questions"
                  :
                    "Default answer from a question in another category"
              }
            />
            {showInnerQuestions && <DropDown
              selectedValue={selectedInnerQuestionId}
              options={
                currentInnerQuestions
              }
              onSelect={setSelectedInnerQuestionId}
              selectHelperText={"Default from the internal answer for this group question"}
            />}
          </div>
          <div style={{display: 'flex'}}>
            <CustomButton 
              onClick={onSave}
              disabled={
                internalOptionsOnly ? 
                  selectedQuestionId === ANSWER_TYPES.empty && selectedInnerQuestionId === ANSWER_TYPES.empty
                : selectedQuestionId === ANSWER_TYPES.empty}
              style={{ margin: 8, marginTop: 12 }}
              size='medium'
              color={'success'}
              variant={'outlined'}
            >Save</CustomButton>
            {defaultFrom && <CustomButton 
              onClick={onClear}
              style={{ margin: 8, marginTop: 12 }}
              size='medium'
              color='danger'
              variant={'outlined'}
            >Clear</CustomButton>}
          </div>
      </BaseModal>
    );
  };

export default DefaultFromModal;