import React from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CustomButton } from '../../components/Button';
import {
  Container,
  AlignWrapper,
  PaperStyled,
  Title,
  SubTitle,
} from './styled';

export const NotFound = withRouter(({ history }) => {
  let location = useLocation();
  return (
    <Container>
      <AlignWrapper>
        <PaperStyled>
          <Title>Page not found 404</Title>
          <SubTitle>sorry no match for this route</SubTitle>
          <SubTitle>{location.pathname}</SubTitle>
          <CustomButton
            style={{ marginTop: 25 }}
            onClick={() => {
              history.push('/home');
            }}
            color='primary'
            variant='contained'
          >
            go home
          </CustomButton>
        </PaperStyled>
      </AlignWrapper>
    </Container>
  );
});
