import styled from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const LinkSectionsContainer = styled.div`
    margin: 50px;
`;

export const LinkSection = styled.div`
    margin: 20px;
    padding: 20px;
    padding-top: 10px;
`;