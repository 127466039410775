import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  FormContainer,
  Footer,
  AppBarStyled,
  PaperStyled,
} from './styled';
import { CustomButton } from '../../components/Button';
import { history } from '../../store';
import { userLogin } from '../../store/common/actions';
import { UserLoginForm } from './Forms';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 600,
    backgroundColor: "#FFFFFFDD",
    justifyContent: "flex-start",
    marginBottom: 25
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const Login = () => {
  const classes = useStyles();
  const accessToken = useSelector((store) => store.Common.accessToken);

  useEffect(() => {
    if (!!accessToken) {
      history.push('/home');
    } else {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <Container>
      <FormContainer>

        <Card className={classes.root} elevation={3}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Water/Wastewater Risk Assessment and Planning Tool
            </Typography>
            <Typography variant="h5" component="h2">
              WRAPT
            </Typography>
            <Typography variant="body2" component="span">
              <p>
                TRWA welcomes you to our Water/Wastewater Risk Assessment and Planning Tool (WRAPT). WRAPT is a cloud-based software designed to help Texas water and wastewater utilities meet today’s regulatory challenges and comply with the America’s Water Infrastructure Act (AWIA) of 2018. Section 2013 of AWIA requires community water systems that serve more than 3,300 people to complete a risk and resilience assessment and develop an emergency response plan. The due dates for your initial certification of your risk and resilience assessment are set by EPA and are based on the population size your serve, with the emergency response plan due within the following six months. Every five years, your utility must review the risk and resilience assessment and emergency response plan and submit a recertification to the U.S. EPA that the assessment has been reviewed and, if necessary, revised.
              </p>
              <p>
                Each section of WRAPT contains dynamic, logic-based questions you can use to build your Risk and Resiliency Assessment and Emergency Response Plan. We have pre-imported some system information to reduce data entry, and the tool is designed to retain your information to reduce administrative burden as you continue to update these documents in the future. Once you have finished, please click on the EPA Certification link at the top of this page for supplemental documents and instructions on how to self-submit your certification to the Environmental Protection Agency (EPA).
              </p>
            </Typography>
          </CardContent>
        </Card>

        <CardForm />
      </FormContainer>
    </Container>
  );
};

const CardForm = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');

  const onSubmit = async () => {
    await dispatch(userLogin(code));
  };
  //<Switcher code={code} onCodeChange={setCode} onSubmit={onSubmit} />
  return (
    <>
      <PaperStyled elevation={3}>
        <AppBarStyled position='static'>Login</AppBarStyled>
        <UserLoginForm code={code} onChange={setCode} onSubmit={onSubmit} />
        <CardAction onSubmit={onSubmit} />
      </PaperStyled>
    </>
  );
};

const CardAction = ({ onSubmit }) => {
  return (
    <Footer>
      <CustomButton
        onClick={onSubmit}
        size={'large'}
        style={{ margin: '5px 0' }}
      >
        get started
      </CustomButton>
    </Footer>
  );
};
