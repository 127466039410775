import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid, OutlinedInput, FormHelperText } from '@material-ui/core';

export const SelectFormComponent = ({
  callBack,
  data,
  options,
  isCompact = false
}) => {
  const {
    id,
    question: questionText,
    description,
    templateData = {
      description: '',
      question: ''
    },
    answer: { value },
  } = data;

  const handleChange = (event) => {
    callBack({ answer: event.target.value, id });
  };

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems={'flex-start'}
      spacing={3}
    >
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        style={{ marginBottom: 10 }}
      >
        <FormControl 
          variant="outlined" 
          style={{ padding: 8, width: "90%" }}
        >
          <InputLabel 
            shrink
            ref={inputLabel}
            style={{marginLeft: '7px', marginTop: '7px'}}
          >
            {isCompact ? templateData.question || questionText : 'Priority'}
          </InputLabel>
          <Select            
            value={value}
            onChange={handleChange}
            helperText={isCompact ? templateData.description || description : ''}
            input={
              <OutlinedInput
                notched
                labelWidth={labelWidth}
              />
            }
          >
            <FormHelperText>{isCompact ? templateData.description || description : ''}</FormHelperText>
            {options.map((o) =>
              <MenuItem key={o} value={o}>{o}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
