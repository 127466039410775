// Config object to be passed to Msal on creation
import { Logger, LogLevel } from 'msal';

const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_AUTHORITY_URI;
const env = process.env.REACT_APP_ENV;

const redirectUri =
  env !== 'development'
    ? process.env.REACT_APP_REDIRECT_URI
    : 'http://localhost:3000';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_SCOPES_URI}access`],
};

export const tokenRequest = {
  scopes: [
    'openid',
    `${process.env.REACT_APP_SCOPES_URI}user_impersonation`,
    `${process.env.REACT_APP_SCOPES_URI}access`,
  ],
};

function loggerCallback(logLevel, message, containsPii) {
  console.info(message);
}

export const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    validateAuthority: false,
  },
  cache: {
    //cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  popUp: !isIE
};

if (process.env.NODE_ENV !== 'production') {
  msalConfig.system = {
    logger: new Logger(
          loggerCallback ,{
                level: LogLevel.Verbose,
                piiLoggingEnabled: false
          }
  )
  };
}
