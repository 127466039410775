import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLUMN_TYPES, COLUMN_AGGREGATE_TYPES } from '../../constants';

const useStyles = makeStyles({
  card: {
    minWidth: 120,
    minHeight: 275
  },

  title: {
    fontSize: 14,
  },
  formControl: {
    width: "100%"
  }

});

const TableCellAdmin = ({
  label = '',
  placeholder = '',
  showWaterSystemSourcePath,
  cell,
  onChange
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary">{label} column</Typography>
        <TextField
          value={cell.name || ''}
          variant="outlined"
          size="small"
          className={classes.formControl}
          label="Heading"
          placeholder={placeholder}
          onChange={({ currentTarget: { value } }) => onChange(value, 'name')}
          style={{ marginBottom: 8 }}
          margin='dense'
        />

        {cell.type === COLUMN_TYPES.calculated &&
          <TextField
            value={cell.formula || ''}
            variant="outlined"
            size="small"
            className={classes.formControl}
            label="Formula"
            helperText="e.g. [Qty]*[Price]"
            placeholder={placeholder}
            onChange={({ currentTarget: { value } }) => onChange(value, 'formula')}
            style={{ marginBottom: 16 }}
            margin='dense'
          />}
        {cell.type !== COLUMN_TYPES.text &&
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>
              Aggregate
              </InputLabel>
            <Select
              label="Aggregate"
              margin="dense"
              value={cell.aggregateType}

              onChange={({ target: { value } }) => onChange(value, 'aggregateType')}
            >
              <MenuItem value={COLUMN_AGGREGATE_TYPES.average}>{COLUMN_AGGREGATE_TYPES.average}</MenuItem>
              <MenuItem value={COLUMN_AGGREGATE_TYPES.sum}>{COLUMN_AGGREGATE_TYPES.sum}</MenuItem>
              <MenuItem value={COLUMN_AGGREGATE_TYPES.none}>{COLUMN_AGGREGATE_TYPES.none}</MenuItem>
            </Select>
          </FormControl>}

          {cell.type !== COLUMN_TYPES.calculated && showWaterSystemSourcePath &&
          <TextField
            value={cell.waterSystemSourcePath || ''}
            variant="outlined"
            size="small"
            className={classes.formControl}
            label="Water System Source Path"
            onChange={({ currentTarget: { value } }) => onChange(value, 'waterSystemSourcePath')}
            style={{ marginBottom: 16 }}
            margin='dense'
          />}
      </CardContent>
    </Card>
  );
};

export default TableCellAdmin;