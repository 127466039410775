import React, { useState, useEffect } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    makeStyles
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import AdditionalInfoToolTip from '../../../../components/Tooltips/AdditionalInfoToolTip';
import BaseModal from '../../../../components/Modals/BaseModal';
import { CustomButton } from '../../../../components/Button';

import { TextForm } from '../TextFormComponent';
import { fillStringTemplate } from '../helpers';

const useStyles = makeStyles({
    tableContainer: {
        marginTop: '30px',
        marginBottom: '30px',
        display: 'flex',
        alignItems: 'flex-start',
    },
    table: {
        minWidth: 650
    }
});

const constructFilledTemplateValues = (templateKey) => ({ id: uuidv4(), key: templateKey.key, value: null });

const GroupSectionFromTemplateModal = ({ sectionHeading, groupQuestionsCopy, buttonText, onSave }) => {
    const classes = useStyles();
    const {
        templateKeys,
        questions
    } = groupQuestionsCopy;

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [filledTemplateValues, setFilledTemplateValues] = useState(templateKeys.map(constructFilledTemplateValues));
    const captureTemplateValue = (value, index) => {
        const newFilledTemplateValues = _.cloneDeep(filledTemplateValues);
        newFilledTemplateValues[index].value = value;

        setFilledTemplateValues(newFilledTemplateValues);
    }
  
    const constructHeadCell = (tK, index) => (<TableCell key={index}>{tK.label}</TableCell>);
    const constructBodyKeyCell = (tK, index) => (<TableCell key={index}>{tK.key}</TableCell>);
    const constructBodyInputs = (fT, index) => {
        const filledTemplateValue = fT;

        return (
            <div key={fT.id} style={{ margin: '8px' }}>
                <TextForm
                    data={{
                        question: filledTemplateValue.key,
                        description: templateKeys[index].label,
                        answer: {
                            key: templateKeys[index].key,
                            value: filledTemplateValue.value
                        }
                    }}
                    callBack={(data) => captureTemplateValue(data.answer, index)}
                    includeNote={false}
                    isCompact={true}
                />
            </div>
        );
    };

    const createNewFilledSection = (addAnother = false) => {
        const newSection = {
            id: uuidv4(),
            sectionHeading: sectionHeading ? fillStringTemplate(sectionHeading, filledTemplateValues) : null,
            questions: _.cloneDeep(questions)
        }

        newSection.questions = newSection.questions.map((q) => {
            return {
              ...q,
              question: fillStringTemplate(q.question, filledTemplateValues),
              description: fillStringTemplate(q.description, filledTemplateValues),
              templateData: {
                  question: '',
                  description: ''
              }
            }
        });

        onSave(newSection);
        if (!!addAnother) {
            setFilledTemplateValues(templateKeys.map(constructFilledTemplateValues));
        } else {
            toggle();
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setFilledTemplateValues(templateKeys.map(constructFilledTemplateValues)), [open]);

    return (
        <BaseModal isOpen={open} toggle={toggle} buttonText={buttonText} paperStyles={{ width: '70%', height: '60%' }}>
            <div>
                <div className={classes.tableContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {templateKeys.map(constructHeadCell)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {templateKeys.map(constructBodyKeyCell)}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <AdditionalInfoToolTip info="Please fill in the following template inputs before we add this new question" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {filledTemplateValues.map(constructBodyInputs)}
                </div>
            </div>
            <div style={{display: 'flex'}}>
                <CustomButton 
                    onClick={() => createNewFilledSection()}
                    disabled={!!filledTemplateValues.find(fT => fT.value === null)}
                    style={{ margin: 8, marginTop: 12 }}
                    size='medium'
                    color={'success'}
                    variant={'outlined'}
                >Save</CustomButton>
                <CustomButton 
                    onClick={() => createNewFilledSection(true)}
                    disabled={!!filledTemplateValues.find(fT => fT.value === null)}
                    style={{ margin: 8, marginTop: 12 }}
                    size='medium'
                    color='primary'
                    variant={'outlined'}
                >Add Another</CustomButton>
            </div>
        </BaseModal>
    );
};

export default GroupSectionFromTemplateModal;