import styled from 'styled-components';


export const InlineCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: 40px;
`;
export const CategoryTitle = styled.div`
  display: flex;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  word-break: break-all;
`;
