import React, { useEffect } from 'react';
import { USER_TYPES, CATEGORY_TYPES, INPUT_LIMITS } from '../../constants';
import transformName from '../../helpers/transformName';
import { BreadcrumbsContainer } from './styled';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { NavigateBefore } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const linkStyle = {
  fontWeight: 300,
  lineHeight: '1.5em',
  textTransform: 'capitalize',
  color: 'black',
  textDecoration: 'none',
};

let fontSize = '2.25rem';

export const BreadcrumbsComponent = () => {
  const location = useLocation();
  const directory = useSelector((store) => store.Common.currentDirectoryName);
  const category = useSelector((store) => store.Common.currentCategoryName);
  
  const userType = useSelector((store) => store.Common.userType);
  const sub_directory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );
  const categoryName = useSelector((store) => {
  
    if (category) {
      const with_ellipsis = `${
        category?.length > INPUT_LIMITS.MAX_CATEGORY_NAME_LENGTH ? '...' : ''
      }`;
      return `/${category.slice(0, INPUT_LIMITS.MAX_CATEGORY_NAME_LENGTH)}${with_ellipsis}`;
    }
    return '';
  });

  const directoryDisplayName = useSelector((store) => {
    const categoriesType = userType === USER_TYPES.ADMIN ? CATEGORY_TYPES.ADMIN : CATEGORY_TYPES.USER;
    return directory ? store[categoriesType][directory].displayName : null;
  });


  useEffect(() => {
    const d = transformName(directory);
    const s = transformName(sub_directory);
    const sum = d.length + s.length;
    const categoryLength = categoryName ? categoryName.length : 0;
    if (sum + categoryLength >= 45) {
      fontSize = '1.8rem';
    } else {
      fontSize = '2.3rem';
    }
  }, [directory, sub_directory, categoryName, location]);

  const onlyOneLevel =
    location.pathname === '/user-management' ||
    location.pathname === '/contact-information';

  return (
    <>
      <BreadcrumbsContainer>
        {onlyOneLevel && (
          <Link style={{ ...linkStyle, fontSize, display: 'flex', alignItems: 'center' }} to={'/home'} id="breadcrumb">
            <NavigateBefore fontSize='large' />
            {transformName(location.pathname)}
          </Link>
        )}
        {!onlyOneLevel && (
          <>
            {directory && (
              <Link style={{ ...linkStyle, fontSize, display: 'flex', alignItems: 'center' }} to={'/home'}>
                <NavigateBefore fontSize='large' />
                {directoryDisplayName}
              </Link>
            )}
            {directory && sub_directory && (
              <Link style={{ ...linkStyle, fontSize }} to={'/categories'}>
                /{transformName(sub_directory)} 
              </Link>
            )}
            {directory && sub_directory && categoryName && (
              <Typography style={{ ...linkStyle, fontSize }} title={category}>
                {categoryName}
              </Typography>
            )}
          </>
        )}
      </BreadcrumbsContainer>
    </>
  );
};
