import jsPDF from 'jspdf';
import 'jspdf-autotable';
import drawPDF from './drawPDF';

const PDFGenerator = ({
  categories,
  contactinfo,
  directory = '',
  subDirectory = '',
  wizards = []
}) => {
  const createPDF = async () => {
    const doc = new jsPDF('p', 'mm');

    await drawPDF(doc, {
      categories,
      contactinfo,
      directory,
      subDirectory,
      wizards
    });

    doc.save('TRWA-download.pdf'); 
  };
  createPDF();
};

export default PDFGenerator;