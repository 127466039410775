import { callApi as baseCallApi } from './api';
import { getAdminToken, getUserToken, getUserSecretCode } from './tokenService';
import { GetAPIUrl } from './config';

const callApi = async ({
  endpoint,
  method,
  body = null,
  type = 'admin',
  AuthToken = null,
}) => {
  const sendRequest = async () => {
    return baseCallApi({
      method,
      AuthToken,
      endpoint: `${GetAPIUrl(type)}${endpoint}`,
      body,
      type,
    });
  };
  return await sendRequest();
};

export const adminGetDirectories = async () => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/GetDirectories',
      method: 'GET',
      AuthToken: await getAdminToken(),
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminGetWizard = async ({ id }) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: `/api/GetWizard?&Wizardid=${id}`,
      method: 'GET',
      AuthToken: await getAdminToken(),
    });
    return result || [];
  } catch (err) {
    console.log('err', err);
  }
};

export const adminUpdateWizard = async ({ wizard }) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: `/api/UpdateWizard`,
      method: 'PUT',
      AuthToken: await getAdminToken(),
      body: wizard
    });
    return result || [];
  } catch (err) {
    console.log('err', err);
  }
};


export const adminPublishDirectory = async (id) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/PublishDirectory',
      method: 'PUT',
      AuthToken: await getAdminToken(),
      body: {
        id,
      },
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminUnPublishDirectory = async (id) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/UnPublishDirectory',
      method: 'PUT',
      AuthToken: await getAdminToken(),
      body: {
        id,
      },
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminGetSecurityCodes = async () => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/GetSecurityCodes',
      method: 'GET',
      AuthToken: await getAdminToken(),
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminRegisterSecurityCode = async () => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/RegisterSecurityCode',
      method: 'POST',
      AuthToken: await getAdminToken(),
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminRefreshSecurityCode = async ({ securityCode }) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/RefreshSecurityCode',
      method: 'PUT',
      AuthToken: await getAdminToken(),
      body: {
        securityCode: securityCode,
      },
    });
    return result.securityCode ? result : { error: 'true' };
  } catch (err) {
    console.log('err', err);
  }
};

export const adminUpdateSecurityCodeName = async ({ name, securityCode }) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: '/api/UpdateSecurityCodeName',
      method: 'PUT',
      AuthToken: await getAdminToken(),
      body: {
        securityCode,
        name,
      },
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const adminDeleteSecurityCode = async ({ securityCode }) => {
  try {
    const result = await callApi({
      type: 'admin',
      endpoint: `/api/DeleteSecurityCode?&SecurityCode=${securityCode}`,
      method: 'DELETE',
      AuthToken: await getAdminToken(),
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

//--//--//
// USER //
//--//--//

export const loginUser = async (securitycode) => {
  try {
    let { functionKey } = await callApi({
      type: 'user',
      endpoint: '/api/Login',
      method: 'POST',
      body: { securitycode },
    });
    if (!functionKey) {
      //in dev there is no function key 
      functionKey = "I'm a key, trust me";
    }
    return functionKey;
  } catch (err) {
    console.log('err', err);
  }
};

export const userGetDirectories = async () => {
  try {
    const result = await callApi({
      type: 'user',
      endpoint: `/api/GetDirectories?code=${getUserToken()}&SecurityCode=${getUserSecretCode()}`,
      method: 'GET',
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const userGetWizardAnswers = async ({ wizard_id }) => {
  try {
    const result = await callApi({
      type: 'user',
      endpoint: `/api/GetWizardAnswers?code=${getUserToken()}&SecurityCode=${getUserSecretCode()}&WizardId=${wizard_id}`,
      method: 'GET',
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
};

export const userGetCategoryAnswers = async ({ wizard_id, category_id }) => {
  try {
    const result = await callApi({
      type: 'user',
      endpoint: `/api/GetCategoryAnswers?code=${getUserToken()}&SecurityCode=${getUserSecretCode()}&WizardId=${wizard_id}&CategoryId=${category_id}`,
      method: 'GET',
    });
    return result;
  } catch (err) {
    console.log('err', err);
  }
}

export const userSearchPwsId = async ({ pwsId }) => {
  const result = await callApi({
    type: 'user',
    endpoint: `/api/searchPwsId?code=${getUserToken()}`,
    method: 'POST',
    AuthToken: getUserToken(),
    body: {
      securityCode: getUserSecretCode(),
      pwsId: pwsId
    }
  });
  return result;
};

export const userStartNewWizardAnswers = async ({ pwsId }) => {
  const result = await callApi({
    type: 'user',
    endpoint: `/api/startNewWizardAnswers?code=${getUserToken()}`,
    method: 'POST',
    AuthToken: getUserToken(),
    body: {
      securityCode: getUserSecretCode(),
      pwsId: pwsId
    }
  });
  return result;
};

export const userUpdateWizardAnswers = async ({ wizardAnswers }) => {
  try {
    const result = await callApi({
      type: 'user',
      endpoint: `/api/UpdateWizardAnswers?code=${getUserToken()}`,
      method: 'PUT',
      AuthToken: getUserToken(),
      body: {
        securityCode: getUserSecretCode(),
        wizardAnswers: wizardAnswers
      }
    });
    return result || [];
  } catch (err) {
    console.log('err', err);
  }
};

export const userUpdateCategoryAnswers = async ({ categoryAnswers }) => {
  try {
    const serializedCategoryAnswers = { ...categoryAnswers };
    for (const question of serializedCategoryAnswers.questions) {
      if (question.groupSections) {
        for (const section of question.groupSections) {
          if (section.questions) {
            for (const innerQuestion of section.questions) {
              delete innerQuestion.groupFromData;
            }
          }
        }
      }
    }
    const result = await callApi({
      type: 'user',
      endpoint: `/api/UpdateCategoryAnswers?code=${getUserToken()}`,
      method: 'PUT',
      AuthToken: getUserToken(),
      body: {
        securityCode: getUserSecretCode(),
        ...serializedCategoryAnswers
      }
    });
    return result || [];
  } catch (err) {
    console.log('err', err);
  }
};

export const userSetLastVisitedQuestion = async (lastQuestion) => {
  try {
    const result = await callApi({
      type: 'user',
      endpoint: `/api/SetLastViewedQuestion?code=${getUserToken()}`,
      method: 'POST',
      AuthToken: getUserToken(),
      body: {
        securityCode: getUserSecretCode(),
        ...lastQuestion
      }
    });
    return result || [];
  } catch (err) {
    console.log('err', err);
  }
};
