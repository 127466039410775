import React from 'react';
import Link from '@material-ui/core/Link';

const ExternalLink = ({
    visable = true,
    link,
    text,
    style = { color: '#3f51b5' }
}) => {
    return (visable &&
        <Link 
            style={style}
            target="_blank"
            href={link}
            rel="noopener noreferrer"
        >
            {text || link}
        </Link>
    );
};

export default ExternalLink;