import React from 'react';
import {
    makeStyles,
    TableCell,
    TextField
} from '@material-ui/core';
import { COLUMN_TYPES } from '../../constants';

const useStyles = makeStyles({
    card: {
        minWidth: 120,
        minHeight: 275
    },

    title: {
        fontSize: 14,
    },
    cellTextField: {
        width: "100%",
        
        
    }

});

const TableCellInput = ({
    placeholder = '',
    cell,
    onChange
}) => {
    const classes = useStyles();
    let value = cell.value || '';

    return (<TableCell>
        <TextField
            value={value}
            type={cell.type === COLUMN_TYPES.text ? 'text' : 'number'}
            inputProps={{
                readOnly: cell.type === COLUMN_TYPES.calculated,
                style: { textAlign: cell.type === COLUMN_TYPES.text ? 'left' : 'right' }
            }}
            placeholder={placeholder}
            onChange={({ currentTarget: { value } }) => onChange(value)}
            className={classes.cellTextField}
            variant="outlined"
            
            margin='dense'
        />
    </TableCell>);
};

export default TableCellInput;