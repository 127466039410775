import React from 'react';
import { Link } from 'react-router-dom';
import {
    Paper
} from '@material-ui/core';

import { LinksContainer, LinkSectionsContainer, LinkSection } from './styled';

const staticPDFLinkSections = [
    {
        sectionName: 'Supplemental Documents',
        linkPrefixData: [
            { text: "The following Supplemental Documents can be used as a reference to gather the information needed to complete the final Risk and Resilience Assessment and Emergency Response Plan:" }
        ],
        links: [
            {
                path: '/files/epa-erp-template-and-instructions.pdf',
                text: 'PDF - EPA - ERP Template and Instructions'
            },
            {
                path: '/files/eap-community-based-water-resiliency-guide.pdf',
                text: 'PDF - EPA - Community Based Water Resiliency Guide'
            },
            {
                path: '/files/nrwa&epa-guidance-for-small-community-water-systems-on-risk-and-resilience-assessments-under-awia-final.pdf',
                text: 'PDF - NRWA & EPA Guidance for Small Community Water Systems on Risk and Resilience under AWIA'
            },
            {
                path: '/files/awia-factsheet.pdf',
                text: 'PDF - AWIA Fact Sheet'
            },
            {
                path: '/files/epa-baseline-information-for-malevolent-acts.pdf',
                text: 'PDF - EPA - Baseline Information for Malevolent Acts'
            },
            {
                path: '/files/epa-instructions-for-online-submission.pdf',
                text: 'PDF - EPA - Instructions for Online Submission'
            }
        ],
        linkSuffixData: []
    },
    {
        sectionName: 'Submitting Your Certification to the EPA',
        linkPrefixData: [
            { text: 'Please note that WRAPT does not certify your Risk and Resiliency Assessment and Emergency Response Plan completion. Use the following directions to submit your certification to the EPA' },
            { text: 'The certification statements provided below can be used by community water systems to certify completion of the Risk and Resilience Assessment and Emergency Response Plan:' }
        ],
        links: [
            {
                path: '/files/awia-erp-certification-form.pdf',
                text: 'PDF - AWIA ERP Certification Form'
            },
            {
                path: '/files/awia-risk-assessment-certification-form.pdf',
                text: 'PDF - AWIA Risk Assessment Certification Form'
            }
        ],
        linkSuffixData: [
            { text: 'Please use the appropriately title form provided above, which can be sent via the following methods:' },
            { 
                style: { fontWeight: 'bold', fontSize: '20px', marginRight: '10px' }, 
                text: '1. Email signed copy to U.S. EPA at  ', 
                link: { 
                    to: 'https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=awiasupport@epacdx.net',
                    text: 'awiasupport@epacdx.net'
                } 
            },
            { 
                style: { marginLeft: '20px' }, 
                text: 'In your email subject line please state "Risk and Resilience Assessment certification statement" or "Emmergency Response Plan certification statement", along with your PWSID number.'
            },
            { 
                style: { fontWeight: 'bold', fontSize: '20px' },
                text: '2. Mail signed copy to U.S. EPA at the folowing address:' 
            },
            {
                style: { fontStyle: 'italic', margin: '5px 0px 5px 20px', fontSize: '20px' },
                text: 'U.S. EPA Data Process Center' 
            },
            {
                style: { fontStyle: 'italic', margin: '5px 0px 5px 20px', fontSize: '20px' },
                text: 'ATTN: AWIA' 
            },
            {
                style: { fontStyle: 'italic', margin: '5px 0px 5px 20px', fontSize: '20px' },
                text: 'C/O CGI Federal' 
            },
            {
                style: { fontStyle: 'italic', margin: '5px 0px 5px 20px', fontSize: '20px' },
                text: '12601 Fair Lakes Circle' 
            },
            {
                style: { fontStyle: 'italic', margin: '5px 0px 5px 20px', fontSize: '20px' },
                text: 'Fairfax, VA 22033' 
            },
            {
                style: { fontWeight: 'bold', fontSize: '20px' },
                text: '3. Submit electronically through EPA website.' 
            },
            {
                style: { marginLeft: '20px' },
                text: 'Electronic Submission for Certification - Tutorial Video' 
            },
            {
                style: { marginLeft: '20px' }, 
                link: { 
                    to: 'https://youtu.be/_sED8MCVTgo',
                    text: 'https://youtu.be/_sED8MCVTgo' } 
                },
            {
                style: { marginLeft: '20px' },
                text: 'To set up an account for certification:' 
            },
            {
                style: { marginLeft: '20px' },
                link: { 
                    to: 'https://encromerr.epa.gov/registrationrequest/OW--AWIA--Certifying%20Official%20-%20Risk%20Assessment?theme=awia', 
                    text: 'https://encromerr.epa.gov/registrationrequest/OW--AWIA--Certifying%20Official%20-%20Risk%20Assessment?theme=awia' 
                }
            }
        ]
    }
];

const EPACertification = () => {

    const pdfLinkMapper = (sPDFLink) => {
        return (
            <li>
                <Link to={sPDFLink.path} target='_blank' download>{sPDFLink.text}</Link>
            </li>
        );
    };

    const textMapper = (textObject) => {
        const hasTextAndLink = !!textObject?.text && !!textObject.link;
        const linkOnly = !textObject?.text && !!textObject.link;
        const textOnly = !!textObject?.text && !textObject.link;

        let element = <></>;

        if (textOnly) {
            element = <p style={textObject.style || {}}>{textObject.text}</p>;
        } else if (linkOnly) {
            element = <a style={textObject.style} rel="noopener noreferrer" target='_blank' href={textObject.link.to}>{textObject.link.text}</a>;
        } else if (hasTextAndLink) {
            element = <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={textObject.style || {}}>{textObject.text}</p>
                <a rel="noopener noreferrer" target='_blank' href={textObject.link.to}>{textObject.link.text}</a>
            </div>;
        } else {
            console.log('We don\'t do that here: text mapper');
        }

        return element;
    }

    const sections = staticPDFLinkSections.map((sPDFLinkSection) => {
        return (
            <Paper>
                <LinkSection>
                    <h2>
                        {sPDFLinkSection.sectionName}
                    </h2>
                    <div>
                        {sPDFLinkSection.linkPrefixData.map(textMapper)}
                    </div>
                    <ul>
                        {sPDFLinkSection.links.map(pdfLinkMapper)}
                    </ul>
                    <div>
                        {sPDFLinkSection.linkSuffixData.map(textMapper)}
                    </div>
                </LinkSection>
            </Paper>
        );
    });

    return (
        <LinksContainer>
            <LinkSectionsContainer>
                <h1>EPA Certification</h1>
                {sections}
            </LinkSectionsContainer>
        </LinksContainer>
    );
};

export default EPACertification;

