import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 20px;
  align-items: flex-start;
`;

export const AnswerPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1005px;
  margin: 20px;
  padding: 10px 0px 25px 0px;
  align-items: flex-start;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1005px;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const IndexTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
  padding: 15px 20px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QuestionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
  padding: 15px 0;
`;

export const GroupQuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 15px 0;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
  color: #6c757d;
  word-break: break-word;
`;

export const DescriptionTitle = styled.p`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.063rem;
  font-style: italic;
  margin: 0;
`;

export const ButtonContainerLeft = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: space-between;
`;

export const ButtonContainerRight = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between;
`;

export const ValidationErrorMessage = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  font-size: 16px;
  color: #f44336;
  font-weight: 600;
`;
