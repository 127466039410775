import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 20px;
  padding: 30px 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-top: 40px;
`;

export const CategoryItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 85px;
  margin: 20px 0;
  color: #fff;
  background-color: #3f51b5;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
`;

export const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 3;
`;

export const CategoryActions = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const CategorySubTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
  text-transform: capitalize;
  font-size: 19px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  margin-top: 30px;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Placeholder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 150px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 50px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: #777;
  background: linear-gradient(60deg, #eee, #d6d3d3);
  border-radius: 5px;
  opacity: 0.7;
`;

export const UserCategoryItem = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 20px;
`;

export const UserCategoryTitleContainer = styled.div`
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: flex-start;
  min-width: 250px;
  max-width: 380px;
  font-size: 20px;
  margin-right: 20px;
  flex: 60%;
`;

export const UserCategoryTitle = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
