import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {
    Card,
    CardHeader, 
    CardContent,
    makeStyles
 } from '@material-ui/core';
 import {
    Delete
 } from '@material-ui/icons';

import BaseModal from '../../../../components/Modals/BaseModal';
import DropDown from '../../../../components/Inputs/DropDown';
import { CustomButton } from '../../../../components/Button';
import { ANSWER_TYPES, QUESTION_TYPES, QUESTION_DEFAULT_FROM_TYPE_FILTERS, QUESTION_DEFAULT_FROM_FILTER_TYPES, QUESTION_TYPE_METADATA } from '../../../../constants';

  const useStyles = makeStyles({
    cardContainer: {
        marginBottom: '50px',
        marginTop: '25px'
    },
    cardHeaderContainer: {
        background: 'linear-gradient(60deg, rgb(5, 91, 187), rgb(16, 73, 167))',
        color: '#fff'
    }
  });

  const questionsFilter = (type, questions) => {
    const typeFilters = QUESTION_DEFAULT_FROM_TYPE_FILTERS[type];

    if (!typeFilters) {
      return [];
    }
  
    const itemsFilteredBasedOnValidTypes = questions.filter((q) => typeFilters.includes(q.type) ||
                                                            (q.type === QUESTION_TYPES.GROUP && !q.groupOptions?.isRepeatable));
  
    return itemsFilteredBasedOnValidTypes;
  };

  const createWizardOptions = (wizards) => wizards.map(w => ({ label: `${w.directoryName} / ${w.subDirectoryName}`, value: w.wizardId }));
  
  const DefaultsFromModal = ({
    type,
    wizards,
    wizardId,
    categoryId,
    defaultsFrom,
    localOptions,
    onChange
  }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const defaultFromLimit = 2;
    const [localDefaultFromValuesAreValid, setLocalDefaultFromValuesAreValid] = useState(false);
    const [localDefaultFromValues, setLocalDefaultFromValues] = useState(defaultsFrom || [
        {
            wizardId: wizardId,
            categoryId: categoryId,
            questionId: null,
            innerQuestionId: null,
            filter: null
        }
    ]);

    const addDefaultFrom = () => {
        if (localDefaultFromValues.length < defaultFromLimit) {
            const newDefaultFromValues = _.cloneDeep(localDefaultFromValues);

            newDefaultFromValues.push({
                wizardId: wizardId,
                categoryId: categoryId,
                questionId: null,
                innerQuestionId: null,
                filter: null
            });
    
            setLocalDefaultFromValues(newDefaultFromValues);
        }
    };
    const editDefaultFrom = (value, index) => {
        const newDefaultFromValues = _.cloneDeep(localDefaultFromValues);
        newDefaultFromValues[index] = value;

        const allValuesAreValid = !(newDefaultFromValues.map((nDFV) => !!nDFV.questionId && nDFV.questionId !== ANSWER_TYPES.empty)).includes(false);

        setLocalDefaultFromValues(newDefaultFromValues);
        setLocalDefaultFromValuesAreValid(allValuesAreValid);
    };
    const deleteDefaultFrom = (index) => {
        const newDefaultFromValues = _.cloneDeep(localDefaultFromValues);
        newDefaultFromValues.splice(index, 1);

        const allValuesAreValid = !!newDefaultFromValues.length && !(newDefaultFromValues.map((nDFV) => !!nDFV.questionId && nDFV.questionId !== ANSWER_TYPES.empty)).includes(false);

        setLocalDefaultFromValues(newDefaultFromValues);
        setLocalDefaultFromValuesAreValid(allValuesAreValid);
    }

    const onSave = () => {
      toggle();
      onChange(localDefaultFromValues);
    };

    const onClear = () => {
      onChange(null);
      toggle();
    }

    return (
      <BaseModal 
        isOpen={open}
        toggle={toggle}
        paperStyles={{ width: '30%', height: '75%' }}
        buttonText={!!defaultsFrom?.length ?
              "Change the default value(s)"
            :
              "Set the default value(s)"
        }
      >
            <div>
                <h2>Where do you want the default value(s) to come from</h2>
                {localDefaultFromValues.length > 1 && (
                    <div style={{ marginTop: '20px' }}>
                        <p>We will produce question sections for all unique combinations of the answers to the following questions that we are defaulting from.</p>
                    </div>
                )}
                <div>
                    <CustomButton
                        onClick={addDefaultFrom}
                        disabled={localDefaultFromValues.length >= defaultFromLimit}
                        size={'large'}
                        style={{ margin: '5px 0' }}
                        variant={'contained'}
                        color='primary'
                    >
                        Add default from value
                    </CustomButton>
                </div>
            </div>
            <div>
                {localDefaultFromValues.map((lDFV, index) => 
                    <DefaultFromForm 
                        index={index}
                        canDelete={index !== 0}
                        defaultFrom={lDFV} 
                        type={type} 
                        wizards={wizards} 
                        localOptions={localOptions} 
                        wizardId={wizardId} 
                        categoryId={categoryId} 
                        onChange={(value) => editDefaultFrom(value, index)} 
                        onDelete={() => deleteDefaultFrom(index)}
                    />
                )}
            </div>
            <div style={{display: 'flex'}}>
                <CustomButton 
                    onClick={onSave}
                    disabled={!localDefaultFromValuesAreValid}
                    style={{ margin: 8, marginTop: 12 }}
                    size='medium'
                    color={'success'}
                    variant={'outlined'}
                >Save</CustomButton>
                {!!defaultsFrom?.length && <CustomButton 
                    onClick={onClear}
                    style={{ margin: 8, marginTop: 12 }}
                    size='medium'
                    color='danger'
                    variant={'outlined'}
                    >Clear</CustomButton>}
            </div>
      </BaseModal>);
  };

  const DefaultFromForm = ({ 
        index,
        defaultFrom,
        type,
        wizards,
        localOptions,
        wizardId,
        categoryId,
        onChange, 
        canDelete,
        onDelete
    }) => {
    const classes = useStyles();
    const wizardOptions = createWizardOptions(wizards);
    const [selectedWizardId, setSelectedWizardId] = useState(defaultFrom?.wizardId || wizardId);
    const [selectedCategoryId, setSelectedCategoryId] = useState(defaultFrom?.categoryId || categoryId);
    const [selectedQuestionId, setSelectedQuestionId] = useState(defaultFrom?.questionId || ANSWER_TYPES.empty);
    const [showFilterForm, setShowFilterForm] = useState(false);
    const [currentCategoryOptions, setCurrentCategoryOptions] = useState([]);
    const [currentCategoryQuestions, setCurrentCategoryQuestions] = useState([]);
    const [currentInnerQuestions, setCurrentInnerQuestions] = useState([]);
    const [filter, setFilter] = useState(defaultFrom?.filter || null);

    useEffect(() => {
        const categories = wizards.find(w => w.wizardId === selectedWizardId).wizard.categories;
        const newCurrentCategory = categories.find(c => c.id === selectedCategoryId);

        setCurrentCategoryOptions(categories.map(c => ({ label: c.name, value: c.id })));
        setCurrentCategoryQuestions(
            !newCurrentCategory ?
            []
            :  
            questionsFilter(type, newCurrentCategory.questions).map(cCQ => ({ value: cCQ.id, label: cCQ.question }))
        );
        if (newCurrentCategory && selectedQuestionId !== ANSWER_TYPES.empty) {
            const newCurrentQuestion = newCurrentCategory.questions.find(q => q.id === selectedQuestionId);

            if (newCurrentQuestion && newCurrentQuestion.type === QUESTION_TYPES.GROUP) {
                setCurrentInnerQuestions(newCurrentQuestion.questions.filter((question) => QUESTION_DEFAULT_FROM_FILTER_TYPES.includes(question.type)));
                setShowFilterForm(true);
            }
        } else {
            setCurrentInnerQuestions([]);
            setFilter(null);
            setShowFilterForm(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWizardId, selectedCategoryId, selectedQuestionId])

    useEffect(() => {
        onChange({ wizardId: selectedWizardId, categoryId: selectedCategoryId, questionId: selectedQuestionId, innerQuestionId: null, filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWizardId, selectedCategoryId, selectedQuestionId, filter]);

    return (
        <Card className={classes.cardContainer}>
            <CardHeader 
                className={classes.cardHeaderContainer} 
                title={`Default From #${index + 1}`} 
                action={canDelete ? 
                        <CustomButton color='primary' onClick={() => onDelete(index)}>
                            <Delete />
                        </CustomButton>
                    :
                        null
                }
            />
            <CardContent>
                <DropDown
                    includeEmptyOption={false}
                    selectedValue={selectedWizardId}
                    options={wizardOptions}
                    onSelect={(value) => {
                        setSelectedWizardId(value);
                        setSelectedCategoryId(ANSWER_TYPES.empty);
                        setSelectedQuestionId(ANSWER_TYPES.empty);
                    }}
                    selectHelperText={"Select the wizard"}
                />
                <DropDown 
                    selectedValue={selectedCategoryId}
                    options={currentCategoryOptions}
                    onSelect={setSelectedCategoryId}
                    selectHelperText={"Select the category"}
                />
                <DropDown
                    selectedValue={selectedQuestionId}
                    options={
                        selectedCategoryId === categoryId ?
                        localOptions.map(lO => ({ value: lO.id, label: `Question #${lO.index + 1}` }))
                        :
                        currentCategoryQuestions
                    }
                    onSelect={setSelectedQuestionId}
                    selectHelperText={
                        selectedCategoryId === categoryId ?
                        "Default answer from previous questions"
                        :
                        "Default answer from a question in another category"
                    }
                />
                {showFilterForm && !!currentInnerQuestions.length && <DefaultFromFilterForm filter={filter} questions={currentInnerQuestions} setFilter={setFilter} />}
            </CardContent>
        </Card>
    );
  };

  const DefaultFromFilterForm = ({ filter, questions, setFilter }) => {
    const createValueOptions = (question) => {
        if (!question) {
            return [];
        } else {
            let options = [];

            switch (question.type) {
                case QUESTION_TYPES.PRIORITYLEVEL:
                case QUESTION_TYPES.COMPLETIONDATE:
                    options = QUESTION_TYPE_METADATA[question.type].values.map((v) => ({ label: v, value: v }));
                    break;
                case QUESTION_TYPES.MULTIPLECHOICE:
                case QUESTION_TYPES.SINGLECHOICE:
                case QUESTION_TYPES.SINGLESELECT:
                    options = question.options.map((o) => ({ label: o.option, value: o.value }));
                    break;
                case QUESTION_TYPES.YESNO:
                    options = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No'}, { label: 'N/A', value: 'N/A' }];
                    break;
                default:
                    break;
            }

            return options;
        }
    }

    const [selectedQuestionId, setSelectedQuestionId] = useState(filter?.questionId);
    const [valueOptions, setValueOptions] = useState(createValueOptions(questions.find(question => question.id === filter?.questionId)));
    const [selectedValue, setSelectedValue] = useState(filter?.value);

    useEffect(() => {
        if (!selectedValue) {
            setFilter(null);
        } else {
            setFilter({
                questionId: selectedQuestionId,
                value: selectedValue
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div>
                <DropDown 
                    selectedValue={selectedQuestionId}
                    selectHelperText={"Select the question you want to filter generated content with"}
                    onSelect={(value) => {
                        setSelectedQuestionId(value);
                        setValueOptions(createValueOptions(questions.find(question => question.id === value)));
                    }}
                    options={questions.map(q => ({ value: q.id, label: q.question }))}
                />
            </div>
            {!!selectedQuestionId && 
                <div>
                    <DropDown 
                        selectedValue={selectedValue}
                        selectHelperText={"Select the value you want to filter by"}
                        onSelect={setSelectedValue}
                        options={valueOptions}
                    />
                </div>
            }
        </div>
    );
  };

export default DefaultsFromModal;