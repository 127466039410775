const initialState = {
  loading: false,
  pwsSearching: false,
  pwsSearchResult: null,
  lastLocation: null,
  hasStarted: false,
  PDF_data: [],
  drinking: {
    published: false,
  },
  waste: {
    published: false,
  },
  contactinfo: {
    published: false,
  },
};

export const User = (state = initialState, { payload, type }) => {
  switch (type) {
    case 'GET_USER_DIRECTORIES_START':
      return {
        ...state,
        loading: true,
      };
    
    case 'GET_USER_DIRECTORIES_SUCCESS':
      const contactDir = state.contactinfo;
      const drinkDir = state.drinking;
      const wasteDir = state.waste;

      let loadedAllDirectoriesContent = true;

      if (
        contactDir.contactinfo?.wizardAnswers?.categories &&
        drinkDir.emergency_response_plan?.wizardAnswers?.categories &&
        drinkDir.risk_assessment?.wizardAnswers?.categories &&
        wasteDir.emergency_response_plan?.wizardAnswers?.categories &&
        wasteDir.risk_assessment?.wizardAnswers?.categories
      ) loadedAllDirectoriesContent = false;

      return {
        ...state,
        loading: loadedAllDirectoriesContent,
      };

    case 'SET_USER_DIRECTORIES_START':
      return {
        ...state,
        loading: true,
      };

    case 'SET_USER_DIRECTORIES_SUCCESS':
      return {
        ...state,
        loading: false,
        drinking: payload.drinking,
        waste: payload.waste,
        contactinfo: payload.contactinfo,
        hasStarted: payload.hasStarted,
        lastLocation: payload.lastLocation
      };

    case 'SET_USER_DIRECTORIES_FAIL':
      return {
        ...state,
        loading: false,
      };

    case 'START_NEW_USER_ANSWERS_START':
      return {
        ...state,
        pwsSearching: true,
        pwsSearchError: null
      };
    case 'START_NEW_USER_ANSWERS_SUCCESS':
      return {
        ...state,
        pwsSearching: false,
        hasStarted: true,
        pwsSearchError: null
      };
    case 'START_NEW_USER_ANSWERS_FAIL':
      return {
        ...state,
        pwsSearching: false,
        hasStarted: false,
        pwsSearchError: payload.errorMessage
      };

    case 'SEARCH_PWS_ID_START':
      return {
        ...state,
        pwsSearching: true,
        pwsSearchError: null,
        pwsSearchResult: null
      };
    case 'SEARCH_PWS_ID_SUCCESS':
      return {
        ...state,
        pwsSearching: false,
        pwsSearchResult: payload.pwsSearchResult,
        pwsSearchError: null
      };
    case 'SEARCH_PWS_ID_FAIL':
      return {
        ...state,
        pwsSearching: false,
        pwsSearchResult: null,
        pwsSearchError: payload.errorMessage
      };

    case 'SET_USER_WIZARD_ANSWERS_SUCCESS':
      return {
        ...state,
        [payload.directory]: {
          ...state[payload.directory],
          [payload.sub_directory]: {
            ...state[payload.directory][payload.sub_directory],
            wizardAnswers: {
              ...payload.wizardAnswers,
              categories: state[payload.directory][payload.sub_directory].wizardAnswers?.categories
            },
          },
        },
      };

    case 'UPDATE_USER_ANSWERS_START':
      return {
        ...state,
        loading: true,
      };

    case 'UPDATE_USER_ANSWERS_SUCCESS':

      let categoryName = state[payload.directory][payload.sub_directory].wizardAnswers.categoryOutlines.find(x => x.categoryId === payload.category_id)?.categoryName;
      let unEditedCategories = state[payload.directory][payload.sub_directory].wizardAnswers.categories.filter(x => x.categoryId !== payload.category_id);

      return {
        ...state,
        loading: false,
        [payload.directory]: {
          ...state[payload.directory],
          [payload.sub_directory]: {
            ...state[payload.directory][payload.sub_directory],
            wizardAnswers: {
              ...state[payload.directory][payload.sub_directory].wizardAnswers,
              categories: [
                ...unEditedCategories,
                {
                  categoryId: payload.category_id,
                  categoryName: categoryName,
                  questions: payload.categoryAnswers
                }
              ]
            }
          }
        }
      }

    case 'UPDATE_USER_ANSWERS_FAIL':
      return {
        ...state,
        loading: false,
      };

    case 'UPDATE_All_USER_ANSWERS_SUCCESS':
      return {
        ...state,
        loading: false,
        [payload.directory]: {
          ...state[payload.directory],
          [payload.sub_directory]: {
            ...state[payload.directory][payload.sub_directory],
            wizardAnswers: {
              ...state[payload.directory][payload.sub_directory].wizardAnswers,
              categories: payload.categories
            }
          }
        }
      }

    case 'SET_ALL_ANSWERS_FOR_ALL_WIZARDS_SUCCESS':
      let allAnswersState = { ...state };
      let allAnswersCategories = [];
      for (const category of payload.categories) {
        let categoryName = state[payload.directory][payload.sub_directory].wizardAnswers.categoryOutlines.find(x => x.categoryId === category.categoryId)?.categoryName;
        allAnswersCategories.push({
          categoryId: category.categoryId,
          categoryName: categoryName,
          questions: category.categoryAnswers
        });
      }
      allAnswersState = {
        ...allAnswersState,
        [payload.directory]: {
          ...allAnswersState[payload.directory],
          [payload.sub_directory]: {
            ...allAnswersState[payload.directory][payload.sub_directory],
            wizardAnswers: {
              ...allAnswersState[payload.directory][payload.sub_directory].wizardAnswers,
              categories: allAnswersCategories
            }
          }
        }
      };
      return allAnswersState;

    default:
      return state;
  }
};
