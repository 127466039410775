import Cookies from 'js-cookie';
import { MSAL_Logout } from '../../services/identity/Msal_login/msalLogout';
import { loginUser } from '../../services/identity';
// change all cookies set into fetch calls

export const userLogin = (secretCode) => async (dispatch) => {
  const payload = await loginUser(secretCode);

  Cookies.set('accessToken', payload);
  Cookies.set('secretCode', secretCode);
  Cookies.set('userType', 'user');

  await dispatch({
    type: 'USER_LOGIN',
    payload,
  });

  return payload;
};

export const userLogout = () => async (dispatch) => {
  Cookies.remove('accessToken');
  Cookies.remove('secretCode');
  Cookies.remove('userType');

  await dispatch({
    type: 'USER_LOGOUT',
  });
};

export const setBranchData = (payload) => async (dispatch) => {
  dispatch({
    type: 'SET_BRANCH_DATA',
    payload,
  });
};

export const setCurrentCategoryData = (payload) => async (dispatch) => {
  dispatch({
    type: 'SET_CURRENT_CATEGORY_DATA',
    payload,
  });
};

export const setWithHeader = (payload) => async (dispatch) => {
  dispatch({
    type: 'SET_GLOBAL_HEADER',
    payload,
  });
};

export const setWithFooter = (payload) => async (dispatch) => {
  dispatch({
    type: 'SET_GLOBAL_FOOTER',
    payload,
  });
};

export const setWizardData = (payload) => async (dispatch) => {
  dispatch({
    type: 'SET_WIZARD_QUESTIONS_DATA',
    payload,
  });
};

export const login = (payload) => async (dispatch) => {
  Cookies.set('accessToken', payload.accessToken);
  Cookies.set('userType', payload.userType);
  //console.log('Cookies', Cookies.get());
  await dispatch({
    type: 'LOG_IN',
    payload,
  });
  return new Promise((resolve) => {
    resolve({ success: true });
  });
};

export const logout = () => async (dispatch) => {
  Cookies.remove('accessToken');
  Cookies.remove('userType');
  
  MSAL_Logout();

  await dispatch({
    type: 'LOG_OUT',
  });
  
  return {success: true};
};
