import React from 'react';
import ExternalLink from './ExternalLink';

const AdditionalInfoLink = ({ 
    additionalLink, 
    containerStyles = { display: 'flex', marginLeft: '14px' }, 
    preLinkStyles = { margin: '0px 8px 20px 0px' }
}) => {
    return (
        <div style={containerStyles}>
            <p style={preLinkStyles}>Additional Info: </p>
            <ExternalLink link={additionalLink.link} text={additionalLink.text} />
        </div>
    );
};

export default AdditionalInfoLink;