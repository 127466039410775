import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { updateCategoryAnswers } from '../../../store/user/actions';
import {
  Container,
  ControlsContainer,
  ButtonContainerLeft,
  ButtonContainerRight
} from './styled';
import { PaginationCustom as Pagination } from '../../../components/Pagination';
import { LinearProgressComponent as LinearProgress } from '../../../components/LinearProgress';
import { CustomButton } from '../../../components/Button';
import { AnswerComponent } from './AnswerComponent';
import { applyDefaultFromValue, constructQuestionAnswer, getOptionsFromGroup } from './helpers';
import { QUESTION_TYPES, ANSWER_TYPES } from '../../../constants';
import LoadingOverlay from '../../../components/LoadingIndicators/LoadingOverlay';

export const AnswerForms = withRouter(({ questionAnswers, history, lastLocation, directory, sub_directory }) => {
  const dispatch = useDispatch();
  const {
    currentWizardId,
    currentCategoryId,
    currentCategoryName,
    currentQuestionId
  } = lastLocation || { currentWizardId: null, currentCategoryId: null, currentCategoryName: null, currentQuestionId: null };
  const [answers, setAnswers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState({
    id: null,
    type: '',
    question: '',
    description: '',
    additionalInfo: '',
    additionalLink: null,
    options: [],
    orderindex: 0,
  });

  useEffect(() => {
    setProgress(((offset + 1) / answers.length) * 100);
    if (answers[offset]) {
      setCurrentQuestion(answers[offset]);
      // setCurrentQuestionIsValid(answerValidator(answers[offset]));
    }
  }, [offset, answers]);

  useEffect(() => {
    const formatedQuestionAnswers = questionAnswers.map((questionAnswer) => constructQuestionAnswer(questionAnswer));
    const answerIndex = formatedQuestionAnswers.findIndex(a => a.answer.questionId === currentQuestionId);

    setAnswers(formatedQuestionAnswers);
    if (answerIndex !== -1) setOffset(answerIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionAnswers]);

  const onNoteEdit = ({ note, id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex((a) => a.id === id);
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      answer: {
        ...updatedAnswers[index].answer,
        note: note,
      },
    };
    setAnswers([...updatedAnswers]);
  };

  const onOtherOptionsEdit = ({ otherOptions, id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex((a) => a.id === id);
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      answer: {
        ...updatedAnswers[index].answer,
        otherOptions: otherOptions,
      },
    };
    setAnswers([...updatedAnswers]);
  };

  const getAllIndexesForLocallySubscribedQuestions = (arr, id) => {
    const indexes = []
    for(let i = 0; i < arr.length; i++) {
      if (arr[i].defaultFrom && arr[i].defaultFrom.questionId === id) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  const onEdit = ({ answer, id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex((a) => a.id === id);
    const lSQIndexes = updatedAnswers[index].questionId && updatedAnswers[index].categoryId === currentCategoryId ?
      getAllIndexesForLocallySubscribedQuestions(updatedAnswers, updatedAnswers[index].questionId)
    : [];

    updatedAnswers[index] = {
      ...updatedAnswers[index],
      answer: {
        ...updatedAnswers[index].answer,
        value: answer
      },
    };
    lSQIndexes.forEach(lSQIndex => applyDefaultFromValue(updatedAnswers[lSQIndex], updatedAnswers[index]));

    setAnswers([...updatedAnswers]);
  };

  const onTableEdit = ({ table, id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex((a) => a.id === id);

    updatedAnswers[index] = {
      ...updatedAnswers[index],
      table,
      answer: {
        ...updatedAnswers[index].answer,
        value: ANSWER_TYPES.empty
      },
    };

    setAnswers([...updatedAnswers]);
  }

  const onChoose = ({ options, id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    if (answers.length) {
      const updatedAnswers = [...answers];
      const index = answers.findIndex((a) => a.id === id);
      const lSQIndexes = updatedAnswers[index].questionId && updatedAnswers[index].categoryId === currentCategoryId ?
        getAllIndexesForLocallySubscribedQuestions(updatedAnswers, updatedAnswers[index].questionId)
      : [];

      updatedAnswers[index] = {
        ...updatedAnswers[index],
        answer: {
          ...updatedAnswers[index].answer,
          options,
        },
      };
      lSQIndexes.forEach(lSQIndex => applyDefaultFromValue(updatedAnswers[lSQIndex], updatedAnswers[index]));

      setAnswers([...updatedAnswers]);
    }
  };

  const onGroupEdit = ({id, data}) => {
    if (submitted) {
      setSubmitted(false);
    }

    const updatedAnswers = [...answers];
    const index = answers.findIndex((a) => a.id === id);

    updatedAnswers[index] = {
      ...updatedAnswers[index],
      groupSections: data,
      answer: {
        ...updatedAnswers[index].answer,
        value: ANSWER_TYPES.empty
      },
    };

    const updatedQuestion = updatedAnswers[index];
    updatedAnswers.forEach(questionAnswer => {
      if (questionAnswer.optionsFromPreviousAnswers && questionAnswer.optionsFrom && questionAnswer.optionsFrom.questionId === updatedQuestion.questionId) {
        updateOptionsForQuestionAnswer(updatedQuestion, questionAnswer);
      } else if (questionAnswer.type === QUESTION_TYPES.GROUP && questionAnswer.groupSections) {
        questionAnswer.groupSections.forEach((section) => {
          section.questions.forEach((innerQuestionAnswer) => {
            if (innerQuestionAnswer.optionsFromPreviousAnswers && innerQuestionAnswer.optionsFrom && innerQuestionAnswer.optionsFrom.questionId === updatedQuestion.questionId) {
              updateOptionsForQuestionAnswer(updatedQuestion, innerQuestionAnswer);
            } 
          });
        });
        questionAnswer.groupQuestionsCopy.questions.forEach((innerQuestionAnswer) => {
          if (innerQuestionAnswer.optionsFromPreviousAnswers && innerQuestionAnswer.optionsFrom && innerQuestionAnswer.optionsFrom.questionId === updatedQuestion.questionId) {
            updateOptionsForQuestionAnswer(updatedQuestion, innerQuestionAnswer);
          } 
        });
      }
    });

    setAnswers([...updatedAnswers]);
  };

  const updateOptionsForQuestionAnswer = (groupQuestion, innerQuestionAnswer) => {
    const options = getOptionsFromGroup(groupQuestion, innerQuestionAnswer.optionsFrom.innerQuestionId);
    innerQuestionAnswer.options = options;
    const selectedOption = innerQuestionAnswer.answer.options ? innerQuestionAnswer.answer.options.find(o => o.value)?.option : null;
    innerQuestionAnswer.answer.options = options.map((o) => ({ option: o, value: o === selectedOption }));
  }

  const onSave = async () => {
    setSubmitted(true);
    setIsLoading(true);
    
    let havePassedCurrentQuestion = false;
    const updatedQuestionAnswers = answers.map((a) => {
      let lastLocation = null;

      if (a.id === currentQuestion.id) {
        lastLocation = {
          wizardId: currentWizardId,
          categoryId: currentCategoryId,
          categoryName: currentCategoryName,
          questionId: a.questionId,
          questionName: a.question
        };
        havePassedCurrentQuestion = true;
      } else if (!havePassedCurrentQuestion) {
        lastLocation = {
          wizardId: currentWizardId,
          categoryId: currentCategoryId,
          categoryName: currentCategoryName,
          questionId: a.questionId,
          questionName: a.question
        };
      }

      return {
        questionId: a.questionId,
        question: a.question,
        type: a.type,
        description: a.description,
        options: a.options,
        optionsFromPreviousAnswers: a.optionsFromPreviousAnswers,
        optionsFrom: a.optionsFrom,
        optionsSpecifyOthers: a.optionsSpecifyOthers,
        defaultFrom: a.defaultFrom,
        moreInfoLabel: a.moreInfoLabel,
        table: a.table,
        additionalInfo: a.additionalInfo,
        groupSections: a.groupSections,
        groupOptions: a.groupOptions,
        sectionHeading: a.sectionHeading,
        lastLocation,
        answer: {
          id: a.answer.id,
          categoryId: a.answer.categoryId,
          questionId: a.answer.questionId,
          value: a.answer.value,
          options: a.answer.options,
          otherOptions: a.answer.otherOptions,
          note: a.answer.note,
          userId: a.answer.userId
        }
      }
    });
      
    await dispatch(
      updateCategoryAnswers({
        directory,
        sub_directory,
        questions: updatedQuestionAnswers,
        wizard_id: currentWizardId,
        category_id: currentCategoryId,
        category_name: currentCategoryName
      })
    );

    setSubmitted(false);
    setIsLoading(false);
    history.push('/categories');
  };

  const onNext = async () => {
    setIsLoading(true);
    const formatedQuestionAnswers = answers.map((questionAnswer) => constructQuestionAnswer(questionAnswer, answers));
    setAnswers(formatedQuestionAnswers);
    setIsLoading(false);

    if (offset < answers.length - 1) {
      setOffset(offset + 1);
    }
  };

  const { id } = currentQuestion;
  const getAnswer = (answers, id) => answers.filter((el) => el.id === id)[0];
  const answer = getAnswer(answers, id);

  if (!answer) {
    return <div></div>;
  }

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      <ControlsContainer>
        <LinearProgress
          additionalText={`Question ${offset + 1} of ${answers.length}`}
          progress={progress}
        />
        <Pagination
          offset={offset}
          total={answers.length}
          limit={1}
          callBack={setOffset}
        />
      </ControlsContainer>
      <AnswerComponent
        data={currentQuestion}
        offset={offset}
        methods={{
          onEdit,
          onGroupEdit,
          onTableEdit,
          onNoteEdit,
          onOtherOptionsEdit,
          onChoose
        }}
      />
      <ControlsContainer>
        <ButtonContainerLeft>
          <CustomButton
            onClick={() => {
              if (offset >= 1) {
                setOffset(offset - 1);
              }
            }}
            disabled={offset === 0}
            size={'large'}
            style={{ margin: '5px 0' }}
            variant={'contained'}
            color='primary'
          >
            back
          </CustomButton>
          <CustomButton
            onClick={onNext}
            disabled={offset === answers.length - 1}
            size={'large'}
            style={{ margin: '5px 0' }}
            variant={'contained'}
            color='primary'
          >
            next
          </CustomButton>
        </ButtonContainerLeft>
        <ButtonContainerRight>
          <CustomButton
            onClick={onSave}
            size={'large'}
            // disabled={!currentQuestionIsValid}
            style={{ margin: '5px 0', backgroundColor: 'green' }}
            variant={'contained'}
            color='primary'
          >
            Save and close
          </CustomButton>
        </ButtonContainerRight>
      </ControlsContainer>
    </Container>
  );
});
