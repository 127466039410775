import React, { useState, useEffect } from 'react';
import {
    Menu,
    Fade
} from '@material-ui/core';
import { CustomButton } from './index';
import { ANSWER_TYPES } from '../../constants';
import { MenuItemStyled, MenuTitle, MenuItemText } from './styled';

const SelectMenu = ({
    title = '',
    options = [],
    showButton = true,
    buttonText,
    style,
    externalAnchor,
    externalCloseAction,
}) => {
    const [anchorEl, setAnchor] = useState(null);

    const toggleOptionsMenu = (event) => setAnchor(!!anchorEl ? null : event.currentTarget);

    useEffect(() => {
        if (externalAnchor !== undefined)
            setAnchor(externalAnchor);
    }, [externalAnchor]);

    return <div>
        {showButton && (
            <div style={style || {margin: '8px'}}>
                <CustomButton color='primary' onClick={toggleOptionsMenu}>
                    {buttonText}
                </CustomButton>
            </div>
        )}
        <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={externalCloseAction || toggleOptionsMenu}
            TransitionComponent={Fade}
        >
            {title && <MenuTitle>{title}</MenuTitle>}
            {options.map(option => {
                return (
                    <MenuItemStyled
                        onClick={() => {
                            option.action();
                            if (externalCloseAction) {
                                externalCloseAction();
                            } else {
                                toggleOptionsMenu();
                            }
                        }}
                    >
                        {option.icon && React.createElement(option.icon)}
                        <MenuItemText>{option.label || ANSWER_TYPES.empty }</MenuItemText>
                    </MenuItemStyled>
                );
            })}
        </Menu>
    </div>
}

export default SelectMenu;