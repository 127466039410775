import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { MSAL_Logout } from './identity/Msal_login/msalLogout';
import { ERROR_TYPES } from '../constants';

export class ApiException {
  constructor(message, errorType) {
    this.message = message;
    this.errorType = errorType;
  }
}

export const apiErrorsHandler = async (status, displayToast = true) => {
  let message = '';
  switch (status) {
    case 401:
      Cookies.remove('secretCode');
      Cookies.remove('accessToken');
      Cookies.remove('userType');
      MSAL_Logout();
      message =  `Sorry, looks like your session has expired, please try login again`;
      if (displayToast) {
        toast.error(message);
      }
      throw new ApiException(message, ERROR_TYPES.NOT_AUTHORIZED);

    case 404:
      message ='Sorry, nothing was found';
      if (displayToast) {
        toast.error(message);
      }
      throw new ApiException(message, ERROR_TYPES.NOT_FOUND);

    case 500:
      message = 'Sorry, something went wrong, please try again';
      if (displayToast) {
        toast.error(message);
      }
      throw new ApiException(message, ERROR_TYPES.SERVER_ERROR);

    default:
      message = 'Sorry, something went wrong';
      if (displayToast) {
        toast.error(message);
      }
      throw new ApiException(message, ERROR_TYPES.UNKNOWN);
  }
};
