import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1005px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 1.5em;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 20px;
  padding: 70px 0;
`;
