import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

const AdditionalLinkForm = ({
    additionalLink,
    onChange,
    disabled = false,
    linkStyles = { margin: 8, marginTop: 20, marginRight: 20 },
    textStyles = { margin: 8, marginTop: 20, marginRight: 0 }
}) => {
    const [link, setLink] = useState(additionalLink?.link || '');
    const [text, setText] = useState(additionalLink?.text || '');

    useEffect(() => {
        if (!link) {
            onChange(null);
        } else {
            onChange({
                link,
                text: !text ? link : text
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link, text]);

    return <div style={{ display: 'flex' }}>
        <TextField
            value={link}
            onChange={({ currentTarget: { value } }) => setLink(value)}
            disabled={disabled}
            label='Additional Info Link (optional)'
            style={linkStyles}
            helperText={"Attach an additional info link if it's necessary"}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            margin='dense'
            variant='outlined'
        />
        <TextField
            value={text === link ? '' : text}
            onChange={({ currentTarget: { value } }) => setText(value)}
            disabled={disabled || !link}
            label='Additional Info Link Text (optional)'
            style={textStyles}
            helperText={"Additional Link Text (optional)"}
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            margin='dense'
            variant='outlined'
        />
    </div>;
};

export default AdditionalLinkForm;