import React from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWithHeader, setWithFooter } from '../../store/common/actions';

export const CustomRoute = ({
  component,
  withHeader = true,
  withFooter = true,
  path,
  exact = false
}) => {
  const dispatch = useDispatch();
  dispatch(setWithHeader(withHeader));
  dispatch(setWithFooter(withFooter));

  return <Route exact={exact} path={path} component={component} />;
};
