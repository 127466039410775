import styled from 'styled-components';
import img from '../../assets/images/bg-water.jpg';
import AppBar from '@material-ui/core/AppBar';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background-image: url(${img});
  background-position: center center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  &::after {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
    background: rgba(101, 47, 142, 0.64);
    background: linear-gradient(
      45deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -moz-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -webkit-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
  }
`;

export const AlignWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 20px;
  padding: 25px 0;
  z-index: 1;
`;

export const AppBarStyled = styled(AppBar)`
  position: absolute;
  display: flex;
  width: 95% !important;
  height: 65px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: -60px;
`;

export const Description = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 25px;
  height: 125px;
`;

export const ShortDescription = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 25px;
  height: 50px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

export const WizardTitle = styled.div`
  display: flex;
  height: 60px;
  font-size: 30px;
  text-transform: capitalize;
`;
