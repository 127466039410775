const transformName = (name) => {
    if (name) {
      if (name === 'contactinfo') {
        return 'Contact Information';
      }
      return name.replace(/[`~!@#$%^&*()_|+\-=?;:'",/.<>]/gi, ' ');
    }
    return '';
  };



export default transformName;