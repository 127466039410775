const parseBool = (parseThis) => {
    if (typeof parseThis === 'boolean') {
        return parseThis
    } else {
        switch (parseThis.toUpperCase()) {
            case "NO":
                return false;
            case "YES":
                return true;
            case "FALSE":
                return false;
            case "TRUE":
                return true;
            default:
                return parseThis;
        }
    }
};

export default parseBool;