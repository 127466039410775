import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  ANSWER_TYPES,
  QUESTION_TYPES,
  QUESTION_TYPE_METADATA
} from '../../../constants';
import getAllIndexes from '../../../helpers/getAllIndexes';

export const answerValidator = (answer) => {
  const {
    LABEL,
    MULTIPLECHOICE,
    SINGLECHOICE,
    SINGLESELECT,
    TABLE,
    GROUP
  } = QUESTION_TYPES;
  const {
    type,
    table,
    groupSections,
    answer: { value, options }
  } = answer;

  switch (type) {
    case MULTIPLECHOICE:
      return !!options.length;
    case SINGLECHOICE:
    case SINGLESELECT:
      return !!options.filter((o) => o.value).length;
    case TABLE:
      const atLeastOneRow = !!table.rows && !!table.rows.length
      let rowsAreValid = true;

      if (atLeastOneRow) {
        table.rows.forEach((row) => {
          if (rowsAreValid) {
            rowsAreValid = row.values.some(val => !!val.value);
          }
        });
      }
      return atLeastOneRow && rowsAreValid;
    case GROUP:
      const areSectionsValid = groupSections.map(gS => {
        const questionsAreValid = gS.questions.map((gSQ) => {
          if (gSQ.hidden) {
            return gSQ.answer.value === ANSWER_TYPES.hidden;
          } else {
            return answerValidator(gSQ);
          }
        }).every((q) => q === true);

        return questionsAreValid;
      }).every((q) => q === true);

      return areSectionsValid;
    case LABEL:
      return true;
    default:
      return !!value;
  }
};

export const pullQuestionValue = (question) => {
  if (!!QUESTION_TYPE_METADATA[question?.type]?.isSelect) {
    const selectedOption = question.answer.options?.find(o => o.value)?.option;

    return selectedOption;
  } else {

    return question?.answer?.value;
  }
};

export const applyDefaultFromValue = (toQuestion, fromQuestion, overrideValue) => {
  let value = overrideValue;
  
  if (!value) value = !!fromQuestion ? pullQuestionValue(fromQuestion) : null;

  switch (toQuestion.type) {
    case QUESTION_TYPES.SINGLECHOICE:
    case QUESTION_TYPES.SINGLESELECT:
      if (!toQuestion.answer.options?.find((o) => o.value)) {
        const option = toQuestion.answer?.options?.find((o) => o.option === value);

        if (!!option) option.value = true;
      }
      break;
    default:
      toQuestion.answer.value = toQuestion.answer.value || value;
      break;
  }
};

export const gateKeeper = (questions = []) => {
  const {
    TABLE,
    SINGLECHOICE,
    SINGLESELECT,
    PRIORITYLEVEL,
    YESNO
  } = QUESTION_TYPES;
  const gateIndexes = getAllIndexes(questions, (question) => question.isGate);
  let updatedQuestions = [];

  if (!gateIndexes.length) {
    updatedQuestions = questions.map(question => ({ hidden: false, ...question }));
  } else {
    if (gateIndexes[0] !== 0) {
      updatedQuestions = updatedQuestions.concat(_.cloneDeep(questions).splice(0, gateIndexes[0]));
    }

    gateIndexes.forEach((i, index) => {
      const gate = questions[i];
      const isLastGate = index + 1 === gateIndexes.length;

      const questionsBetweenStartIndex = i + 1;
      const questionsBetweenEndIndex = isLastGate ? questions.length : gateIndexes[index + 1];
      const questionsBetween = _.cloneDeep(questions).slice(questionsBetweenStartIndex, questionsBetweenEndIndex);

      let hideQuestions = true;
      let updatedQuestionsBetween = questionsBetween;

      switch (gate.type) {
        case TABLE:
          hideQuestions = false;
          break;
        case SINGLECHOICE:
        case SINGLESELECT:
          const selectedOption = gate.answer.options.find((o) => o.value === true);

          if (selectedOption) hideQuestions = gate.key !== selectedOption.option;
          break;
        case PRIORITYLEVEL:
          const selectedPriority = gate.answer.value;
          hideQuestions = selectedPriority !== gate.key;
          break;
        case YESNO:
          const value = gate.answer.value;
          const key = gate.key;
          const valueToBool = value === 'Yes' ? true : false;
          const keyToBool = key === 'true' ? true : false;

          hideQuestions = valueToBool !== keyToBool || value === null || value === 'N/A';
          break;
        default:
          console.log('This gate type is not being used');
          break;
      }

      if (hideQuestions) {
        updatedQuestionsBetween = questionsBetween.map((question) => ({
          ...question,
          hidden: question?.ignoreGate === true ? false : true,
          answer: {
            ...question.answer,
            value: question?.ignoreGate === true ? question.answer.value : ANSWER_TYPES.hidden
          }
        }))
      } else {
        updatedQuestionsBetween = questionsBetween.map((question) => ({
          ...question,
          hidden: false,
          answer: {
            ...question.answer,
            value: question.answer.value === ANSWER_TYPES.hidden ? null : question.answer.value
          }
        }))
      }

      updatedQuestions.push(gate);
      updatedQuestions = updatedQuestions.concat(updatedQuestionsBetween);
    });
  }

  return updatedQuestions;
};

const cleanGroupQuestions = (questions, groupFromData, sectionHeading = null) => {
  const {
    MULTIPLECHOICE,
    SINGLECHOICE,
    SINGLESELECT,
    TABLE
  } = QUESTION_TYPES;

  const cleanQuestions = questions.map((question) => {
    const newQuestion = _.cloneDeep(question);
    const {
      answer: { options },
    } = newQuestion;

    newQuestion.answer.id = uuidv4();

    switch (question.type) {
      case MULTIPLECHOICE:
      case SINGLECHOICE:
      case SINGLESELECT:
        newQuestion.answer.options = !!options ? options.map((o) => ({ option: o.option, value: false })) : [];
        break;
      case TABLE:
        newQuestion.table.rows = null;
        break;
      default:
        newQuestion.answer.value = null;
        break;
    }

    return newQuestion;
  });

  let templateKeys = !!groupFromData?.length ? groupFromData.reduce(templateKeyReduce, []) : null;

  if (templateKeys) {
    const templateValues = [];
    cleanQuestions.forEach(question => {
      QUESTION_TYPE_METADATA.group.questionTemplateKeys.forEach(qTK => {
        if (!!question[qTK]) templateValues.push(question[qTK]);
      });
    });

    const usedKeys = [];
    const keyRegex = /\[\d\]/gi; // [anynumber] don't stop at first match and case insensitve
    templateValues.forEach(tV => {
      const matchingKeys = tV.match(keyRegex);

      if (!!matchingKeys) {
        matchingKeys.forEach(mK => {
          if (!usedKeys.includes(mK)) usedKeys.push(mK);
        });
      }
    });
    if (sectionHeading) {
      const matchingKeys = sectionHeading.match(keyRegex);
      if (!!matchingKeys) {
        matchingKeys.forEach(mK => {
          if (!usedKeys.includes(mK)) usedKeys.push(mK);
        });
      }
    }

    templateKeys = templateKeys.filter(tK => usedKeys.includes(tK.key));
  }

  return { templateKeys, questions: gateKeeper(cleanQuestions) };
};

export const fillStringTemplate = (string, keyValues) => {
  if (!keyValues?.length) {
    return string;
  } else {
    const regexValues = keyValues.map(keyValue => {
      return {
        regex: new RegExp(`\\[${keyValue.key}\\]`, 'g'),
        value: keyValue.value
      };
    });

    let filledString = string;
    regexValues.forEach(rReg => {
      if (!!rReg.value) {
        filledString = filledString.replace(rReg.regex, rReg.value);
      }
    });

    return filledString;
  }
};

const fillSectionTemplate = (section, keyValues, sectionHeading = '') => {
  const newSection = {
    id: section.id,
    templateData: {
      sectionHeading: fillStringTemplate(sectionHeading, keyValues),
    },
    questions: gateKeeper(section.questions)
  };

  newSection.questions = newSection.questions.map((q) => {
    return {
      ...q,
      templateData: {
        description: fillStringTemplate(q.description, keyValues),
        question: fillStringTemplate(q.question, keyValues)
      }
    }
  });

  return newSection;
};

const templateKeyReduce = (currentKeys, dataItem, index) => {
  let newKeys = [...currentKeys];
  const keyIndex = newKeys.length + 1;

  switch (dataItem?.type) {
    case QUESTION_TYPES.MULTIPLECHOICE:
    case QUESTION_TYPES.SINGLECHOICE:
    case QUESTION_TYPES.SINGLESELECT:
      newKeys.push({ label: dataItem.meta.question, key: `[${keyIndex}]`, dataItemIndex: index });
      break;
    case QUESTION_TYPES.GROUP:
      if (!!dataItem.data.length) {
        const validQuestionTypes = dataItem.data[0].questions.filter((que) => QUESTION_TYPE_METADATA.group.allowedTemplateFillers.includes(que.type));
        newKeys = newKeys.concat(validQuestionTypes.map((que, queIndex) => ({ label: que.question, key: `[${keyIndex + queIndex}]`, dataItemIndex: index })));
      }
      break;
    case QUESTION_TYPES.TABLE:
      newKeys = newKeys.concat(dataItem.data.columns.map((col, colIndex) => ({ label: col.name, key: `[${keyIndex + colIndex}]`, dataItemIndex: index })));
      break;
    default:
      break;
  }

  return newKeys;
};

// TODO: Update this to handle an array of coordinates
const mapSection = ({
  sectionIndex,
  section,
  values,
  allData,
  sectionHeading = null
}) => {
  const templateKeys = allData.reduce(templateKeyReduce, []);

  const mainKeys = templateKeys.filter((tK) => tK.dataItemIndex === 0);
  const keyValues = values.map((value, index) => ({ key: mainKeys[index].key, value }));

  const otherData = allData[section.coordinates.otherDataItemIndex];
  const otherDataKeys = templateKeys.filter((tK) => tK.dataItemIndex !== 0);

  switch (otherData?.type) {
    case QUESTION_TYPES.GROUP:
      const validTemplateQuestions = otherData.data[section.coordinates.otherDataItemInternalDataIndex]?.questions.filter(question =>
        QUESTION_TYPE_METADATA.group.allowedTemplateFillers.includes(question.type)
      );
      const simpleQuestionValues = validTemplateQuestions.map(pullQuestionValue);

      otherDataKeys.forEach((oDK, index) => keyValues.push({ key: oDK.key, value: simpleQuestionValues[index] }));
      break;
    case QUESTION_TYPES.TABLE:
      const rowValues = otherData.data.rows[section.coordinates.otherDataItemInternalDataIndex]?.values.map((val) => val.value);

      otherDataKeys.forEach((oDK, index) => keyValues.push({ key: oDK.key, value: rowValues[index] }));
      break;
    case QUESTION_TYPES.MULTIPLECHOICE:
    case QUESTION_TYPES.SINGLECHOICE:
    case QUESTION_TYPES.SINGLESELECT:
      const selectedOption = otherData.data[section.coordinates.otherDataItemInternalDataIndex];

      keyValues.push({ key: otherDataKeys[0].key, value: selectedOption.option });
      break;
    default:
      console.log(`We don't handle the type ${otherData?.type} in the map section`);
      break;
  }

  section.questions.map((question) => {
    if (!!question?.groupFromData) {
      const fromSection = question.groupFromData.data[sectionIndex];

      if (!!fromSection) {
        const fromQuestion = fromSection.questions.find((innerQuestion) => innerQuestion.questionId === question.groupFromData.innerQuestionId);

        if (!!fromQuestion) applyDefaultFromValue(question, fromQuestion);
      }
    }

    return question;
  });

  return fillSectionTemplate(section, keyValues, sectionHeading);
};

// TODO: Update this to generate sections from multiple other items
// TODO: coordinates should become an array of coordinates indicating where it pulls the various template values from the multiple other item sources
const constructSectionsFrom = (values, otherItems, baseGroupQuestion, sections = []) => {
  const newSections = [];

  values.forEach((v, valueIndex) => {
    if (!!otherItems?.length) {
      otherItems.forEach((oDI, oDIIndex) => {
        switch (oDI?.type) {
          case QUESTION_TYPES.GROUP:
            oDI.data.forEach((d, dIndex) => {
              newSections.push({
                id: sections[valueIndex + oDIIndex + dIndex]?.id || uuidv4(),
                coordinates: {
                  mainDataItemIndex: 0,
                  mainDataItemAnswerIndex: valueIndex,
                  otherDataItemIndex: oDIIndex + 1,
                  otherDataItemInternalDataIndex: dIndex
                },
                questions: sections[valueIndex + oDIIndex + dIndex]?.questions || _.cloneDeep(baseGroupQuestion)
              })
            });
            break;
          case QUESTION_TYPES.MULTIPLECHOICE:
          case QUESTION_TYPES.SINGLECHOICE:
          case QUESTION_TYPES.SINGLESELECT:
            const selectedOptions = oDI.data.filter((cV) => cV.value);

            if (!!selectedOptions.length) {
              selectedOptions.forEach((d, dIndex) => {
                newSections.push({
                  id: sections[valueIndex + oDIIndex + dIndex]?.id || uuidv4(),
                  coordinates: {
                    mainDataItemIndex: 0,
                    mainDataItemAnswerIndex: valueIndex,
                    otherDataItemIndex: oDIIndex + 1,
                    otherDataItemInternalDataIndex: dIndex
                  },
                  questions: sections[valueIndex + oDIIndex + dIndex]?.questions || _.cloneDeep(baseGroupQuestion)
                })
              });
            } else {
              newSections.push({
                id: sections[valueIndex]?.id || uuidv4(),
                coordinates: {
                  mainDataItemIndex: 0,
                  mainDataItemAnswerIndex: valueIndex,
                  otherDataItemIndex: null,
                  otherDataItemInternalDataIndex: null
                },
                questions: sections[valueIndex]?.questions || _.cloneDeep(baseGroupQuestion)
              });
            }
            break;
          case QUESTION_TYPES.TABLE:
            if (!!oDI.data?.rows) {
              oDI.data.rows.forEach((r, rIndex) => {
                newSections.push({
                  id: sections[valueIndex + oDIIndex + rIndex]?.id || uuidv4(),
                  coordinates: {
                    mainDataItemIndex: 0,
                    mainDataItemAnswerIndex: valueIndex,
                    otherDataItemIndex: oDIIndex + 1,
                    otherDataItemInternalDataIndex: rIndex
                  },
                  questions: sections[valueIndex + oDIIndex + rIndex]?.questions || _.cloneDeep(baseGroupQuestion)
                })
              });
            } else {
              newSections.push({
                id: sections[valueIndex]?.id || uuidv4(),
                coordinates: {
                  mainDataItemIndex: 0,
                  mainDataItemAnswerIndex: valueIndex,
                  otherDataItemIndex: null,
                  otherDataItemInternalDataIndex: null
                },
                questions: sections[valueIndex]?.questions || _.cloneDeep(baseGroupQuestion)
              });
            }
            break;
          default:
            break;
        }
      });
    } else {
      newSections.push({
        id: sections[valueIndex]?.id || uuidv4(),
        coordinates: {
          mainDataItemIndex: 0,
          mainDataItemAnswerIndex: valueIndex,
          otherDataItemIndex: null,
          otherDataItemInternalDataIndex: null
        },
        questions: sections[valueIndex]?.questions || _.cloneDeep(baseGroupQuestion)
      });
    }
  });

  return newSections;
};

const constructGroupSectionWithGateKeeper = (gS, sectionHeading = null) => {
  const result =
  {
    ...gS,
    questions: gateKeeper(gS.questions)
  };
  if (sectionHeading && !result.templateData?.sectionHeading) {
    result.templateData = {
      ...result.templateData,
      sectionHeading
    };
  }
  return result;
};

const totalLengthReducer = (accumulator, currentValue, multiplier) => {
  let newValue = accumulator;

  switch (currentValue?.type) {
    case QUESTION_TYPES.GROUP:
      newValue = accumulator + (currentValue.data.length * multiplier);
      break;
    case QUESTION_TYPES.MULTIPLECHOICE:
    case QUESTION_TYPES.SINGLECHOICE:
    case QUESTION_TYPES.SINGLESELECT:
      newValue = accumulator + (currentValue.data.filter((cV) => cV.value).length * multiplier);
      break;
    case QUESTION_TYPES.TABLE:
      newValue = accumulator + ((currentValue.data?.rows?.length || 0) * multiplier);
      break;
    default:
      break;
  }

  return newValue;
};

const constructGroupSectionsFromTable = (invokeData) => {
  const {
    groupSections,
    firstDataItem,
    otherDataItems,
    sectionHeading
  } = invokeData;
  const table = firstDataItem?.data;
  const totalLength = !otherDataItems?.length 
    ? table?.rows?.length
    : otherDataItems.reduce((accumulator, currentValue) => totalLengthReducer(accumulator, currentValue, table?.rows?.length || 0), 0);
  const baseGroupQuestion = (cleanGroupQuestions(groupSections[0].questions)).questions;

  let mappedSections = [];

  if (groupSections.length < totalLength) {
    const newSections = constructSectionsFrom(table?.rows || [1], otherDataItems, baseGroupQuestion);

    mappedSections = newSections.map((newSection, sectionIndex) => {
      const row = table?.rows[newSection.coordinates.mainDataItemAnswerIndex];

      if (!!row) {
        const rowValues = row?.values.map((val) => val.value);

        return mapSection({
          sectionIndex,
          section: newSection, 
          values: rowValues, 
          allData: [firstDataItem, ...otherDataItems], 
          sectionHeading
        });
      } else {
        return newSection;
      }
    });
  } else {
    const userGeneratedCount = groupSections.length - totalLength;
    const generatedEndIndex = groupSections.length - userGeneratedCount;

    const userCreatedSections = _.cloneDeep(groupSections).splice(generatedEndIndex, userGeneratedCount);
    const generatedSections = _.cloneDeep(groupSections).splice(0, generatedEndIndex);
    const regeneratedSections = constructSectionsFrom(table?.rows || [1], otherDataItems, baseGroupQuestion, generatedSections);

    const coordinatedSections = _.cloneDeep(regeneratedSections.concat(userCreatedSections));

    mappedSections = coordinatedSections.map((newSection, sectionIndex) => {
      if (newSection?.coordinates) {
        const row = table?.rows[newSection.coordinates.mainDataItemAnswerIndex];

        if (!!row) {
          const rowValues = row?.values.map((val) => val.value);

          return mapSection({
            sectionIndex,
            section: newSection, 
            values: rowValues, 
            allData: [firstDataItem, ...otherDataItems], 
            sectionHeading
          });
        } else {
          return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
        }
      } else {
        return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
      }
    });
  }

  return _.cloneDeep(mappedSections);
};

const constructGroupSectionsFromGroup = (invokeData) => {
  const {
    groupSections,
    firstDataItem,
    otherDataItems,
    sectionHeading
  } = invokeData;
  const sections = firstDataItem?.data;
  const totalLength = !otherDataItems?.length 
    ? sections.length 
    : otherDataItems.reduce((accumulator, currentValue) => totalLengthReducer(accumulator, currentValue, sections.length), 0);
  const baseGroupQuestion = (cleanGroupQuestions(groupSections[0].questions)).questions;

  let mappedSections = [];

  if (groupSections.length < totalLength) {
    const newSections = constructSectionsFrom(sections, otherDataItems, baseGroupQuestion);

    mappedSections = newSections.map((newSection, sectionIndex) => {
      const section = sections[newSection.coordinates.mainDataItemAnswerIndex];
      const validTemplateQuestions = section?.questions.filter(question =>
        QUESTION_TYPE_METADATA.group.allowedTemplateFillers.includes(question.type)
      );
      const simpleQuestionValues = validTemplateQuestions.map(pullQuestionValue);

      if (!!section) {
        return mapSection({
          sectionIndex,
          section: newSection, 
          values: simpleQuestionValues, 
          allData: [firstDataItem, ...otherDataItems], 
          sectionHeading
        });
      } else {
        return newSection;
      }
    });
  } else {
    const userGeneratedCount = groupSections.length - totalLength;
    const generatedEndIndex = groupSections.length - userGeneratedCount;
    const userCreatedSections = _.cloneDeep(groupSections).splice(generatedEndIndex, userGeneratedCount);
    const generatedSections = _.cloneDeep(groupSections).splice(0, generatedEndIndex);
    const regeneratedSections = constructSectionsFrom(sections, otherDataItems, baseGroupQuestion, generatedSections);

    const coordinatedSections = _.cloneDeep(regeneratedSections.concat(userCreatedSections));

    mappedSections = coordinatedSections.map((newSection, sectionIndex) => {
      if (newSection?.coordinates) {
        const section = sections[newSection.coordinates.mainDataItemAnswerIndex];
        const validTemplateQuestions = section?.questions.filter(question =>
          QUESTION_TYPE_METADATA.group.allowedTemplateFillers.includes(question.type)
        );
        const simpleQuestionValues = validTemplateQuestions.map(pullQuestionValue);

        if (!!section) {
          return mapSection({
            sectionIndex,
            section: newSection, 
            values: simpleQuestionValues, 
            allData: [firstDataItem, ...otherDataItems], 
            sectionHeading
          });
        } else {
          return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
        }
      } else {
        return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
      }
    });
  }

  return _.cloneDeep(mappedSections);
};

const constructGroupSectionsFromOptions = (invokeData) => {
  const {
    groupSections,
    firstDataItem,
    otherDataItems,
    sectionHeading
  } = invokeData;
  const options = firstDataItem?.data.filter(op => op.value);
  const totalLength = !otherDataItems?.length 
    ? options.length 
    : otherDataItems.reduce((accumulator, currentValue) => totalLengthReducer(accumulator, currentValue, options.length), 0);
  const baseGroupQuestion = (cleanGroupQuestions(groupSections[0].questions)).questions;

  let mappedSections = [];

  if (groupSections.length < totalLength) {
    const newSections = constructSectionsFrom(options, otherDataItems, baseGroupQuestion);

    mappedSections = newSections.map((newSection, sectionIndex) => {
      const option = options[newSection.coordinates.mainDataItemAnswerIndex];

      if (!!option) {
        return mapSection({
          sectionIndex,
          section: newSection, 
          values: [option.option], 
          allData: [firstDataItem, ...otherDataItems], 
          sectionHeading
        });
      } else {
        return newSection;
      }
    });
  } else {
    const userGeneratedCount = groupSections.length - totalLength;
    const generatedEndIndex = groupSections.length - userGeneratedCount;

    const userCreatedSections = _.cloneDeep(groupSections).splice(generatedEndIndex, userGeneratedCount);
    const generatedSections = _.cloneDeep(groupSections).splice(0, generatedEndIndex);
    const regeneratedSections = constructSectionsFrom(options, otherDataItems, baseGroupQuestion, generatedSections);

    const coordinatedSections = _.cloneDeep(regeneratedSections.concat(userCreatedSections));

    mappedSections = coordinatedSections.map((newSection, sectionIndex) => {
      if (newSection?.coordinates) {
        const option = options[newSection.coordinates.mainDataItemAnswerIndex];

        if (!!option) {
          return mapSection({
            sectionIndex,
            section: newSection, 
            values: [option.option], 
            allData: [firstDataItem, ...otherDataItems], 
            sectionHeading
          });
        } else {
          return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
        }
      } else {
        return constructGroupSectionWithGateKeeper(newSection, sectionHeading);
      }
    });
  }

  return _.cloneDeep(mappedSections);
};

const constructGroupSections = ({ groupSections = [], groupFromData, sectionHeading }) => {
  let newGroupSections = groupSections;
  if (!groupSections) {
    return null;
  } else if (!!groupFromData?.length) {
    const firstDataItem = groupFromData[0];
    const otherDataItems = _.cloneDeep(groupFromData).splice(1, groupFromData.length - 1);

    switch (firstDataItem?.type) {
      case QUESTION_TYPES.TABLE:
        if (!!firstDataItem?.data?.rows) {
          newGroupSections = constructGroupSectionsFromTable({ groupSections, firstDataItem, otherDataItems, sectionHeading });
        } else {
          newGroupSections = groupSections.map((gs) => constructGroupSectionWithGateKeeper(gs, sectionHeading));
        }
        break;
      case QUESTION_TYPES.GROUP:
        if (!!firstDataItem?.data.length) {
          newGroupSections = constructGroupSectionsFromGroup({ groupSections, firstDataItem, otherDataItems, sectionHeading });
        } else {
          newGroupSections = groupSections.map((gs) => constructGroupSectionWithGateKeeper(gs, sectionHeading));
        }
        break;
      case QUESTION_TYPES.MULTIPLECHOICE:
      case QUESTION_TYPES.SINGLESELECT:
      case QUESTION_TYPES.SINGLECHOICE:
        const selectedOptions = !!firstDataItem?.data.length && !!firstDataItem.data.find((op) => op.value);

        if (selectedOptions) {
          newGroupSections = constructGroupSectionsFromOptions({ groupSections, firstDataItem, otherDataItems, sectionHeading });
        } else {
          newGroupSections = groupSections.map((gs) => constructGroupSectionWithGateKeeper(gs, sectionHeading));
        }
        break;
      default:
        console.log('Construct from group sections doesn\'t handle that type');
        newGroupSections = groupSections.map((gs) => constructGroupSectionWithGateKeeper(gs, sectionHeading));
        break;
    }
  } else {
    newGroupSections = groupSections.map((gs) => constructGroupSectionWithGateKeeper(gs, sectionHeading));
  }

  return newGroupSections;
};

const filterGroupSections = (filter, sections) => {
  const filteredSections = sections.filter((section) => {
    const filterQuestion = section.questions.find((question) => question.questionId === filter.questionId);
    let isValidSection = true;

    switch (filterQuestion.type) {
      case QUESTION_TYPES.PRIORITYLEVEL:
      case QUESTION_TYPES.COMPLETIONDATE:
      case QUESTION_TYPES.YESNO:
        isValidSection = filterQuestion.answer.value === filter.value;
        break;
      case QUESTION_TYPES.SINGLECHOICE:
      case QUESTION_TYPES.SINGLESELECT:
        const selectedOption = filterQuestion.answer.options.find((o) => o.value === true);

        isValidSection = selectedOption?.option === filter.value;
        break;
      case QUESTION_TYPES.MULTIPLECHOICE:
        isValidSection = !!filterQuestion.answer.options.find((o) => o.value === true && o.option === filter.value);
        break;
      default:
        console.log('we don\'t handle that type');
        break;
    }

    return isValidSection;
  });

  return filteredSections;
};

export const constructQuestionAnswer = (questionAnswer, questionAnswers = []) => {
  let groupFromData = null;
  let groupSections = null;
  let groupQuestionsCopy = null;

  if (questionAnswer.type === QUESTION_TYPES.GROUP) {
    groupFromData = !questionAnswer?.groupFromData?.length ? null : questionAnswer.groupFromData.map((gFD, index) => {
      if (questionAnswers.length && gFD?.isLocal) {
        const question = questionAnswers.find((question) => question.questionId === questionAnswer.groupOptions.defaultsFrom[index].questionId);

        switch (question?.type) {
          case QUESTION_TYPES.TABLE:
            gFD.data = question.table;
            break;
          case QUESTION_TYPES.GROUP:
            gFD.data = question.groupSections;
            break;
          case QUESTION_TYPES.SINGLECHOICE:
          case QUESTION_TYPES.SINGLESELECT:
            gFD.data = question.answer.options;
            break;
          case QUESTION_TYPES.MULTIPLECHOICE:
            const allOptions = _.cloneDeep(question.answer.options);

            if (question.answer.otherOptions) {
              const otherOptions = question.answer.otherOptions.split('\n');

              otherOptions.forEach(o => allOptions.push({ option: o, value: true }));
            }

            gFD.data = allOptions;
            break;
          default:
            console.log('We don\'t handle that type in the constructQuestionAnswer');
            break;
        }

        return gFD;
      }

      return gFD;
    }).map((gFD) => {
      if (gFD?.type === QUESTION_TYPES.GROUP && gFD?.data && gFD?.filter) {
        gFD.data = filterGroupSections(gFD?.filter, gFD?.data);
      }

      return gFD;
    });

    groupSections = constructGroupSections({ groupSections: questionAnswer?.groupSections, groupFromData: groupFromData, sectionHeading: questionAnswer?.sectionHeading });
    groupQuestionsCopy = !!questionAnswer.groupSections ? cleanGroupQuestions(questionAnswer.groupSections[0].questions, groupFromData, questionAnswer?.sectionHeading) : null;
  }

  const result = {
    ...questionAnswer,
    id: questionAnswer.answer.id,
    categoryId: questionAnswer.answer.categoryId,
    questionId: questionAnswer.questionId,
    optionsFromPreviousAnswers: questionAnswer.optionsFromPreviousAnswers,
    optionsFrom: questionAnswer.optionsFrom,
    options: questionAnswer.options || [],
    optionsSpecifyOthers: questionAnswer.optionsSpecifyOthers,
    moreInfoLabel: questionAnswer.moreInfoLabel || '',
    additionalInfo: questionAnswer.additionalInfo || '',
    groupQuestionsCopy,
    groupFromData,
    groupSections,
    answer: {
      ...questionAnswer.answer,
      id: questionAnswer.answer.id || null,
      value: questionAnswer.answer.value || '',
      note: questionAnswer.answer.note || '',
    },
  };

  return result;
};

export const getOptionsFromGroup = (groupQuestion, innerQuestionId) => {

  const innerQuestions = groupQuestion.groupSections.map(section => section.questions.find((q) => q.questionId === innerQuestionId));
  const options = innerQuestions.filter((q) => q !== null).map((q => {
    if (q.type === QUESTION_TYPES.SINGLECHOICE) {
      const selectedOption = q.answer.options.find(o => o.value);

      return selectedOption ? selectedOption.option : null;
    } else {
      return q.answer.value;
    }
  }));
  return options;

};