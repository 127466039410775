import { QUESTION_TYPES, COLUMN_TYPES, ANSWER_TYPES } from '../../constants';
import constructInputLimitErrorMessage from '../../helpers/constructInputLimitErrorMessage';

export const optionsValidator = (options = []) => {
  if (options?.length > 1) {
    return options.every((o) => o.value !== '');
  }
  return false;
};

export const formsValidator = (forms = []) => {
  const checkedForms = forms?.map((form) => {
    let isValid = true;

    switch (form?.type) {
      case QUESTION_TYPES.MULTIPLECHOICE:
      case QUESTION_TYPES.SINGLECHOICE:
        isValid = !!form.question && optionsValidator(form.options);
        break;
      case QUESTION_TYPES.SINGLESELECT:
        if (form.optionsFromPreviousAnswers) {
          isValid = !!form.optionsFrom && 
                  (form.optionsFrom.wizardId && form.optionsFrom.wizardId !== ANSWER_TYPES.empty
                          && form.optionsFrom.categoryId && form.optionsFrom.categoryId !== ANSWER_TYPES.empty
                          && form.optionsFrom.questionId && form.optionsFrom.questionId !== ANSWER_TYPES.empty
                          && form.optionsFrom.innerQuestionId && form.optionsFrom.innerQuestionId !== ANSWER_TYPES.empty);
        } else {
          isValid = !!form.question  && optionsValidator(form.options);
        }
        break;
      case QUESTION_TYPES.TABLE:
        const validTable = !!form.table.columns.length && !(form.table.columns.find(column => !column.name))
                                  && !(form.table.columns.find(column => column.type === COLUMN_TYPES.calculated && !column.formula));

        isValid = !!form.question && validTable;
        break;
      case QUESTION_TYPES.GROUP:
        let validQuestions = !!form.question && !!form.questions.length;

        if (validQuestions && !!form.questions.find(q => q.type === QUESTION_TYPES.GROUP)) {
          console.log('You can\'t have a group within a group');
          isValid = false;
        }

        if (validQuestions) {
          validQuestions = formsValidator(form.questions);
        }

        isValid = validQuestions;
        break;
      default:
        isValid = !!form.question;
        break;
    }

    if (isValid && form?.isGate) {
      switch (form?.type) {
        case QUESTION_TYPES.TABLE:
          break;
        case QUESTION_TYPES.SINGLECHOICE:
        case QUESTION_TYPES.SINGLESELECT:
          isValid = !!form.options.includes(form.key);
          break;
        case QUESTION_TYPES.YESNO:
          isValid = !!form.key;
          break;
        default:
          console.log('We don\'t handle that gate type in the question validator');
          break;
      }
    }

    return isValid;
  });
  return checkedForms.every((f) => f === true);
};

export const reduceValueLength = (value, limit, setError) => {
  if (value.length > limit) {
    setError(constructInputLimitErrorMessage(limit));
  }
  return value.slice(0, limit);
}