import React, { useState } from 'react';
import {
    Menu,
    MenuItem
} from '@material-ui/core';
import { CustomButton } from '../Button';
import { ANSWER_TYPES } from '../../constants';

const TableMenuInput = ({
    inputs = [],
    showButton = true,
    buttonText,
    style,
    action = () => { }
}) => {
    const [anchorEl, setAnchor] = useState(null);

    const toggleMenu = (event) => setAnchor(!!anchorEl ? null : event.currentTarget);
    const hideMenu = () => setAnchor(null);
    return <div>
        {showButton && (
            <div style={style || { margin: '8px' }}>
                <CustomButton color='primary' onClick={!inputs.length ? action : toggleMenu}>
                    {buttonText}
                </CustomButton>
            </div>
        )}
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={hideMenu}
        >
            {inputs.map((input, index) => {
                return <MenuItem 
                key={index}
                onClick={() => {

                    hideMenu();
                    input.action();

                }}>
                    {input.label || ANSWER_TYPES.empty}
                </MenuItem>
            })}
        </Menu>
    </div>
}

export default TableMenuInput;