import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateMultipleCategoryAnswers
} from '../../../store/user/actions';

import { setCurrentCategoryData } from '../../../store/common/actions';
import { history } from '../../../store';
import PDFGenerator from '../../PDFGenerator';
// components
import {
  Container,
  ContentContainer,
  PaperStyled,
  UserCategoryItem,
  UserCategoryTitle,
  UserCategoryTitleContainer,
} from '../styled';
import { BreadcrumbsComponent } from '../../../components/Breadcrumbs';
import { NoCategoryPlaceholder } from '../Placeholder';
import { LinearProgressComponent as LinearProgress } from '../../../components/LinearProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomButton } from '../../../components/Button';
import { Print } from '@material-ui/icons';
import { WIZARD_TYPES, DIRECTORY_TYPES, INPUT_LIMITS, QUESTION_TYPES } from '../../../constants';
import { InlineCategory, CategoryTitle } from './styled';
import LastLocationLink from '../../../components/Links/LastLocationLink';
import { Typography } from '@material-ui/core';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';

export const UserCategories = () => {
  const dispatch = useDispatch();
  const lastLocation = useSelector((store) => store.User.lastLocation);
  const directory = useSelector((store) => store.Common.currentDirectoryName);
  const subDirectory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );
  const wizardId = useSelector((store) => {
    return directory ? store.User[directory][subDirectory].id : null;
  });
  const categories = useSelector((store) => wizardId ? store.User[directory][subDirectory]?.wizardAnswers?.categories : []);
  const categoryOutlines = useSelector((store) => wizardId ? store.User[directory][subDirectory]?.wizardAnswers?.categoryOutlines : []);
  const wizardType = useSelector((store) => {
    return wizardId ? store.User[directory][subDirectory]?.wizardAnswers?.type : WIZARD_TYPES.regular;
  });

  const pullWizardFromObject = (directoryObject, subDirectoryName, directoryName) => {
    const reducedData = {
      directory: directoryName,
      subDirectory: subDirectoryName,
      wizardId: directoryObject[subDirectoryName]?.id,
      wizardAnswers: directoryObject[subDirectoryName]?.wizardAnswers
    };

    return reducedData;
  }

  const wizards = useSelector((store) => {
    const {
      waste,
      drinking,
      contactinfo
    } = store.User;
    const {
      emergencyresponseplan,
      riskassessment,
      contactinfo: contactinfoWizType
    } = WIZARD_TYPES;
    const {
      waste: wasteType,
      drinking: drinkingType,
      contactinfo: contactinfoDirType
    } = DIRECTORY_TYPES;

    const simpleWizards = [
      pullWizardFromObject(waste, emergencyresponseplan, wasteType),
      pullWizardFromObject(waste, riskassessment, wasteType),
      pullWizardFromObject(drinking, emergencyresponseplan, drinkingType),
      pullWizardFromObject(drinking, riskassessment, drinkingType),
      pullWizardFromObject(contactinfo, contactinfoWizType, contactinfoDirType)
    ];

    return simpleWizards;
  });

  useEffect(() => {
    const unSetCurrentCategory = async () => {
      await dispatch(setCurrentCategoryData(null));
    };
    unSetCurrentCategory();
  }, [dispatch]);

  useEffect(() => {
    if (!wizardId) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directory]);

  if (!directory) {
    return <></>;
  }

  return (
    <UserCategoriesComponent
      lastLocation={lastLocation}
      directory={directory}
      subDirectory={subDirectory}
      categories={categories || []}
      categoryOutlines={categoryOutlines}
      wizardId={wizardId}
      wizardType={wizardType}
      wizards={wizards}
    />
  );
};

export const UserCategoriesComponent = ({
  lastLocation,
  directory,
  subDirectory,
  wizardId,
  categories,
  categoryOutlines,
  wizardType,
  wizards
}) => {
  const dispatch = useDispatch();

  const contactinfo = useSelector((store) => store.User.contactinfo.contactinfo?.wizardAnswers);

  const [pdfLoading, setPDFLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {

      setPDFReady(true);
      setPDFDataReady(false);
    };
    if (pdfLoading) {
      getData();
    }
  }, [pdfLoading]);

  const [pdfReady, setPDFReady] = useState(false);
  useEffect(() => {
    const sendData = async () => {
      setPDFReady(false);
      setPDFDataReady(true);
    };
    if (pdfReady) {
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfReady]);

  const [pdfDataReady, setPDFDataReady] = useState(false);
  useEffect(() => {
    if (pdfDataReady) {

      PDFGenerator({
        categories,
        contactinfo,
        directory,
        subDirectory,
        wizards
      });
      setPDFLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDataReady]);

  const onSetCurrentCategoryData = async (data) => {
    await dispatch(setCurrentCategoryData(data));
    history.push('/answers');
  };

  return (
    <Container>
      <PaperStyled elevation={3}>
        <BreadcrumbsComponent />
        <ContentContainer>
          <LastLocationLink visable={lastLocation?.wizardId === wizardId} onClick={onSetCurrentCategoryData} lastLocation={lastLocation} />
          {!categoryOutlines?.length && <NoCategoryPlaceholder />}
          {!!categoryOutlines?.length && wizardType === WIZARD_TYPES.regular && (
            <InfiniteScroll dataLength={categoryOutlines.length}>
              {categoryOutlines.map((c, index) =>
                <Category
                  key={c.categoryId}
                  id={c.categoryId}
                  wizardId={wizardId}
                  directory={directory}
                  subDirectory={subDirectory}
                  name={c.categoryName}
                  whole={c.numberOfQuestions}
                  filled={c.numberOfCompletedQuestions}
                  onSetCurrentCategoryData={onSetCurrentCategoryData}
                />
              )}
            </InfiniteScroll>
          )}
          {!!categories?.length && wizardType === WIZARD_TYPES.contactinfo && (
            <InfiniteScroll dataLength={categories.length}>
              <ContactInfoAnswersComponent
                directory={directory}
                subDirectory={subDirectory}
                wizardId={wizardId}
                rawCategories={categories}
                key={'contactinfo' + directory.id}>
              </ContactInfoAnswersComponent>
            </InfiniteScroll>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: 60,
              alignItems: 'flex-end',
            }}
          >
            <CustomButton
              startIcon={<Print style={{ color: '#ffffff' }} />}
              disabled={!categories?.length}
              onClick={() => {
                setPDFLoading(true);
              }}
              color={'default'}
            >
              {`download report ${pdfLoading ? 'loading ...' : ''}`}
            </CustomButton>
          </div>
        </ContentContainer>
      </PaperStyled>
    </Container>
  );
};

const ContactInfoAnswersComponent = ({
  rawCategories,
  wizardId
}) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const formattedCategories = rawCategories.map((item) => {
      return {
        categoryId: item.categoryId,
        categoryName: item.categoryName,
        questionAnswers: item.questions ? item.questions.map((q) => {
          return {
            questionId: q.answer.questionId,
            question: q.question,
            description: q.description,
            type: q.type,
            orderIndex: q.orderIndex,
            defaultFrom: q.defaultFrom,
            answer: {
              id: q.answer.id,
              categoryId: item.categoryId,
              questionId: q.answer.questionId,
              userId: q.answer.userId,
              value: q.answer.value || '',
              note: q.answer.note || ''
            }
          }
        }) : []
      };
    });
    setCategories(formattedCategories);
  }, [rawCategories]);

  const onEdit = ({ answerValue,
    question_id,
    category_id }) => {
    if (submitted) {
      setSubmitted(false);
    }
    const updatedCategories = [...categories];
    const categoryIndex = updatedCategories.findIndex((c) => c.categoryId === category_id);
    const category = updatedCategories[categoryIndex];
    var questionIndex = category.questionAnswers.findIndex((q) => q.answer.questionId === question_id);
    category.questionAnswers = category.questionAnswers.map((q, i) =>
      i === questionIndex ? {
        ...q,
        answer: { ...q.answer, value: answerValue }
      } : q
    );
    setCategories([...updatedCategories]);

  };

  const onSave = async () => {
    if (!submitted) {
      setSubmitted(true);

      const updatedCategories = categories.map((c) => {
        return {
          categoryId: c.categoryId,
          categoryName: c.categoryName,
          questions: c.questionAnswers.map((qa) => {
            return {
              questionId: qa.answer.questionId,
              type: qa.type,
              question: qa.question,
              description: qa.description,
              defaultFrom: qa.defaultFrom,
              lastLocation: null,
              answer: {
                id: qa.answer.id,
                categoryId: qa.answer.categoryId,
                questionId: qa.answer.questionId,
                value: qa.answer.value,
                note: qa.answer.note,
                userId: qa.answer.userId
              }
            }
          })

        }
      });

      await dispatch(
        updateMultipleCategoryAnswers({
          directory: DIRECTORY_TYPES.contactinfo,
          sub_directory: WIZARD_TYPES.contactinfo,
          categories: updatedCategories,
          wizard_id: wizardId
        })
      );

      setSubmitted(false);
      history.push('/home');
    }
  };
  return <>{categories.map((c, i) =>
    <AnswerComponent key={'answer' + i + c.categoryName} title={c.categoryName}
      category_id={c.categoryId}
      questions={c.questionAnswers}
      onEdit={onEdit}></AnswerComponent>
  )}

    <CustomButton
      onClick={onSave}
      size={'large'}
      style={{ margin: '5px 0' }}
      variant={'contained'}
      color={'primary'}
    >
      Save and close
    </CustomButton>

  </>;
};

const AnswerComponent = ({
  title,
  category_id,
  questions,
  onEdit
}) => {
  const [answerOverLimit, setAnswerOverLimit] = useState(null);

  const onAnswerEdit = ({
    answer,
    question_id,
    category_id
  }) => {
    const {
      MAX_SYMBOLS_LOWER_LIMIT
    } = INPUT_LIMITS;
    setAnswerOverLimit(null);
    const reduceCategoryNameLength = () => {
      if (answer.length > MAX_SYMBOLS_LOWER_LIMIT) {
        setAnswerOverLimit({
          id: question_id,
          error: constructInputLimitErrorMessage(MAX_SYMBOLS_LOWER_LIMIT),
        });
      }
      return answer.slice(0, MAX_SYMBOLS_LOWER_LIMIT);
    };

    onEdit({
      answerValue: reduceCategoryNameLength(),
      question_id,
      category_id
    });
  };

  return (
    <InlineCategory>
      <CategoryTitle> {title} </CategoryTitle>
      <div>
        {questions ?
          questions.map((q, i) => {
            return (
              <form noValidate autoComplete="off" key={q.answer.questionId}>
                {q.type === QUESTION_TYPES.LABEL &&
                  <Typography variant="caption">{q.question}</Typography>
                }
                {q.type === QUESTION_TYPES.SIMPLETEXT &&
                  <TextField InputLabelProps={{
                    shrink: true,
                  }} margin="normal" label={q.question} value={q.answer.value || ''} variant="outlined" fullWidth={true}
                    onChange={({ currentTarget: { value } }) => {
                      onAnswerEdit({
                        answer: value,
                        question_id: q.answer.questionId,
                        category_id
                      });
                    }}

                    error={
                      answerOverLimit && answerOverLimit.id === q.answer.questionId
                    }
                    helperText={
                      answerOverLimit && answerOverLimit.id === q.answer.questionId
                        ? constructInputLimitErrorMessage(INPUT_LIMITS.MAX_SYMBOLS_LOWER_LIMIT)
                        : ''
                    }
                  />
                }



              </form>
            );
          }) : <></>}
      </div>
    </InlineCategory>
  );
};

const Category = ({ id, name, whole, filled, onSetCurrentCategoryData }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((filled / whole) * 100);
  }, [whole, filled]);

  return (
    <UserCategoryItem>
      <UserCategoryTitleContainer>
        <UserCategoryTitle>
          {name}
        </UserCategoryTitle>
      </UserCategoryTitleContainer>
      <LinearProgress
        additionalText={`${filled} of ${whole}`}
        progress={progress}
      />
      <CustomButton
        onClick={() => onSetCurrentCategoryData({ currentCategoryId: id, currentCategoryName: name })}
        color={whole > 0 ? 'primary' : 'secondary'}
        disabled={whole < 1}
      >
        edit
      </CustomButton>
    </UserCategoryItem>
  );
};
