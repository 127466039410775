import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { QUESTION_TYPES, QUESTION_TYPE_METADATA, WIZARD_TYPES} from '../../../constants';
import {
  Container,
  PaperStyled,
  ListStyled,
  FooterContainer,
  SaveButton,
  AddFormButton,
  Placeholder,
  PlaceholderContainer,
} from '../styled';
import { QuestionsList } from '../QuestionsList';
import { PlaylistAdd } from '@material-ui/icons';
import { formsValidator } from '../helpers';
import { updateCategoryQuestions } from '../../../store/admin/actions';
import { BreadcrumbsComponent } from '../../../components/Breadcrumbs';
import SelectMenu from '../../../components/Button/SelectMenu';
import { withRouter } from 'react-router';
import gateChecker from '../../../helpers/gateChecker';

export const QuestionsComponent = withRouter(
  ({
    directory,
    sub_directory,
    categories,
    currentCategoryId,
    questions,
    wizardType,
    wizards,
    wizardId,
    history,
  }) => {
    const dispatch = useDispatch();
    const [forms, setGlobalForms] = useState(questions);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [submitted, setSubmitted] = useState(false);
    const {
      LABEL,
      MULTIPLECHOICE,
      SINGLECHOICE,
      SINGLESELECT,
      SIMPLETEXT,
      GROUP
    } = QUESTION_TYPES;
    const questionTypeStrings = Object.values(QUESTION_TYPES);
    const validQuestionTypes = wizardType === WIZARD_TYPES.contactinfo ? 
                                    questionTypeStrings.filter(QT => QT === SIMPLETEXT || QT === LABEL) : 
                                    questionTypeStrings.filter(QT => QT !== LABEL);

    useEffect(() => {
      const transformedQuestions = questions.map((q) => {
        if (q.type === MULTIPLECHOICE || q.type === SINGLECHOICE || q.type === SINGLESELECT) {
          return {
            ...q,
            options: q.options.map((o) => {
              return { id: uuidv4(), value: o };
            }),
          };
        } else if (q.type === GROUP) {
          return {
            ...q,
            questions: gateChecker(q.questions)
          };
        } else {
          return q;
        }
      });
      setGlobalForms(transformedQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    const [timer, setTimer] = useState(null);
    const submitHelper = () => {
      setSubmitted(true);
      if (timer !== null) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setSubmitted(false);
        }, 5000)
      );
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    // add new question
    const handleClose = async (type) => {
      setAnchorEl(null);
      if (type) {
        const unactiveForms = forms.map((f) => {
          f.active = false;
          return f;
        });
        setGlobalForms([
          ...unactiveForms,
          {
            id: uuidv4(),
            defaultFrom: null,
            active: true,
            type: type,
            tag: '',
            isOptional: false,
            sectionHeading: '',
            description: '',
            moreInfoLabel: '',
            waterSystemSourcePath: '',
            additionalInfo: '',
            additionalLink: null,
            question: QUESTION_TYPE_METADATA[type].defaultQuestion,
            optionsFromPreviousAnswers: false,
            optionsFrom: null,
            options: [],
            optionsSpecifyOthers: false,
            table: null,
            questions: null,
            groupOptions: null
          },
        ]);
      }
    };

    const onSortEnd = async (newState) => {
      setGlobalForms(newState);
    };
    
    const onDelete = async (id) => {
      const filteredQuestions = forms.filter((i) => i.id !== id);
      const categoryToUpdateIndex = categories.findIndex(
        (c) => c.id === currentCategoryId
      );
      await dispatch(
        updateCategoryQuestions({
          directory,
          sub_directory,
          data: {
            questions: filteredQuestions.map((f, i) => {
              let defaultFrom = null;

              if (!!f.defaultFrom) {
                defaultFrom = f.defaultFrom.questionId === id ? null : f.defaultFrom;
              };

              return {
                type: f.type,
                id: f.id,
                tag: f.tag,
                isOptional: f.isOptional,
                sectionHeading: f.sectionHeading,
                orderIndex: i,
                description: f.description,
                question: f.question,
                optionsFromPreviousAnswers: f.optionsFromPreviousAnswers,
                optionsFrom: f.optionsFrom,
                options: f.options ? f.options.map((option) => option.value) : [],
                optionsSpecifyOthers: f.optionsSpecifyOthers,
                moreInfoLabel: f.moreInfoLabel,
                additionalInfo: f.additionalInfo,
                additionalLink: f.additionalLink,
                defaultFrom,
                waterSystemSourcePath: f.waterSystemSourcePath,
                table: f.table,
                questions: f.questions,
                groupOptions: f.groupOptions
              };
            }),
            updatedCategory: categories[categoryToUpdateIndex],
            categoryId: currentCategoryId,
            categories,
          },
        })
      );
      setGlobalForms(filteredQuestions);
    };

    const setActive = async (id) => {
      const newItems = forms.map((item) => {
        return { ...item, active: item.id === id };
      });
      setGlobalForms([...newItems]);
    };

    const onSave = async () => {
      submitHelper();
      const valid = formsValidator(forms);
      if (!!forms.length && valid) {
        await dispatch(
          updateCategoryQuestions({
            directory,
            sub_directory,
            data: {
              questions: forms.map((f, i) => {
                return {
                  id: f.id,
                  tag: f.tag,
                  isOptional: f.isOptional,
                  sectionHeading: f.sectionHeading,
                  type: f.type,
                  orderIndex: i,
                  description: f.description,
                  question: f.question,
                  optionsFromPreviousAnswers: f.optionsFromPreviousAnswers,
                  optionsFrom: f.optionsFrom,
                  options: f.options ? f.options.map((option) => option.value) : [],
                  optionsSpecifyOthers: f.optionsSpecifyOthers,
                  moreInfoLabel: f.moreInfoLabel,
                  additionalInfo: f.additionalInfo,
                  additionalLink: f.additionalLink,
                  defaultFrom: f.defaultFrom,
                  waterSystemSourcePath: f.waterSystemSourcePath,
                  table: f.table,
                  questions: f.questions,
                  groupOptions: f.groupOptions
                };
              }),
              updatedCategory: categories.find(
                (c) => c.id === currentCategoryId
              ),
              categoryId: currentCategoryId,
              categories,
            },
          })
        );
        history.push('/categories');
      } else {
        console.log('data is invalid');
      }
    };

    const onEdit = async ({ id, tag, isOptional, sectionHeading, question, description, optionsFromPreviousAnswers, optionsFrom, options, optionsSpecifyOthers, table, questions, additionalInfo, additionalLink, moreInfoLabel, waterSystemSourcePath, defaultFrom, groupOptions }) => {
      let newQuestion = forms.find((q) => q.id === id);
      const index = forms.findIndex((q) => q.id === id);
      newQuestion = {
        ...newQuestion,
        tag,
        isOptional,
        sectionHeading,
        question,
        description,
        optionsFromPreviousAnswers,
        optionsFrom,
        options,
        optionsSpecifyOthers,
        defaultFrom,
        table,
        waterSystemSourcePath,
        moreInfoLabel,
        additionalInfo,
        additionalLink,
        questions,
        groupOptions
      };
      const newQuestions = [...forms];
      newQuestions[index] = newQuestion;
      setGlobalForms(newQuestions);
    };

    return (
      <Container>
        <PaperStyled elevation={3}>
          <BreadcrumbsComponent />
          <ListStyled component='div'>
            {forms?.length < 1 && <NoQuestionsPlaceholder />}
            <QuestionsList
              setGlobalForms={setGlobalForms}
              categoryId={currentCategoryId}
              wizardId={wizardId}
              wizards={wizards}
              forms={forms}
              onSortEnd={onSortEnd}
              onEdit={onEdit}
              setActive={setActive}
              onSave={onSave}
              onDelete={onDelete}
              submitted={submitted}
            />
          </ListStyled>
          <FooterContainer>
            <AddFormButton
              startIcon={<PlaylistAdd />}
              size='large'
              color='primary'
              variant='outlined'
              style={{ margin: 8 }}
              onClick={handleClick}
            >
              add question
            </AddFormButton>
            <SelectMenu 
              title='Select Question'
              showButton={false}
              externalAnchor={anchorEl}
              externalCloseAction={() => handleClose(null)}
              options={
                validQuestionTypes.map((vQT) => ({ icon: QUESTION_TYPE_METADATA[vQT].icon, label: QUESTION_TYPE_METADATA[vQT].label, action: () => handleClose(vQT) }))
              }
            />
            <SaveButton
              onClick={onSave}
              style={{ margin: 8 }}
              size='large'
              color={'success'}
              variant={'outlined'}
            >
              Save
            </SaveButton>
          </FooterContainer>
        </PaperStyled>
        <style>
          {`
            .infinite-scroll-component__outerdiv {
              width: 100%;
            }
          `}
        </style>
      </Container>
    );
  }
);

const NoQuestionsPlaceholder = () => {
  return (
    <PlaceholderContainer>
      <Placeholder>Please add your question</Placeholder>
    </PlaceholderContainer>
  );
};
