/* eslint-disable */
import React, { useState, useEffect } from 'react';
//import { useDispatch } from 'react-redux';
import { FormSwitcherContainer } from './styled';
import { CustomButton } from '../../components/Button';
import { OutlinedCard } from '../../components/Card';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { LockOutlined, PermIdentity, EmailOutlined } from '@material-ui/icons';
import { history } from '../../store';

export const AdminLoginForm = ({ data, onChange, onSubmit }) => {
  const [state, setState] = useState(data);

  return (
    <FormSwitcherContainer>
      <Grid container alignItems='flex-end' style={{ marginBottom: 10 }}>
        <Grid item>
          <EmailOutlined />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <TextField
            onChange={({ currentTarget }) => {
              setState({
                ...state,
                email: currentTarget.value,
              });
              onChange({
                ...state,
                email: currentTarget.value,
              });
            }}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                onSubmit(state);
              }
            }}
            id='standard-full-width'
            style={{ margin: 8, width: 'calc(100% - 8px)' }}
            placeholder='Email'
            margin='normal'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-end'>
        <Grid item>
          <LockOutlined />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <TextField
            onChange={({ currentTarget }) => {
              setState({
                ...state,
                password: currentTarget.value,
              });
              onChange({
                ...state,
                password: currentTarget.value,
              });
            }}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                onSubmit();
              }
            }}
            type={'password'}
            name='exampleRequired'
            style={{ margin: 8, width: 'calc(100% - 8px)' }}
            placeholder='Password'
            margin='normal'
            InputLabelProps={{
              shrink: false,
            }}
          />
        </Grid>
      </Grid>
    </FormSwitcherContainer>
  );
};

export const UserLoginForm = ({ code, onChange, onSubmit }) => {
  const [secretCode, setCode] = useState(code);

  useEffect(() => {
    onChange(secretCode);
  }, [secretCode, onChange]);

  return (
    <FormSwitcherContainer>
      <Grid container alignItems='flex-end'>
        <Grid item>
          <PermIdentity />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <TextField
            value={code}
            onChange={({ currentTarget }) => {
              setCode(currentTarget.value);
            }}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                onSubmit();
              }
            }}
            type='text'
            style={{ margin: 8, width: 'calc(100% - 8px)' }}
            placeholder='Secret code'
            margin='normal'
            InputLabelProps={{
              shrink: false,
            }}
          />
        </Grid>
      </Grid>
    </FormSwitcherContainer>
  );
};
