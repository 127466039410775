import { apiErrorsHandler } from '../helpers';
import * as pako from 'pako';

window.COMPRESS_BODY = true;

export const callApi = async ({
  endpoint,
  method,
  AuthToken = null,
  body = null,
  type,
}) => {
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  const headers = new Headers();
  if (AuthToken !== null && type === 'admin') {
    const bearer = `Bearer ${AuthToken}`;
    headers.set('Authorization', bearer);
  }

  const fetchConfig = {
    method,
    headers: headers,
  };

  if (body !== null) {
    fetchConfig.body = JSON.stringify(body);
    if (fetchConfig.body.length > 1024 && window.COMPRESS_BODY) {
      fetchConfig.body = pako.gzip(JSON.stringify(body));
      headers.set('Content-Encoding', 'gzip');
    }
  }

  const response = await fetch(endpoint, fetchConfig);
  if (response.status >= 300) {
    return apiErrorsHandler(response.status);
  }
  if (response.status !== 204) {
    const responseJson = await response.json();
    return responseJson;
  } else {
    return null;
  }
};
