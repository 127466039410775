import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import { CustomButton } from '../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const CardContainer = styled.div`
  display: flex;
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  width: 95%;
  margin: 30px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 6px;
  justify-content: center;
`;

export const FormsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px 4%;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 20px;
  padding: 70px 0;
`;

export const ListStyled = styled(List)`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 90%;
  overflow: auto;
  min-height: 80%;
`;

export const FooterContainer = styled.div`
  display: flex;
  height: 65px;
  width: 55%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const AddFormButton = styled(CustomButton)`
  margin: 5px;
`;

export const SaveButton = styled(CustomButton)`
  margin: 5px;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Placeholder = styled.div`
  display: flex;
  width: 65%;
  flex-direction: row;
  height: 150px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 50px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: #777;
  background: linear-gradient(60deg, #eee, #d6d3d3);
  border-radius: 5px;
  opacity: 0.7;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 1.5em;
`;
