import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import {
    Delete
} from '@material-ui/icons';

import BaseModal from '../../../../components/Modals/BaseModal';
import { CustomButton } from '../../../../components/Button';
import { QUESTION_TYPES } from '../../../../constants';

const useStyles = makeStyles({
    cardContainer: {
        marginBottom: '50px',
        marginTop: '25px'
    },
    cardHeaderContainer: {
        background: 'linear-gradient(60deg, rgb(5, 91, 187), rgb(16, 73, 167))',
        color: '#fff'
    },
    formControl: {
        width: "100%"
    },
    cellTopAlign: {
        verticalAlign: "top"
    },
    tableContainer: {
        overflowX: 'auto'
    }
});



const InitialSectionsModal = ({
    questions,
    initialSections,
    onChange
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [localInitialSections, setLocalInitialSections] = useState(initialSections || []);

    useEffect(() => {
        setLocalInitialSections(initialSections || []);
    }, [initialSections])

    const addInitialSection = () => {
        const newInitialSection = {
            answers: questions.map((q) => ({ answer: '', waterSystemSourcePath: '' }))
        }
        setLocalInitialSections([...localInitialSections, newInitialSection]);
    };


    const editInitialSection = (value, key, row, col) => {
        const newInitialSections = _.cloneDeep(localInitialSections);
        newInitialSections[row].answers[col][key] = value;
        setLocalInitialSections(newInitialSections);
    };

    const deleteInitialSection = (row) => {
        const newInitialSections = [...localInitialSections];
        newInitialSections.splice(row, 1);
        setLocalInitialSections(newInitialSections);
    }

    const onSave = () => {
        toggle();
        onChange(localInitialSections);
    };

    
    return (
        <BaseModal
            isOpen={open}
            toggle={toggle}
            paperStyles={{ width: '80%', height: '75%' }}
            buttonText={!!localInitialSections?.length ?
                `Change ${localInitialSections.length} initial sections`
                :
                "Set initial sections"
            }
        >
            <div>
                <h2>The user will be prompted to fill out the following sections:</h2>
                <div>
                    <CustomButton
                        onClick={addInitialSection}
                        size={'large'}
                        style={{ margin: '5px 0' }}
                        variant={'contained'}
                        color='primary'
                    >
                        Add a section
                    </CustomButton>
                </div>
            </div>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            {questions.map((q) =>
                                <TableCell key={q.id}>{q.question}</TableCell>
                            )}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localInitialSections.map((s, rowIndex) =>
                            <>
                                <TableRow key={`${rowIndex}:answer`}>
                                    <TableCell className={classes.cellTopAlign}>#{rowIndex + 1}</TableCell>
                                    {s.answers.map((a, colIndex) =>
                                    {
                                       const q = questions[colIndex];
                                       return !!q ? 
                                       <TableCell key={`${rowIndex}:${q.id}`}>
                                        {q.type === QUESTION_TYPES.SINGLESELECT && q.options && q.options.length > 0 ?
                                            <FormControl size="small" variant="outlined" className={classes.formControl}>
                                                <InputLabel>
                                                    Answer
                                                </InputLabel>
                                                <Select size="small"
                                                    value={a.answer}
                                                    onChange={(event) => editInitialSection(event.target.value, 'answer', rowIndex, colIndex)}
                                                    label="Answer"
                                                >
                                                    {q.options.map((o) =>
                                                        <MenuItem key={o} value={o}>{o}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> :
                                            <TextField
                                                value={a.answer || ''}
                                                variant="outlined"
                                                size="small"
                                                label="Answer"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={classes.formControl}
                                                onChange={({ currentTarget: { value } }) => editInitialSection(value, 'answer', rowIndex, colIndex)}
                                                style={{ marginBottom: 4 }}
                                                margin='dense'
                                            />
                                        }
                                        <br />
                                        <TextField
                                            value={a.waterSystemSourcePath || ''}
                                            variant="outlined"
                                            size="small"
                                            label="Water System Source Path"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={classes.formControl}
                                            onChange={({ currentTarget: { value } }) => editInitialSection(value, 'waterSystemSourcePath', rowIndex, colIndex)}
                                            style={{ marginBottom: 4 }}
                                            margin='dense'
                                        />
                                    </TableCell>
                                    : <TableCell key={`${rowIndex}:${colIndex}`}></TableCell>
                                    }
                                        
                                    )}
                                    <TableCell className={classes.cellTopAlign}>
                                        <IconButton aria-label="delete" onClick={() => deleteInitialSection(rowIndex)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>

            </TableContainer>
            <div style={{ display: 'flex' }}>
                <CustomButton
                    onClick={onSave}
                    style={{ margin: 8, marginTop: 12 }}
                    size='medium'
                    color={'success'}
                    variant={'outlined'}
                >Save</CustomButton>
            </div>
        </BaseModal>);
};

export default InitialSectionsModal;