import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Container, PaperStyled } from './styled';
import { AnswerForms } from './Forms';
import { BreadcrumbsComponent } from '../../components/Breadcrumbs';
import { history } from '../../store';
import {
  QUESTION_TYPES,
  WIZARD_TYPES,
  DIRECTORY_TYPES, ANSWER_TYPES
} from '../../constants';
import { applyDefaultFromValue, pullQuestionValue } from './Forms/helpers';

export const Answers = () => {
  const directory = useSelector((store) => store.Common.currentDirectoryName);
  const sub_directory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );

  const currentWizardId = useSelector((store) => {
    return directory ? store.User[directory][sub_directory].id : '';
  });
  const currentCategoryId = useSelector(
    (store) => store.Common.currentCategoryId
  );
  const currentCategoryName = useSelector((store) => store.Common.currentCategoryName);
  const currentQuestionId = useSelector((store) => store.Common.currentQuestionId);

  const pullWizardFromObject = (directoryObject, sub_directory_name) => {
    const reducedData = {
      wizardAnswers: directoryObject[sub_directory_name]?.wizardAnswers,
      wizardId: directoryObject[sub_directory_name]?.id
    };

    return reducedData;
  }
  const wizards = useSelector((store) => {
    const {
      waste,
      drinking,
      contactinfo
    } = store.User;
    const {
      emergencyresponseplan,
      riskassessment,
      contactinfo: contactinfoWizType
    } = WIZARD_TYPES;
    const {
      waste: wasteType,
      drinking: drinkingType,
      contactinfo: contactinfoDirType
    } = DIRECTORY_TYPES;

    const simpleWizards = [
      pullWizardFromObject(waste, emergencyresponseplan, wasteType),
      pullWizardFromObject(waste, riskassessment, wasteType),
      pullWizardFromObject(drinking, emergencyresponseplan, drinkingType),
      pullWizardFromObject(drinking, riskassessment, drinkingType),
      pullWizardFromObject(contactinfo, contactinfoWizType, contactinfoDirType)
    ];

    return simpleWizards;
  });

  const questions = useSelector((store) => {
    return currentCategoryId && store.User[directory][sub_directory].wizardAnswers.categories.find(
      (c) => c.categoryId === currentCategoryId
    )
      ? store.User[directory][sub_directory].wizardAnswers.categories.find(
        (c) => c.categoryId === currentCategoryId
      ).questions
      : [];
  });

  const localCategories = useSelector((store) => directory && sub_directory ? store.User[directory][sub_directory].wizardAnswers.categories : []);
    
  const pullValueFromCategory = (categories, categoryId, questionId, innerQuestionId, { pullForGroup } = { pullForGroup: false }) => {
    const category = categories.find(c => c.categoryId === categoryId);
    const question = category?.questions?.find(q => q.questionId === questionId);
    
    if (!question) {
      return null;
    }

    if (pullForGroup) {
      switch (question.type) {
        case QUESTION_TYPES.TABLE:
          return { type: question.type, data: question.table };
        case QUESTION_TYPES.GROUP:
          return { type: question.type, data: question.groupSections  };
        case QUESTION_TYPES.MULTIPLECHOICE:
          const allOptions = _.cloneDeep(question.answer.options);

          if (question.answer.otherOptions) {
            const otherOptions = question.answer.otherOptions.split('\n');

            otherOptions.forEach(o => allOptions.push({ option: o, value: true }));
          }

          return { type: question.type, meta: { question: question.question }, data: allOptions };
        case QUESTION_TYPES.SINGLECHOICE:
        case QUESTION_TYPES.SINGLESELECT:
          return { type: question.type, meta: { question: question.question }, data: question.answer.options };
        default:
          console.log('Pull for group type is not handled', question.type);
          break;
      }
    }

    if (question.type === QUESTION_TYPES.GROUP && innerQuestionId !== null && innerQuestionId !== ANSWER_TYPES.empty) {
      
      if (question.groupSections && question.groupSections.length === 1) {
        const innerQuestion = question.groupSections[0].questions.find(iQ => iQ.questionId === innerQuestionId);
        if (!innerQuestion) {
          return null;
        }
        return pullQuestionValue(innerQuestion);

      }
      else {
        return null;
      }
    }

    return pullQuestionValue(question);
  };

  const pullValueFromWizard = (wizardId, categoryId, questionId, innerQuestionId, { pullForGroup } = { pullForGroup: false }) => {
    const wizard = wizards.find(w => w.wizardId === wizardId);

    if (!wizard?.wizardAnswers) {
      return null;
    }

    return pullValueFromCategory(wizard.wizardAnswers.categories, categoryId, questionId, innerQuestionId, { pullForGroup });
  }

  const fillInAnswerValue = (unUpdatedQuestion, wizardId, categoryId, questionId, innerQuestionId) => {
    const updatedQuestion = _.cloneDeep(unUpdatedQuestion);
    let valueFromPreviousAnswer = null;

    if (wizardId === currentWizardId) {
      valueFromPreviousAnswer = pullValueFromCategory(localCategories, categoryId, questionId, innerQuestionId);
    } else {
      valueFromPreviousAnswer = pullValueFromWizard(wizardId, categoryId, questionId, innerQuestionId);
    }

    applyDefaultFromValue(updatedQuestion, null, valueFromPreviousAnswer);

    return updatedQuestion;
  }

  const pullOptionsFromCategory = (categories, categoryId, questionId, innerQuestionId) => {
    const category = categories.find(c => c.categoryId === categoryId);

    if (!category) {
      console.log('Was unable to find the category to pull the options from');
      return [];
    }

    const question = category.questions.find(q => q.questionId === questionId);

    if (!question) {
      return [];
    }

    if (!question.groupSections || question.groupSections.length === 0) {
      return [];
    }
    const innerQuestions = question.groupSections.map(section => section.questions.find((q) => q.questionId === innerQuestionId));
    const options = innerQuestions.filter((q) => q !== null).map((q => {
      if (q.type === QUESTION_TYPES.SINGLECHOICE) {
        const selectedOption = q.answer.options.find(o => o.value);

        return selectedOption ? selectedOption.option : null;
      } else {
        return q.answer.value;
      }
    }));
    return options;
  };

  const pullOptionsFromWizard = (wizardId, categoryId, questionId, innerQuestionId) => {
    const wizard = wizards.find(w => w.wizardId === wizardId);

    if (!wizard?.wizardAnswers) {
      return null;
    }

    return pullOptionsFromCategory(wizard.wizardAnswers.categories, categoryId, questionId, innerQuestionId);
  }

  const fillInOptionsValues = (unUpdatedQuestion, wizardId, categoryId, questionId, innerQuestionId) => {

    let optionsFromPreviousAnswer;
    if (wizardId === currentWizardId) {
      optionsFromPreviousAnswer = pullOptionsFromCategory(localCategories, categoryId, questionId, innerQuestionId);
    } else {
      optionsFromPreviousAnswer = pullOptionsFromWizard(wizardId, categoryId, questionId, innerQuestionId);
    }

    if (_.isEqual(optionsFromPreviousAnswer, unUpdatedQuestion.options)) {
      return unUpdatedQuestion;
    } else {

      const selectedOption = unUpdatedQuestion.answer.options ? unUpdatedQuestion.answer.options.find(o => o.value)?.option : null;
      return {
        ...unUpdatedQuestion,
        options: optionsFromPreviousAnswer,
        answer: {
          ...unUpdatedQuestion.answer,
          options: optionsFromPreviousAnswer.map((o) => ({ option: o, value: o === selectedOption }))
        }
      };
    }
  }

  const fillInSubscribedQuestions = (questionsToUpdate) => {
    const unUpdatedQuestions = [...questionsToUpdate];
    const updatedQuestions = unUpdatedQuestions.map((unUpdatedQuestion) => {
      let updatedQuestion = unUpdatedQuestion;

      if (!!unUpdatedQuestion?.defaultFrom?.wizardId) {
        const {
          wizardId: dFWizardId,
          categoryId: dFCategoryId,
          questionId: dFQuestionId,
          innerQuestionId: dFInnerQuestionId
        } = unUpdatedQuestion.defaultFrom;
        updatedQuestion = fillInAnswerValue(unUpdatedQuestion, dFWizardId, dFCategoryId, dFQuestionId, dFInnerQuestionId);
      }
      if (updatedQuestion.optionsFromPreviousAnswers) {
        const {
          wizardId: oFWizardId,
          categoryId: oFCategoryId,
          questionId: oFQuestionId,
          innerQuestionId: oFInnerQuestionId
        } = updatedQuestion.optionsFrom;

        updatedQuestion = fillInOptionsValues(updatedQuestion, oFWizardId, oFCategoryId, oFQuestionId, oFInnerQuestionId);
      }

      if (unUpdatedQuestion?.groupOptions?.defaultsFrom?.length) {
        const defaultsFrom = unUpdatedQuestion.groupOptions.defaultsFrom || [];

        updatedQuestion.groupFromData = defaultsFrom.map((defaultFrom) => {
          const {
            wizardId: dFTWizardId,
            categoryId: dFTCategoryId,
            questionId: dFTQuestionId
          } = defaultFrom

          let groupFromData = null;

          if (dFTCategoryId === currentCategoryId) {
            groupFromData = { ...pullValueFromCategory(localCategories, dFTCategoryId, dFTQuestionId, null, { pullForGroup: true }), filter: defaultFrom?.filter, isLocal: true };
          } else {
            groupFromData = { ...pullValueFromWizard(dFTWizardId, dFTCategoryId, dFTQuestionId, null, { pullForGroup: true }), filter: defaultFrom?.filter, isLocal: false };
          }

          return groupFromData;
        });
      }

      if (updatedQuestion.type === QUESTION_TYPES.GROUP 
          && updatedQuestion.groupSections?.length > 0) {
        if (!!updatedQuestion?.groupOptions?.defaultsFrom?.length) {
          updatedQuestion.groupSections.forEach(section => {
            section.questions = section.questions.map(q => {
              if (q.defaultFrom) {
                const {
                  wizardId: dFTWizardId,
                  categoryId: dFTCategoryId,
                  questionId: dFTQuestionId,
                  innerQuestionId: dFTInnerQuestionId
                } = q.defaultFrom

                let groupFromData = null;
                
                if (dFTCategoryId === currentCategoryId) {
                  groupFromData = { 
                    ...pullValueFromCategory(localCategories, dFTCategoryId, dFTQuestionId, dFTInnerQuestionId, { pullForGroup: true }), 
                    innerQuestionId: dFTInnerQuestionId
                  };
                } else {
                  groupFromData = { 
                    ...pullValueFromWizard(dFTWizardId, dFTCategoryId, dFTQuestionId, dFTInnerQuestionId, { pullForGroup: true }),
                    innerQuestionId: dFTInnerQuestionId
                  };
                }

                q.groupFromData = groupFromData;
              }

              if (q.optionsFromPreviousAnswers) {
                const {
                  wizardId: oFWizardId,
                  categoryId: oFCategoryId,
                  questionId: oFQuestionId,
                  innerQuestionId: oFInnerQuestionId
                } = q.optionsFrom;
                
                q = fillInOptionsValues(q, oFWizardId, oFCategoryId, oFQuestionId, oFInnerQuestionId);
              }

              return q;
            });
          });
        } else {
          updatedQuestion.groupSections.forEach(section => {
            section.questions = fillInSubscribedQuestions(section.questions);
          });
        }
      }
      return updatedQuestion;
    });

    return updatedQuestions;
  }

  useEffect(() => {
    if (!directory || !currentCategoryId) {
      history.push('/home');
    }
  }, [directory, currentCategoryId]);

  if (!directory || !currentCategoryId) {
    return <></>;
  }

  return <AnswersComponent directory={directory} sub_directory={sub_directory} currentWizardId={currentWizardId} currentCategoryId={currentCategoryId} currentCategoryName={currentCategoryName} currentQuestionId={currentQuestionId} questions={fillInSubscribedQuestions(questions)} />;
};

const AnswersComponent = ({ questions, currentWizardId, currentCategoryId, currentCategoryName, currentQuestionId, directory, sub_directory }) => {
  return (
    <Container>
      <PaperStyled elevation={3}>
        <BreadcrumbsComponent />
        {questions?.length > 0 && <AnswerForms directory={directory} sub_directory={sub_directory} lastLocation={{ currentWizardId, currentCategoryId, currentCategoryName, currentQuestionId }} questionAnswers={questions} />}
      </PaperStyled>
    </Container>
  );
};
