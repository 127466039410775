import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
`;

export const StyledAppbar = styled(AppBar)`
  display: flex;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 84px;
  background-color: #fafafa;
  justify-content: space-between;
  align-items: center;
  padding: 0.9375rem 0;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const FooterMailLink = styled.a`
  color: inherit;
  padding: 0.9375rem;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
`;

export const IconButtonTitle = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 28px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  color: '#ffffff';
`;
