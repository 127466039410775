import React, { useState, useEffect, Fragment} from 'react';
import debounce from 'debounce';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles, Typography
 } from '@material-ui/core';

 import {
    Delete, ExpandMore
 } from '@material-ui/icons';
import GroupQuestionCard from './GroupQuestionCard';

import { gateKeeper, pullQuestionValue } from '../helpers';
import { CustomButton } from '../../../../components/Button';

const useStyles = makeStyles({
    accordionDetails: {
        marginBottom: '10px',
        marginTop: '10px',
        display: 'block'
    },
    accordionExpandIcon: {
        color: '#fff',
    },
    accordionSummary: {
        background: 'linear-gradient(60deg, rgb(5, 91, 187), rgb(16, 73, 167))',
        color: '#fff'
    },
    
    accordionHeading: {
        flexBasis: '100%',
        flexGrow: 0 
    },
  });

const GroupSection = ({ index, sectionOptions, data, onEdit, onDelete, onInsertPreviousChanges, selectedSection, setSelectedSection }) => {
    const [hasInitialized, setHasInitialized] = useState(false);
    const [questions, setQuestions] = useState(gateKeeper(data.questions || []));

    const [localQuestions, setLocalQuestions] = useState(gateKeeper(data.questions || []));
    const debouncedSetQuestions = debounce(setQuestions, 600);

    const onChange = (qIndex, changedQuestion) => {

        let updatedQuestions = [...localQuestions];

        updatedQuestions[qIndex] = changedQuestion;

        if (changedQuestion.isGate) {
            updatedQuestions = gateKeeper(updatedQuestions);
        }

        setLocalQuestions(updatedQuestions);
        debouncedSetQuestions(updatedQuestions);
    };

    useEffect(() => {
        if (hasInitialized) {
            onEdit({
                ...data,
                questions
            });
        } else {
            setHasInitialized(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    return (sectionOptions?.isRepeatable ? 
      <GroupSectionCard
        index={index} 
        sectionHeading={data?.sectionHeading}
        templateData={data?.templateData}
        questions={localQuestions} 
        onChange={onChange} 
        onDelete={onDelete}
        onInsertPreviousChanges={() => {
            const newQuestions = onInsertPreviousChanges();

            setLocalQuestions(newQuestions);
        }} 
        canDelete={index !== 0 && sectionOptions?.allowUserAdd}
        canInsert={index !== 0}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        key={data.id + index}
      />
    : <Fragment key={data.id + index}>
        {localQuestions.map((question, qIndex) => {
            if (question?.hidden) {
                return <Fragment key={qIndex}></Fragment>;
            } else {
                return <GroupQuestionCard key={qIndex} data={question} onEdit={(changedQuestion) => onChange(qIndex, changedQuestion)} />;
            }
        })}
    </Fragment>);
};

const GroupSectionCard = ({ 
    index, 
    sectionHeading,
    templateData,
    onChange, 
    onDelete,
    onInsertPreviousChanges, 
    questions,
    selectedSection,
    setSelectedSection,
    canDelete = true,
    canInsert = true
}) => {
    const classes = useStyles();

    const handleChange = (_, isExpanded) => {
        if (isExpanded) {
            setSelectedSection(index);
        } else {
            setSelectedSection(-1);
        }
      };

    const applyLocalSectionHeadingTemplate = () => {
        const questionIndexToValue = (match) => {

            const questionIndex = match.substring(1, match.length - 1);
            const parsedQuestionIndex =  Number.parseFloat(questionIndex);
            if (Number.isNaN(parsedQuestionIndex) || questions.length < questionIndex) {
                return match;
            } else {
                return pullQuestionValue(questions[parsedQuestionIndex -1]) ?? '--';
            }
          };

        let headingText = sectionHeading || templateData?.sectionHeading || `Section #${index + 1}`;
        headingText = headingText.replace(/(\{(\d)+\})/g, questionIndexToValue);
        return headingText;
    }

    return (
    <Accordion expanded={selectedSection === index} onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore className={classes.accordionExpandIcon} />}>
            <Typography className={classes.accordionHeading}>{applyLocalSectionHeadingTemplate()}</Typography>
            {canDelete ? 
                    <CustomButton color='primary' onClick={() => onDelete(index)}>
                        <Delete />
                    </CustomButton>
                :
                    null
            }
            {canInsert && (
                <CustomButton style={{ margin: '0px 20px', width: '400px', border: 'solid 1px' }} color='primary' onClick={onInsertPreviousChanges}>
                    Copy Previous Answers
                </CustomButton>
            )}
            
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            {questions.map((question, qIndex) => {
                if (question?.hidden) {
                    return <></>;
                } else {
                    return <GroupQuestionCard key={qIndex} data={question} onEdit={(changedQuestion) => onChange(qIndex, changedQuestion)} />;
                }
            })}
        </AccordionDetails>
    
    </Accordion>);
};

export default GroupSection;