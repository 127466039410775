import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//helpers
import { history } from '../../store';
import { USER_TYPES } from '../../constants';
import logo from '../../assets/images/logo.png';

// components
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  LayoutContainer,
  StyledAppbar,
  ContentContainer,
  FooterMailLink,
} from './styled';
import {
  Home,
  UserManagement,
  AssociationLogin,
  Logout,
  EPACertification,
  Resources
} from './controls';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 80,
    alignItems: 'center',
  },
  title: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  iconsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  logo: {
    height: 50,
    marginRight: 10
  }
}));

const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const userType = useSelector((store) => store.Common.userType);
  const accessToken = useSelector((store) => store.Common.accessToken);

  const loggedIn = !!accessToken;
  
  const isEPACertification = location.pathname === '/epa-certification';
  const isRegistration = location.pathname === '/registration';
  const isUserManagment = location.pathname === '/user-management';
  const isHome = location.pathname === '/home';
  const isRoot = location.pathname === '/';
  const isAdmin = userType === USER_TYPES.ADMIN;

  const isTransparent = isHome || isRoot;
  const styles = isTransparent
    ? { background: 'transparent', boxShadow: 'none', position: 'absolute' }
    : { position: 'relative' };

  return (
    <div className={classes.root}>
      <StyledAppbar style={styles}>
        <Toolbar className={classes.toolbar}>
          
          <Typography className={classes.title} variant='h6' noWrap>
          <img className={classes.logo} src={logo} alt="TRWA Logo"></img>
          WRAPT
          </Typography>
          <div className={classes.iconsContainer}>
            <Home visible={(loggedIn && !isHome) || isEPACertification} />
            <Resources />
            <EPACertification visible={!isEPACertification} history={history} />
            <UserManagement visible={isAdmin && !isUserManagment} />
            <AssociationLogin visible={!loggedIn} history={history} />
            <Logout visible={loggedIn && !isRegistration} isAdmin={isAdmin} />
          </div>
        </Toolbar>
      </StyledAppbar>
    </div>
  );
};

export const Layout = ({ children }) => {
  const withHeader = useSelector((store) => store.Common.withHeader);
  const withFooter = useSelector((store) => store.Common.withFooter);

  return (
    <LayoutContainer>
      {withHeader && <Header />}
      <ContentContainer>{children}</ContentContainer>
      {withFooter && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 80,
            padding: '0 135px',
            zIndex: 1,
            backgroundColor: 'white'
          }}
        >
          <FooterMailLink href='mailto:info@trwa.org'>
            info@trwa.org
          </FooterMailLink>

          <div>
            © 2021,{' '}
            <a
              href='https://www.trwa.org/'
              target='_blank'
              rel='noopener noreferrer'
            >
              TRWA.org
            </a>{' '}
            | 1616 Rio Grande, Austin, TX 78701
          </div>
        </div>
      )}
    </LayoutContainer>
  );
};
