import React, { useState, useEffect } from 'react';
import {
    TextField,
    Card,
    CardHeader,
    CardContent,
    RadioGroup,
    Radio,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormLabel, 
    FormHelperText,
    Grid
} from '@material-ui/core';
import {
    makeStyles
} from '@material-ui/core/styles';
import {
    Delete
} from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'debounce';

import { QUESTION_TYPES, INPUT_LIMITS, QUESTION_TYPE_METADATA, ANSWER_TYPES, QUESTION_TYPE_OPTIONS, QUESTION_DEFAULT_FROM_TYPE_FILTERS } from '../../../../constants';
import { optionsValidator, reduceValueLength } from '../../helpers';
import constructInputLimitErrorMessage from '../../../../helpers/constructInputLimitErrorMessage';

import TableForm from '../TableForm';
import OptionsForm from '../OptionsForm';
import DefaultOptionsForm from '../DefaultOptionsForm';
import DefaultFromModal from '../DefaultFromModal';

import AdditionalInfoToolTip from '../../../../components/Tooltips/AdditionalInfoToolTip';
import DropDown from '../../../../components/Inputs/DropDown';
import { CustomButton } from '../../../../components/Button';
import AdditionalLinkFrom from '../AdditionalLinkForm';

const useStyles = makeStyles({
    cardContainer: {
        marginBottom: '50px',
        marginTop: '25px'
    },
    cardHeaderContainer: {
        background: 'linear-gradient(60deg, rgb(5, 91, 187), rgb(16, 73, 167))',
        color: '#fff'
    }
});

const QuestionList = ({
    onEdit,
    onDelete,
    formData,
    questions,
    isRepeatable,
    showWaterSystemSourcePath,
    categoryId,
    wizardId,
    wizards,
    defaultFromGroupOptions
}) => {
    return (
        <div>
            {!!questions.length && questions.map((question, index) => <QuestionCard
                key={question.id}
                categoryId={categoryId}
                wizardId={wizardId}
                wizards={wizards}
                index={index}
                formData={formData}
                defaultFromGroupOptions={defaultFromGroupOptions}
                showWaterSystemSourcePath={showWaterSystemSourcePath}
                isRepeatable={isRepeatable}
                question={question}
                onEdit={onEdit}
                onDelete={onDelete} />)}
        </div>
    );
};

const QuestionCard = ({ question, formData, showWaterSystemSourcePath, isRepeatable, index, onEdit, onDelete, categoryId, wizardId, wizards, defaultFromGroupOptions = [] }) => {
    const classes = useStyles();
    const {
        MULTIPLECHOICE,
        SINGLECHOICE,
        SINGLESELECT,
        PRIORITYLEVEL,
        TABLE,
        YESNO,
        SIMPLETEXT,
        LONGTEXT
    } = QUESTION_TYPES;
    const {
        id,
        type,
        question: questionText,
        description,
        additionalLink,
        defaultFrom,
        waterSystemSourcePath,
        table,
        options,
        optionsSpecifyOthers,
        optionsFromPreviousAnswers,
        optionsFrom,
        displayOptions,
        isGate,
        ignoreGate,
        inGate = false,
        isOptional = false,
        key: gateKey
    } = question;
    const {
        submitted,
        active
    } = formData;
    const isOptionsInValid = optionsValidator(options);
    const isMultyChoiceError =
        (type === MULTIPLECHOICE || type === SINGLECHOICE || type === SINGLESELECT) && submitted && !isOptionsInValid;

    const [questionTextError, setQuestionTextError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [tableError, setTableError] = useState('');
    const [optionsFromError, setOptionsFromError] = useState('');
    const [optionError, setOptionError] = useState(null);

    const [localQuestionData, setLocalQuestionData] = useState({
        type,
        question: questionText,
        description,
        additionalLink,
        waterSystemSourcePath,
        table,
        isGate,
        isOptional,
        key: gateKey,
        options,
        displayOptions
    });

    const debouncedOnEdit = debounce(onEdit, 600);

    const onChange = (value, key) => {
        const {
            MAX_CHARACTERS_UPPER_LIMIT,
            MAX_CHARACTERS_DESCRIPTION_LIMIT,
        } = INPUT_LIMITS;
        const defaultValue = {
            id,
            type,
            question: questionText,
            description,
            additionalLink,
            defaultFrom,
            waterSystemSourcePath,
            table,
            options,
            optionsSpecifyOthers,
            optionsFromPreviousAnswers,
            optionsFrom,
            displayOptions,
            isGate,
            isOptional,
            ignoreGate,
            key: gateKey
        };

        switch (key) {
            case 'question':
                setQuestionTextError('');
                defaultValue.question = reduceValueLength(value, MAX_CHARACTERS_UPPER_LIMIT, setQuestionTextError);
                break;
            case 'description':
                setDescriptionError('');
                defaultValue.description = reduceValueLength(value, MAX_CHARACTERS_DESCRIPTION_LIMIT, setDescriptionError);
                break;
            case 'table':
                setTableError('');
                defaultValue.table = value;
                break;
            case 'optionsFrom':
                setOptionsFromError('');
                defaultValue.optionsFrom = value;
                break;
            case 'ignoreGate':
                defaultValue.ignoreGate = value === 'true';
                break;
            case 'isOptional':
                defaultValue.isOptional = value === 'true';
                break;
            default:
                defaultValue[key] = value;
                break;
        }

        setLocalQuestionData({
            ...defaultValue
        });
        debouncedOnEdit({ ...defaultValue }, index);
    };

    const onOptionChange = (option) => {
        setOptionError(null);

        const reduceOptionLength = () => {
            const {
                MAX_CHARACTERS_UPPER_LIMIT
            } = INPUT_LIMITS;
            if (option.value.length > MAX_CHARACTERS_UPPER_LIMIT)
                setOptionError({ id: option.id, error: constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT) });
            return option.value.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
        };
        const newValue = {
            id,
            type,
            question: questionText,
            description,
            additionalLink,
            defaultFrom,
            waterSystemSourcePath,
            table,
            options,
            optionsSpecifyOthers,
            displayOptions,
            isGate,
            isOptional,
            key: gateKey,
            optionsFromPreviousAnswers,
            optionsFrom,
        };

        const optionIndex = displayOptions.findIndex((o) => o.id === option.id);
        if (gateKey === options[optionIndex]) {
            newValue.key = ANSWER_TYPES.empty;
        }
        newValue.displayOptions[optionIndex] = { ...option, value: reduceOptionLength() };
        newValue.options[optionIndex] = reduceOptionLength();

        setLocalQuestionData(newValue);
        debouncedOnEdit(newValue, index);
    };

    const onOptionAdd = () => {

        const newValue = {
            id,
            type,
            question: questionText,
            description,
            additionalLink,
            waterSystemSourcePath,
            table,
            defaultFrom,
            options: [...options, ''],
            optionsSpecifyOthers,
            displayOptions: [...displayOptions, { id: uuidv4(), value: '' }],
            isGate,
            isOptional,
            key: gateKey,
            optionsFromPreviousAnswers,
            optionsFrom,
        };

        setLocalQuestionData(newValue);
        debouncedOnEdit(newValue, index);
    };

    const onOptionRemove = (id) => {

        const filteredDisplayOptions = displayOptions.filter((o) => o.id !== id);
        const newValue = {
            id,
            type,
            question: questionText,
            description,
            additionalLink,
            defaultFrom,
            table,
            options: filteredDisplayOptions.map(o => o.value),
            optionsSpecifyOthers,
            displayOptions: filteredDisplayOptions,
            isGate,
            isOptional,
            key: gateKey,
            optionsFromPreviousAnswers,
            optionsFrom,
        };

        setLocalQuestionData(newValue);
        debouncedOnEdit(newValue, index);
    };

    useEffect(() => {
        if (!questionText && submitted) {
            setQuestionTextError('this field is required');
        }

        if (!!table && submitted) {
            if (!table.columns.length) {
                setTableError('Must include at least one column');
            } else if (table.columns.find(column => !column.name)) {
                setTableError('Columns must have names');
            }
        }

        if (optionsFromPreviousAnswers && !!optionsFrom && submitted) {
            if (!optionsFrom.wizardId || optionsFrom.wizardId === ANSWER_TYPES.empty
                || !optionsFrom.categoryId || optionsFrom.categoryId === ANSWER_TYPES.empty
                || !optionsFrom.questionId || optionsFrom.questionId === ANSWER_TYPES.empty
                || !optionsFrom.innerQuestionId || optionsFrom.innerQuestionId === ANSWER_TYPES.empty) {
                setOptionsFromError('Select a source question for the options values');
            }
        }

        if (localQuestionData.table === null && type === TABLE)
            setLocalQuestionData({
                ...localQuestionData,
                table: question.table
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question, submitted]);

    return (<Card className={classes.cardContainer}>
        <CardHeader
            className={classes.cardHeaderContainer}
            title={`Question #${index + 1} - ${QUESTION_TYPE_METADATA[type].label}`}
            action={
                <CustomButton color='primary' onClick={() => onDelete(index)}>
                    <Delete />
                </CustomButton>
            }
        />
        <CardContent>
            <TextField
                value={localQuestionData.question}
                onChange={({ currentTarget: { value } }) => {
                    onChange(value, 'question');
                }}
                error={questionTextError}
                helperText={questionTextError || QUESTION_TYPE_METADATA[type].helperText}
                disabled={!active}
                label='Question'
                style={{ margin: 8 }}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                variant='outlined'
                margin='dense'
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {(!isRepeatable || !!defaultFromGroupOptions.length) && !!QUESTION_DEFAULT_FROM_TYPE_FILTERS[type] && <DefaultFromModal
                    internalOptionsOnly={!!defaultFromGroupOptions.length}
                    type={type}
                    categoryId={categoryId}
                    wizardId={wizardId}
                    wizards={wizards}
                    defaultFrom={defaultFrom}
                    localOptions={formData.localOptions}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    filteredOptions={defaultFromGroupOptions}
                    variant="outlined"
                    margin='dense'
                    onDefaultSelected={(value) => onChange(value, 'defaultFrom')}
                    />}
                {inGate && 
                <div style={{ marginLeft: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <p>Ignore coordinator</p>
                        <AdditionalInfoToolTip style={{ margin: '0px' }} info="This will prevent the coordinator from hiding this question" />
                    </div>
                    <FormControl>
                        <RadioGroup
                            value={ignoreGate}
                            onChange={({ currentTarget: { value } }) => {
                                onChange(value, 'ignoreGate');
                            }}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio color='primary' />}
                                label='Yes'
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio color='primary' />}
                                label='No'
                            />
                        </RadioGroup>
                    </FormControl>    
                </div>}
            </div>
            {(type === SIMPLETEXT || type === LONGTEXT) && (
                <Grid item style={{ margin: 8 }}>
                    <div>
                        Is Optional
                    </div>
                    <RadioGroup
                        value={isOptional}
                        onChange={({ target }) => {
                            onChange(target.value, 'isOptional');
                        }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                    <FormControlLabel
                        value={true}
                        control={<Radio color='primary' />}
                        label='Yes'
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio color='primary' />}
                        label='No'
                    />
                    </RadioGroup>
                </Grid>
            )}
            {QUESTION_TYPE_METADATA[type].options === QUESTION_TYPE_OPTIONS.DETAILED && (
                <>
                    <TextField
                        value={localQuestionData.description}
                        onChange={({ currentTarget: { value } }) => {
                            onChange(value, 'description');
                        }}
                        disabled={!active}
                        label='Description (optional)'
                        style={{ margin: 8, marginTop: 20 }}
                        helperText={
                            descriptionError || "add description if it's necessary"
                        }
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant='outlined'
                        margin='dense'
                        multiline
                        rows={2}
                        error={descriptionError}
                    />
                    <AdditionalLinkFrom disabled={!active} additionalLink={additionalLink} onChange={(value) => onChange(value, 'additionalLink')} />
                </>
            )}
            {showWaterSystemSourcePath &&
                <TextField
                    value={localQuestionData.waterSystemSourcePath}
                    onChange={({ currentTarget: { value } }) => {
                        onChange(value, 'waterSystemSourcePath');
                    }}
                    label='Water System Source Path (optional)'
                    style={{ margin: 8 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    margin='dense'
                />
            }
            {(type === SINGLECHOICE || type === SINGLESELECT || type === PRIORITYLEVEL || type === YESNO || type === TABLE) &&
                <QuestionKeyForm
                    type={type}
                    submitted={submitted}
                    options={displayOptions}
                    isGate={localQuestionData.isGate}
                    gateKey={localQuestionData.key}
                    onChange={onChange}
                />
            }
            {(
                type === MULTIPLECHOICE ||
                type === SINGLECHOICE
            ) && (<OptionsForm
                options={displayOptions}
                onOptionChange={onOptionChange}
                onOptionAdd={onOptionAdd}
                onOptionRemove={onOptionRemove}
                active={active}
                isMultyChoiceError={isMultyChoiceError}
                optionError={optionError}
            />
                )}
            {type === SINGLESELECT &&
                <>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Options From</FormLabel>
                        <RadioGroup row aria-label="optionsFromPreviousAnswers" name="optionsFromPreviousAnswers" value={optionsFromPreviousAnswers} onChange={({ currentTarget: { value } }) => {
                            onChange(value === 'true', 'optionsFromPreviousAnswers');
                        }}>
                            <FormControlLabel value={false} control={<Radio color='primary' />} label="Static List" />
                            <FormControlLabel value={true} control={<Radio color='primary' />} label="Previous Answers" />
                        </RadioGroup>
                    </FormControl>

                    {!optionsFromPreviousAnswers &&
                        <OptionsForm
                            options={displayOptions}
                            onOptionChange={onOptionChange}
                            onOptionAdd={onOptionAdd}
                            onOptionRemove={onOptionRemove}
                            active={active}
                            isMultyChoiceError={isMultyChoiceError}
                            optionError={optionError}
                        />}

                    {optionsFromPreviousAnswers &&
                        <DefaultOptionsForm
                            type={type}
                            categoryId={categoryId}
                            wizardId={wizardId}
                            wizards={wizards}
                            optionsFrom={optionsFrom}
                            onOptionsFrom={(value) => onChange(value, 'optionsFrom')}
                            error={optionsFromError}
                        />
                    }
                </>
            }
            {(
                  type === MULTIPLECHOICE
                ) && (<FormControlLabel
                  control={
                    <Checkbox
                      checked={optionsSpecifyOthers}
                      onChange={( event ) => {
                        onChange(event.target.checked, 'optionsSpecifyOthers');
                      }}
                      name="optionsSpecifyOthers"
                      color="primary"
                    />
                  }
                  label="Allow User to Add Other Options"/>
                  )}
            {type === TABLE && (
                <TableForm
                    onChange={(value) => onChange(value, 'table')}
                    error={tableError}
                    table={localQuestionData.table}
                />
            )}
        </CardContent>
    </Card>);
};

const QuestionKeyForm = ({ type, isGate, gateKey, options, onChange, submitted }) => {
    const {
        SINGLECHOICE,
        SINGLESELECT,
        PRIORITYLEVEL,
        YESNO,
    } = QUESTION_TYPES;
    const isOptionType = type === SINGLECHOICE || type === SINGLESELECT;

    const onSelect = (value) => {
        if (value === ANSWER_TYPES.empty) {
            onChange(null, 'key');
        } else {
            onChange(value, 'key');
        }
    };

    return (
        <div style={{ margin: 8, display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <div style={{ display: 'flex' }}>
                    <p>Is a coordinator</p>
                    <AdditionalInfoToolTip style={{ margin: '0px' }} info="If a question is a coordinator it will show or hide following questions based on the users’ answer." />
                </div>
                <RadioGroup
                    value={isGate}
                    onChange={({ currentTarget: { value } }) => {
                        onChange(value === 'true', 'isGate');
                    }}
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio disabled={isOptionType && !options?.length} color='primary' />}
                        label='Yes'
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio disabled={isOptionType && !options?.length} color='primary' />}
                        label='No'
                    />
                </RadioGroup>
            </div>
            {isOptionType && isGate && (<div>
                <div style={{ display: 'flex' }}>
                    <p>Answer key</p>
                    <AdditionalInfoToolTip style={{ margin: '0px' }} info="What answer needs to be given to show following questions" />
                </div>
                <DropDown
                    options={options.map(o => ({ label: o.value, value: o.value }))}
                    selectedValue={gateKey || ANSWER_TYPES.empty}
                    onSelect={onSelect}
                    error={submitted && !gateKey ? 'You need to select a gate key' : ''}
                />
            </div>)}
            {type === YESNO && isGate && (<div>
                <div style={{ display: 'flex' }}>
                    <p>Answer key</p>
                    <AdditionalInfoToolTip style={{ margin: '0px' }} info="What answer needs to be given to show following questions" />
                </div>
                <FormControl error={submitted && !gateKey}>
                    <RadioGroup
                        value={gateKey}
                        onChange={({ currentTarget: { value } }) => {
                            onChange(value, 'key');
                        }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            value={'true'}
                            control={<Radio color='primary' />}
                            label='Yes'
                        />
                        <FormControlLabel
                            value={'false'}
                            control={<Radio color='primary' />}
                            label='No'
                        />
                    </RadioGroup>
                    <FormHelperText>{submitted && !gateKey ? 'You need to select a gate key' : ''}</FormHelperText>
                </FormControl>
            </div>)}
            {type === PRIORITYLEVEL && isGate && (<div>
                <div style={{ display: 'flex' }}>
                    <p>Answer key</p>
                    <AdditionalInfoToolTip style={{ margin: '0px' }} info="What answer needs to be given to show following questions" />
                </div>
                <DropDown
                    options={[{label: "Low", value: "Low"}, {label: "Medium", value: "Medium"}, {label: "High", value: "High"}]}
                    selectedValue={gateKey || ANSWER_TYPES.empty}
                    onSelect={onSelect}
                    error={submitted && !gateKey ? 'You need to select a gate key' : ''}
                />
            </div>)}
        </div>
    );
};

export default QuestionList;