import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminGetSecurityCodesCall,
  adminRegisterSecurityCodesCall,
  adminDeleteSecurityCodesCall,
  adminUpdateSecurityCodeNameCall,
  adminRefreshSecurityCodesCall,
} from '../../store/admin/actions';
import {
  Container,
  PaperStyled,
  FooterContainer,
  AlignWrapper,
} from './styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomButton } from '../../components/Button';
import { SecurityCodeComponent } from './SecurityCodeComponent';
import { BreadcrumbsComponent } from '../../components/Breadcrumbs';
import LoadingOverlay from '../../components/LoadingIndicators/LoadingOverlay';

export const UserManagement = () => {
  const dispatch = useDispatch();
  const codes = useSelector((store) => store.Admin.securityCodes);
  const refreshingSecurityCodeId = useSelector(
    (store) => store.Admin.refreshingSecurityCodeId
  );
  const [securityCodes, setSecurityCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSecurityCodes(codes);
  }, [codes]);

  useEffect(() => {
    setIsLoading(true);
    const getCodes = async () => {
      await dispatch(adminGetSecurityCodesCall());
    };
    getCodes().finally(() => {
      setIsLoading(false);
    });

  }, [dispatch]);

  const registerSecurityCode = async () => {
    setIsLoading(true);
    await dispatch(adminRegisterSecurityCodesCall());
    setIsLoading(false);
  };

  const deleteCode = async (securityCode) => {
    const updatedCodes = [...securityCodes].filter(
      (u) => u.securityCode !== securityCode
    );
    setIsLoading(true);
    await dispatch(
      adminDeleteSecurityCodesCall({ securityCode, updatedCodes })
    );
    setIsLoading(false);
  };

  let delay = null;
  const updateSecurityCodeName = (newUser) => {
    clearTimeout(delay);
    delay = setTimeout(async () => {
      const index = securityCodes.findIndex((u) => u.id === newUser.id);
      const updatedCodes = [...securityCodes];
      updatedCodes[index] = newUser;

      if (newUser.name.length > 2) {
        setIsLoading(true);
        await dispatch(
          adminUpdateSecurityCodeNameCall({
            name: newUser.name,
            securityCode: newUser.securityCode,
            updatedCodes,
          })
        );
        setIsLoading(false);
      }
    }, 600);
  };

  const updateSecurityCode = async ({ securityCode }) => {
    setIsLoading(true);
    await dispatch(
      adminRefreshSecurityCodesCall({
        securityCode,
        codes,
      })
    );
    setIsLoading(false);
  };

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      <PaperStyled
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AlignWrapper>
          <BreadcrumbsComponent />
          <InfiniteScroll dataLength={securityCodes.length}>
            {securityCodes.map((data, index) => (
              <SecurityCodeComponent
                key={data.id}
                data={data}
                index={index}
                loading={refreshingSecurityCodeId === data.securityCode}
                deleteCode={deleteCode}
                updateSecurityCodeName={updateSecurityCodeName}
                updateSecurityCode={updateSecurityCode}
              />
            ))}
          </InfiniteScroll>
          <FooterContainer>
            <CustomButton
              onClick={registerSecurityCode}
              variant='contained'
              color='primary'
            >
              add code
            </CustomButton>
          </FooterContainer>
        </AlignWrapper>
      </PaperStyled>
    </Container>
  );
};
