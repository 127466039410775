import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { history, store } from '../../store';
import { Toast } from '../Toast';
import { Router } from './Router';
import { Layout } from '../Layout';

export const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Layout>
          <Router />
        </Layout>
        <Toast />
      </ConnectedRouter>
    </Provider>
  );
};
