import _ from 'lodash';
import { QUESTION_TYPES } from '../constants';
import getAllIndexes from './getAllIndexes';
import parseBool from './parseBool';

const gateChecker = (questions = [], isAnswerForm = false) => { 
    const {
        TABLE,
        YESNO,
        SINGLECHOICE,
        SINGLESELECT,
        PRIORITYLEVEL
    } = QUESTION_TYPES;
    const gateIndexes = getAllIndexes(questions, (question) => question.isGate);
    let updatedQuestions = [];

    if (!gateIndexes.length) {
        updatedQuestions = questions.map(question => ({ inGate: false, ...question }));
    } else {
        if (gateIndexes[0] !== 0) {
            updatedQuestions = updatedQuestions.concat(_.cloneDeep(questions).splice(0, gateIndexes[0]));
        }

        gateIndexes.forEach((i, index) => {
            const gate = questions[i];
            const isLastGate = index + 1 === gateIndexes.length;
            const questionsBetweenStartIndex = i + 1;
            const questionsBetweenEndIndex = isLastGate ? questions.length : gateIndexes[index + 1];
            const questionsBetween = _.cloneDeep(questions).slice(questionsBetweenStartIndex, questionsBetweenEndIndex);

            let hideQuestions = true;

            if (isAnswerForm) {
                switch (gate.type) {
                    case TABLE:
                        hideQuestions = false;
                        break;
                    case SINGLECHOICE:
                    case SINGLESELECT:
                        const selectedOption = gate.answer.options.find((o) => o.value === true);

                        if (selectedOption) hideQuestions = gate.key !== selectedOption.option;
                        break;
                    case PRIORITYLEVEL:
                        const selectedPriority = gate.answer.value;
                        hideQuestions = selectedPriority !== gate.key;
                        break;
                    case YESNO:
                        const hide = 'HIDE';
                        const value = gate.answer.value === null || gate.answer.value === 'N/A' 
                            ? hide 
                            : parseBool(gate.answer.value);
                        const key = parseBool(gate.key);

                        hideQuestions = value === hide || value !== key;
                        break;
                    default:
                        console.log('This gate type is not being used');
                        break;
                }
            }

            const updatedQuestionsBetween = questionsBetween.map((question) => ({
                ...question,
                inGate: true,
                hidden: question?.ignoreGate === true ? false : hideQuestions
            }))

            updatedQuestions.push(gate);
            updatedQuestions = updatedQuestions.concat(updatedQuestionsBetween);
        });
    }

    return updatedQuestions;
};

export default gateChecker;