import React from 'react';
import { 
  makeStyles
} from '@material-ui/core/styles';
import { 
    IconButton
} from '@material-ui/core';
import {
    Info
} from '@material-ui/icons';
import { LargeTextToolTip } from './styled';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  }
}));

const AdditionalInfoToolTip = ({ info, style }) => {
    const classes = useStyles();

    return (
        <div>
          <LargeTextToolTip title={info}>
                <IconButton style={style || {}} className={classes.button} aria-label="info">
                    <Info />
                </IconButton>
          </LargeTextToolTip>
        </div>
      );
};

export default AdditionalInfoToolTip;