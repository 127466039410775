import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile } from '@material-ui/core';
import TableCellAdmin from '../../../components/Table/TableCellAdmin';
import TableMenuInput from '../../../components/Table/TableMenuInput';
import { COLUMN_TYPES, COLUMN_AGGREGATE_TYPES } from '../../../constants';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        marginTop: 50,
        marginLeft: 8
    },
    tableControlsContainer: {
        display: 'flex'
    },
    gridListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        
      },
      gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width: '100%'
      },

});

const TableForm = ({
    onChange,
    table,
    showWaterSystemSourcePath,
    error
}) => {
    const classes = useStyles();
    const [columns, setColumns] = useState(table?.columns || []);
    const columnLimit = 5;

    const addColumn = (type) => {
        const newColumns = [...columns];

        if (newColumns.length <= columnLimit)
            newColumns.push({ name: '', type, aggregateType: COLUMN_AGGREGATE_TYPES.none, formula: null, waterSystemSourcePath: null });

        setColumns(newColumns);
    }

    const editColumn = (value, key, index) => {
        console.info(value, key, index);
        const newColumns = [...columns];
        newColumns[index][key] = value;
        console.table(newColumns);
        setColumns(newColumns);
    }

    const deleteColumn = (index) => {
        const newColumns = [...columns];
        newColumns.splice(index, 1);

        setColumns(newColumns);
    }

    useEffect(() => {
        const data = {
            columns
        };

        onChange(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    return (
        <div className={classes.tableContainer}>
            <div className={classes.tableControlsContainer}>
                <TableMenuInput
                    buttonText="Add Column"
                    style={{ margin: '8px', marginLeft: '0px' }}
                    inputs={[
                        { label: 'Text', action: () => addColumn(COLUMN_TYPES.text) },
                        { label: 'Number', action: () => addColumn(COLUMN_TYPES.number) },
                        { label: 'Calculated', action: () => addColumn(COLUMN_TYPES.calculated) },
                    ]}
                />
                <TableMenuInput buttonText="Delete Column" showButton={!!columns.length}
                    inputs={columns.map((column, index) => ({ label: column.name, action: () => deleteColumn(index) }))}
                />
            </div>
            <div>
                {error ? <p style={{ color: 'red' }}>{error}</p> : <></>}
            </div>
            <div className={classes.gridListContainer}
                style={error ? { border: '1px solid red' } : {}}
            >
                <GridList  className={classes.gridList} cols="2.5">
                    {columns.map((column, index) => {
                        return <GridListTile style={{height: 285}}>
                            <TableCellAdmin label={column.type} 
                            showWaterSystemSourcePath={showWaterSystemSourcePath}
                            placeholder="column name" cell={{ 
                                name: column.name, 
                                type: column.type,
                                formula: column.formula,
                                waterSystemSourcePath: column.waterSystemSourcePath,
                                aggregateType: column.aggregateType }} onChange={(value, key) => editColumn(value, key, index)} />
                        </GridListTile> 
                    })}
                </GridList>
            </div>
        </div>
    );
};

export default TableForm;