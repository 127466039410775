import React from 'react';
import { CustomButton } from '../../components/Button';
import { Preloader } from '../../components/Preloader';

const buttonStyle = {
  marginBottom: 10,
};

export const AdminContactInfoActions = ({
  setCurrentBranch,
  toggleContactInfoPublish,
  isContactInfoPublished,
  contactInfoId,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }
  return (
    <>
      {!isContactInfoPublished && (
        <>
          <CustomButton
            onClick={() => {
              setCurrentBranch({
                directory: 'contactinfo',
                sub_directory: 'contactinfo',
              });
            }}
            style={buttonStyle}
            color='primary'
            variant='contained'
          >
            edit contact info
          </CustomButton>
          
          {contactInfoId && (
            <CustomButton
              onClick={() => {
                toggleContactInfoPublish(true);
              }}
              style={buttonStyle}
              color='success'
              variant='contained'
            >
              publish wizard
            </CustomButton>
          )}
        </>
      )}
      {isContactInfoPublished && contactInfoId && (
        <CustomButton
          onClick={() => {
            toggleContactInfoPublish(false);
          }}
          style={buttonStyle}
          color='warning'
          variant='contained'
        >
          unpublish wizard
        </CustomButton>
      )}
    </>
  );
};

export const AdminDrinkinWaterActions = ({
  setCurrentBranch,
  toggleDrinkingPublish,
  isDrinkingPublished,
  drinkingId,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }
  return (
    <>
      {!isDrinkingPublished && (
        <>
          <CustomButton
            onClick={() => {
              setCurrentBranch({
                directory: 'drinking',
                sub_directory: 'risk_assessment',
              });
            }}
            style={buttonStyle}
            color='primary'
            variant='contained'
          >
            edit risk assessment
          </CustomButton>
          <CustomButton
            onClick={() => {
              setCurrentBranch({
                directory: 'drinking',
                sub_directory: 'emergency_response_plan',
              });
            }}
            style={buttonStyle}
            color='primary'
            variant='contained'
          >
            edit emergency response plan
          </CustomButton>
          {drinkingId && (
            <CustomButton
              onClick={() => {
                toggleDrinkingPublish(true);
              }}
              style={buttonStyle}
              color='success'
              variant='contained'
            >
              publish wizard
            </CustomButton>
          )}
        </>
      )}
      {isDrinkingPublished && drinkingId && (
        <CustomButton
          onClick={() => {
            toggleDrinkingPublish(false);
          }}
          style={buttonStyle}
          color='warning'
          variant='contained'
        >
          unpublish wizard
        </CustomButton>
      )}
    </>
  );
};

export const AdminWasteWaterActions = ({
  setCurrentBranch,
  toggleWastePublish,
  isWastePublished,
  wasteId,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }
  return (
    <>
      {!isWastePublished && (
        <>
          <CustomButton
            onClick={() => {
              setCurrentBranch({
                directory: 'waste',
                sub_directory: 'risk_assessment',
              });
            }}
            style={buttonStyle}
            color='primary'
            variant='contained'
          >
            edit risk assessment
          </CustomButton>
          <CustomButton
            onClick={async () => {
              setCurrentBranch({
                directory: 'waste',
                sub_directory: 'emergency_response_plan',
              });
            }}
            style={buttonStyle}
            color='primary'
            variant='contained'
          >
            edit emergency response plan
          </CustomButton>
          {wasteId && (
            <CustomButton
              onClick={() => {
                toggleWastePublish(true);
              }}
              style={buttonStyle}
              color='success'
              variant='contained'
            >
              publish wizard
            </CustomButton>
          )}
        </>
      )}
      {isWastePublished && wasteId && (
        <CustomButton
          onClick={() => {
            toggleWastePublish(false);
          }}
          style={buttonStyle}
          color='warning'
          variant='contained'
        >
          unpublish wizard
        </CustomButton>
      )}
    </>
  );
};
