import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select, TextField, FormControl, InputLabel, MenuItem, Grid, OutlinedInput } from '@material-ui/core';

import {
    ANSWER_TYPES,
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

const keyMapper = (options) => !!options ? options.map(o => ({
    ...o,
    key: uuidv4(),
})) : [];

const SingleSelectForm = ({
  data,
  callBack,
  onNoteEdit,
  includeNote = true,
  isCompact = false
}) => {
  const {
    id,
    question: questionText,
    templateData = {
      description: '',
      question: ''
    },
    answer: { options: answerOptions, note: noteValue },
  } = data;
  const [mappedOptions, setMappedOptions] = useState(keyMapper(answerOptions));
  const [noteOverLimit, setNoteOverLimit] = useState(null);

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  const handleChange = (key) => {
    const index = mappedOptions.findIndex((o) => o.key === key);
    const updatesOptions = [...answerOptions].map((o) => ({ option: o.option, value: false }));
    if (key !== ANSWER_TYPES.empty)
        updatesOptions[index] = { option: mappedOptions[index].option, value: !mappedOptions[index].value };

    callBack({
      options: updatesOptions,
      id,
    });
  };

  useEffect(() => setMappedOptions(keyMapper(answerOptions)), [answerOptions]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <Grid container direction='column' spacing={3}>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        style={{ marginBottom: 10 }}
      >
        <FormControl variant="outlined" style={{ padding: 8, width: "90%" }}>
          <InputLabel
            shrink
            ref={inputLabel}
            style={{marginLeft: '7px', marginTop: '7px'}}
          >
            {isCompact ? templateData.question || questionText : ''}
          </InputLabel>
          <Select
            value={mappedOptions.find(mO => !!mO.value)?.key || ANSWER_TYPES.empty}
            onChange={(event) => handleChange(event.target.value)}
            input={
              <OutlinedInput
                notched
                labelWidth={labelWidth}
              />
            }
          >
            <MenuItem value={ANSWER_TYPES.empty}>{ANSWER_TYPES.empty}</MenuItem>
            {mappedOptions.map((o) =>
              <MenuItem key={o?.id || uuidv4()} value={o.key}>{o.option}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid item style={{ width: '90%' }}>
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            value={noteValue}
            multiline
            style={{ width: '100%' }}
            rows={4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helperText={noteOverLimit || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SingleSelectForm;