import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    marginTop: -25,
    marginRight: 20
  },
  additionalText: {
    fontSize: 15,
    fontWeight: 500,
  },
}));

export const LinearProgressComponent = ({
  progress,
  variant = 'determinate',
  additionalText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.additionalText}>{additionalText || ''}</div>
      <LinearProgress variant={variant} value={progress} />
    </div>
  );
};
