import React, { useState, useEffect } from 'react';
import { UserContainer, SecretCodeContainer } from './styled';
import TextField from '@material-ui/core/TextField';
import { PermIdentity, DeleteOutline, Cached } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

export const SecurityCodeComponent = ({
  data,
  index,
  loading,
  deleteCode,
  updateSecurityCodeName,
  updateSecurityCode,
}) => {
  const { name, securityCode } = data;
  const [inputValue, setValue] = useState(name || '');
  const [error, setError] = useState('');

  const requestNewCode = async () => {
    if (!loading) {
      await updateSecurityCode({ securityCode });
    }
  };

  useEffect(() => {
    if (!!name) {
      if (inputValue.length <= 2) {
        setError('Name must have at least three characters');
      } else {
        setError('');
      }
    } else {
      if (inputValue.length !== 0 && inputValue.length <= 2) {
        setError('Name must have at least three characters');
      } else {
        setError('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <UserContainer>
      <PermIdentity />
      <TextField
        value={inputValue}
        error={!!error}
        helperText={error}
        label={`Association user ${index + 1}`}
        onChange={({ currentTarget: { value } }) => {
          setValue(value);
          updateSecurityCodeName({
            ...data,
            name: value,
          });
        }}
        style={{ margin: '0px 20px 16px 10px' }}
        fullWidth
        margin='normal'
      />

      <SecretCodeContainer>
        {loading ? <CircularProgress size={25} /> : securityCode}
      </SecretCodeContainer>
      <IconButton onClick={requestNewCode}>
        <Cached />
      </IconButton>

      <IconButton
        onClick={() => {
          deleteCode(securityCode);
        }}
      >
        <DeleteOutline color='primary' />
      </IconButton>
    </UserContainer>
  );
};
