import React, { useState, useEffect } from 'react';
import {
  Snackbar
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { USER_TYPES, WIZARD_TYPES, DIRECTORY_TYPES } from '../../constants';
import {
  setBranchData,
  setCurrentCategoryData,
} from '../../store/common/actions';
import {
  setDirectoryPublished,
  setDirectories,
} from '../../store/admin/actions';
import { userSetDirectories, startNewWizardAnswers, searchPwsId, startNewWizardAnswersByPwsId, getWizardAnswers, getAllWizardAnswersForDirectory, getAllCategoryAnswersForDirectory } from '../../store/user/actions';

import { history } from '../../store';

// components
import { AdminContactInfoActions, AdminDrinkinWaterActions, AdminWasteWaterActions } from './admin';
import { UserStartActions, UserContactInfoActions, UserWasteWaterActions, UserDrinkinWaterActions } from './user';
import {
  Container,
  PaperStyled,
  AppBarStyled,
  Description,
  ShortDescription,
  ButtonsContainer,
  AlignWrapper,
} from './styled';
import { AlertInfo } from '../../components/Alerts';
import LastLocationLink from '../../components/Links/LastLocationLink';
import LoadingOverlay from '../../components/LoadingIndicators/LoadingOverlay';

export const Home = () => {
  const dispatch = useDispatch();
  const userType = useSelector((store) => store.Common.userType);
  const isAdmin = userType === USER_TYPES.ADMIN;
  const isAdminLoading = useSelector((store) => store.Admin.loading);
  const isUserLoading = useSelector((store) => store.User.loading);
  const lastLocation = useSelector((store) => store.User.lastLocation);

  const contactInfoCategory = useSelector((store) => store.Admin.contactinfo);
  const drinkingCategory = useSelector((store) => store.Admin.drinking);
  const wasteCategory = useSelector((store) => store.Admin.waste);

  const { published: contactInfoPubStatus, id: contactInfoId } = contactInfoCategory;
  const { published: drinkingPubStatus, id: drinkingId } = drinkingCategory;

  const { published: wastePubStatus, id: wasteId } = wasteCategory;

  const userHasStarted = useSelector((store) => {
    return store.User.hasStarted;
  });
  const isPwsSearching = useSelector((store) => store.User.pwsSearching);
  const pwsSearchError = useSelector((store) => store.User.pwsSearchError);
  const pwsSearchResult = useSelector((store) => store.User.pwsSearchResult);

  const userContactInfoCategory = useSelector((store) => store.User.contactinfo);
  const userDrinkingCategory = useSelector((store) => store.User.drinking);
  const userWasteCategory = useSelector((store) => store.User.waste);

  const [isLoading, setIsLoading] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);

  const {
    published: userContactInfoPubStatus,
    id: userContactInfoId,
  } = userContactInfoCategory;
  
  const {
    published: userDrinkingPubStatus,
    id: userDrinkingId,
  } = userDrinkingCategory;
  const { published: userWastePubStatus, id: userWasteId } = userWasteCategory;

  useEffect(() => {
    const setBranchesToDefaultState = async () => {
      await dispatch(setBranchData({ directory: '', sub_directory: '' }));
      await dispatch(setCurrentCategoryData(null));
      if (isAdmin) {
        await dispatch(setDirectories(null));
      } else if (!userContactInfoCategory.id || !userDrinkingCategory.id || !userWasteCategory.id) {
        await dispatch(userSetDirectories(null));
      }
    };
    setBranchesToDefaultState();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => { 
    if (
      !isAdmin &&
      userContactInfoCategory.id &&
      !userContactInfoCategory.contactinfo.wizardAnswers
    ) dispatch(getAllWizardAnswersForDirectory(userContactInfoCategory));

    if (
      !isAdmin &&
      userContactInfoCategory.id &&
      userContactInfoCategory.contactinfo.wizardAnswers &&
      !userContactInfoCategory.contactinfo.wizardAnswers.categories
    ) dispatch(getAllCategoryAnswersForDirectory(userContactInfoCategory));
  // eslint-disable-next-line 
  }, [userContactInfoCategory, isAdmin]);

  useEffect(() => { 
    if (
      !isAdmin && 
      userDrinkingCategory.id &&
      !userDrinkingCategory.emergency_response_plan.wizardAnswers &&
      !userDrinkingCategory.risk_assessment.wizardAnswers
    ) dispatch(getAllWizardAnswersForDirectory(userDrinkingCategory));

    if (
      !isAdmin && 
      userDrinkingCategory.id &&
      userDrinkingCategory.emergency_response_plan.wizardAnswers &&
      userDrinkingCategory.risk_assessment.wizardAnswers && 
      !userDrinkingCategory.emergency_response_plan.wizardAnswers.categories &&
      !userDrinkingCategory.risk_assessment.wizardAnswers.categories
    ) dispatch(getAllCategoryAnswersForDirectory(userDrinkingCategory));
  // eslint-disable-next-line
  }, [userDrinkingCategory, isAdmin]);

  useEffect(() => {
    if (
      !isAdmin &&
      userWasteCategory.id &&
      !userWasteCategory.emergency_response_plan.wizardAnswers &&
      !userWasteCategory.risk_assessment.wizardAnswers
    ) dispatch(getAllWizardAnswersForDirectory(userWasteCategory));

    if (
      !isAdmin && 
      userWasteCategory.id &&
      userWasteCategory.emergency_response_plan.wizardAnswers &&
      userWasteCategory.risk_assessment.wizardAnswers && 
      !userWasteCategory.emergency_response_plan.wizardAnswers.categories &&
      !userWasteCategory.risk_assessment.wizardAnswers.categories
    ) dispatch(getAllCategoryAnswersForDirectory(userWasteCategory));
  // eslint-disable-next-line
  }, [userWasteCategory, isAdmin]);

  useEffect(() => setIsSnackOpen(!!lastLocation), [lastLocation]);

  const startNew = async() => {
    await dispatch(startNewWizardAnswers());
  }

  const search = async({pwsId}) => {
    await dispatch(searchPwsId({pwsId}));
  }

  const startWithPwsId  = async({pwsId}) => {
    await dispatch(startNewWizardAnswersByPwsId({pwsId}));
  }

  const getUserWizards = async (wizards) => {
    await Promise.all(wizards.map((w) => {
      return dispatch(getWizardAnswers({ directory: w.directory, sub_directory: w.sub_directory, wizard_id: w.wizard_id }));
    }));
  }

  const pullWizardFromObject = (directoryObject, sub_directory_name, directory_name) => {
    const reducedData = {
      directory: directory_name,
      sub_directory: sub_directory_name,
      wizard_id: directoryObject[sub_directory_name]?.id,
      wizardAnswers: directoryObject[sub_directory_name]?.wizardAnswers
    };

    return reducedData;
  }

  const onLastLocationClick = async (data) => {
    const {
      emergencyresponseplan,
      riskassessment,
      contactinfo: contactinfoWizType
    } = WIZARD_TYPES;
    const {
      waste: wasteType,
      drinking: drinkingType,
      contactinfo: contactinfoDirType
    } = DIRECTORY_TYPES;
  
    const wizards = [
      pullWizardFromObject(userWasteCategory, emergencyresponseplan, wasteType),
      pullWizardFromObject(userWasteCategory, riskassessment, wasteType),
      pullWizardFromObject(userDrinkingCategory, emergencyresponseplan, drinkingType),
      pullWizardFromObject(userDrinkingCategory, riskassessment, drinkingType),
      pullWizardFromObject(userContactInfoCategory, contactinfoWizType, contactinfoDirType)
    ];
    const wizard = wizards.find((wiz) => wiz?.wizard_id === data.currentWizardId);

    if (wizard) {
      setIsLoading(true);
      await dispatch(setBranchData({ directory: wizard.directory, sub_directory: wizard.sub_directory }));
      await dispatch(setCurrentCategoryData(data));

      await getUserWizards(wizards);
      setIsLoading(false);

      history.push('/answers');
    }
  }

  const setCurrentBranch = async ({ directory, sub_directory }) => {
    await dispatch(setBranchData({ directory, sub_directory }));
    history.push('/categories');
  };

  const toggleContactInfoPublish = async () => {
    await dispatch(
      setDirectoryPublished({
        status: !contactInfoPubStatus,
        id: contactInfoId,
        type: 'contactinfo',
      })
    );
  };

  const toggleDrinkingPublish = async () => {
    await dispatch(
      setDirectoryPublished({
        status: !drinkingPubStatus,
        id: drinkingId,
        type: 'drinking',
      })
    );
  };

  const toggleWastePublish = async () => {
    await dispatch(
      setDirectoryPublished({
        status: !wastePubStatus,
        id: wasteId,
        type: 'waste',
      })
    );
  };

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      <AlignWrapper>
        {(!isAdmin && !userHasStarted) && (<PaperStyled elevation={3}>
            <AppBarStyled position='static'>Welcome</AppBarStyled>
            <ShortDescription>
              Welcome to the Water/Wastewater Risk Assessment and Planning Tool (WRAPT).
            </ShortDescription>
            <ButtonsContainer>
              <UserStartActions 
                pwsSearchError={pwsSearchError}
                pwsSearching={isPwsSearching}
                pwsSearchResult={pwsSearchResult}
                searchPwsId={search}
                startNew={startNew} 
                startWithPwsId={startWithPwsId}
                loading={isUserLoading}
              ></UserStartActions>
            </ButtonsContainer>
          </PaperStyled>
        )}

          {(isAdmin || userHasStarted) && (
          <PaperStyled elevation={3}>
            <AppBarStyled position='static'>Contact Information</AppBarStyled>
            <Description>
              Contact Information
            </Description>
            <ButtonsContainer>
              {!isAdmin && (
                <UserContactInfoActions
                  setCurrentBranch={setCurrentBranch}
                  isContactInfoPublished={userContactInfoPubStatus}
                  contactInfoId={userContactInfoId}
                  loading={isUserLoading}
                />
              )}
              {isAdmin && (
                <AdminContactInfoActions
                  setCurrentBranch={setCurrentBranch}
                  toggleContactInfoPublish={toggleContactInfoPublish}
                  isContactInfoPublished={contactInfoPubStatus}
                  contactInfoId={contactInfoId}
                  loading={isAdminLoading}
                />
              )}
            </ButtonsContainer>
          </PaperStyled>)}

          {(isAdmin || userHasStarted) && (
          <PaperStyled elevation={3}>
            <AppBarStyled position='static'>Drinking Water</AppBarStyled>
            <Description>
              This tool was specifically designed for your drinking water utility
              to conduct a risk assessment and develop an emergency response plan.
            </Description>
            <ButtonsContainer>
              {!isAdmin && (
                <UserDrinkinWaterActions
                  isDrinkingPublished={userDrinkingPubStatus}
                  setCurrentBranch={setCurrentBranch}
                  drinkingId={userDrinkingId}
                  loading={isUserLoading}
                />
              )}
              {isAdmin && (
                <AdminDrinkinWaterActions
                  setCurrentBranch={setCurrentBranch}
                  toggleDrinkingPublish={toggleDrinkingPublish}
                  isDrinkingPublished={drinkingPubStatus}
                  drinkingId={drinkingId}
                  loading={isAdminLoading}
                />
              )}
            </ButtonsContainer>
          </PaperStyled>
          )}

          {(isAdmin || userHasStarted) && (
            <PaperStyled elevation={3}>
            <AppBarStyled position='static'>Waste Water</AppBarStyled>
            <Description>
              This tool allows you to conduct a risk assessment and develop an
              emergency response plan just like you would for a drinking water
              system but has been specifically designed for wastewater utilities.
            </Description>
            <ButtonsContainer>
              {!isAdmin && (
                <UserWasteWaterActions
                  setCurrentBranch={setCurrentBranch}
                  isWastePublished={userWastePubStatus}
                  wasteId={userWasteId}
                  loading={isUserLoading}
                />
              )}
              {isAdmin && (
                <AdminWasteWaterActions
                  setCurrentBranch={setCurrentBranch}
                  toggleWastePublish={toggleWastePublish}
                  isWastePublished={wastePubStatus}
                  wasteId={wasteId}
                  loading={isAdminLoading}
                />
              )}
            </ButtonsContainer>
          </PaperStyled>
          )}
      </AlignWrapper>
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} autoHideDuration={30000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <AlertInfo>
          <LastLocationLink color='white' onClick={onLastLocationClick} lastLocation={lastLocation} />
        </AlertInfo>
      </Snackbar>
    </Container>
  );
};
