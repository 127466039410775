import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QuestionsComponent } from './QuestionsComponent';
import { history } from '../../store';
import transformName from '../../helpers/transformName';
import {
  WIZARD_TYPES,
  DIRECTORY_TYPES
} from '../../constants';

export const Questions = () => {
  const directory = useSelector((store) => store.Common.currentDirectoryName);

  const sub_directory = useSelector(
    (store) => store.Common.currentSubDirectoryName
  );

  const currentCategoryId = useSelector(
    (store) => store.Common.currentCategoryId
  );

  const categories = useSelector((store) => {
    return directory ? store.Admin[directory][sub_directory].wizard.categories : [];
  });

  const pullWizardFromObject = (directoryObject, sub_directory_name) => {
    const reducedData = {
      directoryName: directoryObject.displayName,
      subDirectoryName: transformName(sub_directory_name),
      wizard: directoryObject[sub_directory_name].wizard,
      wizardId: directoryObject[sub_directory_name].id
    };

    return reducedData;
  }

  const wizards = useSelector((store) => {
    const {
      waste,
      drinking,
      contactinfo
    } = store.Admin;
    const {
      emergencyresponseplan,
      riskassessment,
      contactinfo: contactinfoWizType
    } = WIZARD_TYPES;
    const {
      waste: wasteType,
      drinking: drinkingType,
      contactinfo: contactinfoDirType
    } = DIRECTORY_TYPES;

    const simpleWizards = [
      pullWizardFromObject(contactinfo, contactinfoWizType, contactinfoDirType),
      pullWizardFromObject(drinking, riskassessment, drinkingType),
      pullWizardFromObject(drinking, emergencyresponseplan, drinkingType),
      pullWizardFromObject(waste, riskassessment, wasteType),
      pullWizardFromObject(waste, emergencyresponseplan, wasteType)

    ];

    return simpleWizards;
  });

  const wizardId = useSelector((store) => {
    return directory ? store.Admin[directory][sub_directory].id : '';
  });

  const wizardType = useSelector((store) => {
    return directory ? store.Admin[directory][sub_directory].type : '';
  });

  const questions = useSelector((store) => {
    return currentCategoryId
      ? store.Admin[directory][sub_directory].wizard.categories.find(
          (c) => c.id === currentCategoryId
        ).questions
      : [];
  });

  useEffect(() => {
    if (!directory) {
      history.push('/home');
    }
  }, [directory]);

  if (!directory) {
    return <></>;
  }

  return (
    <QuestionsComponent
      directory={directory}
      sub_directory={sub_directory}
      categories={categories}
      currentCategoryId={currentCategoryId}
      questions={questions || []}
      wizardType={wizardType}
      wizardId={wizardId}
      wizards={wizards}
    />
  );
};
