import React from 'react';

import { PlaceholderContainer, Placeholder } from './styled';

export const NoCategoryPlaceholder = () => {
  return (
    <PlaceholderContainer>
      <Placeholder>No categories defined yet</Placeholder>
    </PlaceholderContainer>
  );
};
