import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody
} from '@material-ui/core';

import {
  COLUMN_TYPES,
  COLUMN_AGGREGATE_TYPES,
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import TableCellInput from '../../../components/Table/TableCellInput';
import TableMenuInput from '../../../components/Table/TableMenuInput';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    margin: 8,
  },
  tableControlsContainer: {
    display: 'flex'
  }
});

const constructRow = (row, index, onChange) => {
  const validValues = row.values.some(val => !!val.value);

  return (<TableRow key={row.id} style={!validValues ? { border: '1px solid red' } : {}}>
    <TableCell>#{index + 1}</TableCell>
    {row.values.map((cell, cellIndex) => <TableCellInput
      key={`${row.id}-${cellIndex}`}
      cell={cell}
      onChange={(value) => onChange({
        value,
        rowIndex: index,
        valueIndex: cellIndex
      })}
    />)}
  </TableRow>);
};

const reduceValueLength = (value) => {
  const {
    MAX_CHARACTERS_LOWER_LIMIT
  } = INPUT_LIMITS;

  return value.slice(0, MAX_CHARACTERS_LOWER_LIMIT);
};

const fillRowCalcValues = (row, table) => {
    /// Update any calculated columns
    const columnNameToValue = (match) => {

      const columnName = match.substring(1, match.length - 1);

      const columnIndex = table.columns.findIndex((c) => c.name === columnName);
      if (columnIndex > -1) {
        const columnValue = row.values[columnIndex].value
        const parsedColumnValue = Number.parseFloat(columnValue);
        if (Number.isNaN(parsedColumnValue)) {
          return 0;
        } else {
          return `(${columnValue})`;
        }
      }
      return match;
    };

    for (let colIndex = 0; colIndex < table.columns.length; colIndex++) {
      const column = table.columns[colIndex];
      if (column.type !== COLUMN_TYPES.calculated) {
        continue;
      }
      const formulaWithValues = column.formula.replace(/(\[(\w|\s)+\])/g, columnNameToValue)
      try {
        // eslint-disable-next-line no-new-func
        const formulaFunction = new Function('return (' + formulaWithValues + ').toFixed(2);');
        row.values[colIndex].value = formulaFunction();
      } catch (e) {
        console.error(e);
      }
    }
};
const constructTable = (table) => {
  const newTable = { ...table };

  if (!newTable.rows) {
    newTable.rows = [];
  }

  for (const row of newTable.rows) {
    fillRowCalcValues(row, newTable);
  }

  return newTable;
};

export const TableForm = ({
  data,
  callBack,
  onNoteEdit,
  includeNote = true
}) => {
  const classes = useStyles();
  const {
    table,
    answer: { note: noteValue },
  } = data;

  const [hasInitialized, setHasInitialized] = useState(false);
  const [noteOverLimit, setNoteOverLimit] = useState(null);
  const [tableAnswer, setTableAnswer] = useState(constructTable(table));

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  const getAggregateValue = (column, columnIndex) => {
    try {
      switch (column.aggregateType) {
        case COLUMN_AGGREGATE_TYPES.sum:
          return 'Total: ' + tableAnswer.rows.map((r) => r.values[columnIndex].value)
            .filter((value) => !Number.isNaN(Number.parseFloat(value)))
            .map((value) => Number.parseFloat(value))
            .reduce((a, b) => a + b, 0).toFixed(2);
        case COLUMN_AGGREGATE_TYPES.average:
          return 'Average: ' + (tableAnswer.rows.map((r) => r.values[columnIndex].value)
            .filter((value) => !Number.isNaN(Number.parseFloat(value)))
            .map((value) => Number.parseFloat(value))
            .reduce((a, b) => a + b, 0) / tableAnswer.rows.length).toFixed(2);
        default:
          return null;
      }
    } catch {
      return null;
    }
  }

  const constructFooter = (columns) => {
    const hasAggregate = columns.findIndex(c => c.aggregateType !== COLUMN_AGGREGATE_TYPES.none) > -1;
    return hasAggregate ? <TableRow>
      <TableCell></TableCell>
      {columns.map((col, colIndex) => <TableCell align="right" style={{ fontWeight: "bold" }} key={`footer-${colIndex}`}>
        {getAggregateValue(col, colIndex)}
      </TableCell>)}
    </TableRow> : null;
  }

  const constructRowObject = (columns) => {
    return {
      values: columns.map((col, index) => ({ type: col.type, value: null, columnId: index }))
    }
  }

  const addRow = () => {
    const newTable = { ...tableAnswer };

    if (!newTable.rows) {
      newTable.rows = [];
    }

    newTable.rows.push(constructRowObject(newTable.columns));

    setTableAnswer(newTable);
  }

  const deleteRow = (index) => {
    const newTable = { ...tableAnswer };

    newTable.rows.splice(index, 1);

    setTableAnswer(newTable);
  }

  const editRow = ({ value, rowIndex, valueIndex }) => {
    const newTable = { ...tableAnswer };
    const row = newTable.rows[rowIndex];
    const rowValue = row.values[valueIndex];
    let reducedValue = reduceValueLength(value);
    rowValue.value = reducedValue;

    fillRowCalcValues(row, newTable);

    setTableAnswer(newTable);
  }

  useEffect(() => {
    if (hasInitialized) {
      callBack({ table: tableAnswer, id: data.id })
    } else {
      setHasInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableAnswer]);

  useEffect(() => {
    setTableAnswer(data.table);
  }, [data.table]);

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems={'flex-start'}
      spacing={3}
    >
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems={'flex-end'}
        style={{ marginBottom: 10 }}
      >
        <Grid item style={{ width: '90%' }}>
          <div className={classes.tableContainer}>
            <div className={classes.tableControlsContainer}>
              <TableMenuInput
                buttonText="Add Row"
                style={{ margin: '8px', marginLeft: '0px' }}
                action={addRow}
              />
              <TableMenuInput buttonText="Delete Row" showButton={!!tableAnswer.rows && !!tableAnswer.rows.length}
                inputs={
                  !tableAnswer.rows || !tableAnswer.rows.length ?
                    []
                    :
                    tableAnswer.rows.map((row, index) => ({ label: `Row #${index + 1}`, action: () => deleteRow(index) }))}
              />
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    {table.columns.map((column, index) => <TableCell key={index} align={column.type === 'text' ? 'left' : 'right'}>{column.name}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableAnswer.rows && tableAnswer.rows.map((row, index) => constructRow(row, index, editRow))}
                  {tableAnswer.rows && constructFooter(table.columns)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid item style={{ width: '90%' }}>
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            value={noteValue}
            multiline
            style={{ width: '100%' }}
            rows={4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helperText={noteOverLimit || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
