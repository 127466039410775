import React from 'react';

import { QUESTION_TYPES, QUESTION_TYPE_METADATA } from '../../../constants';
import { TextForm } from './TextFormComponent';
import { MultipleChoiceForm } from './MultipleChoiceFormComponent';
import { SingleChoiceForm } from './SingleChoiceFormComponent';
import { DecimalNumberForm } from './DecimalNumberFormComponent';
import { BooleanChoiceForm } from './BooleanChoiceFormComponent';
import { DateForm } from './DateFormComponent';
import { TableForm } from './TableFormComponent';
import { SelectFormComponent } from './SelectFormComponent';
import GroupComponent from './GroupComponent/GroupComponent';
import SingleSelectForm from './SingleSelectFormComponent';
import {
  AnswerPaper,
  IndexTitle,
  FormContainer,
  QuestionTitle,
  QuestionTitleContainer,
  DescriptionTitle,
  DescriptionContainer,
} from './styled';

import AdditionalInfoToolTip from '../../../components/Tooltips/AdditionalInfoToolTip';

export const AnswerComponent = ({
  data,
  offset,
  methods
}) => {
  const {
    SIMPLETEXT,
    LONGTEXT,
    WHOLENUMBER,
    DECIMALNUMBER,
    MULTIPLECHOICE,
    SINGLECHOICE,
    SINGLESELECT,
    PRIORITYLEVEL,
    COMPLETIONDATE,
    YESNO,
    DATE,
    TABLE,
    GROUP
  } = QUESTION_TYPES;
  const {
    id,
    type,
    question,
    description,
    additionalInfo
  } = data;

  return (
    <AnswerPaper>
      <IndexTitle>#{offset + 1}</IndexTitle>
      <FormContainer>
        <QuestionTitleContainer>
          <QuestionTitle>{question}</QuestionTitle>
          {additionalInfo && <AdditionalInfoToolTip info={additionalInfo} />}
        </QuestionTitleContainer>
        {type === GROUP && <GroupComponent key={id} data={data} onEdit={methods.onGroupEdit} />}
        {type !== GROUP && (
          <>
            {description && (
              <DescriptionContainer>
                <DescriptionTitle>{description}</DescriptionTitle>
              </DescriptionContainer>
            )}
            {type === SIMPLETEXT && (
              <TextForm
                data={data}
                callBack={methods.onEdit}
                onNoteEdit={methods.onNoteEdit}
              />
            )}

            {type === LONGTEXT && (
              <TextForm
                data={data}
                callBack={methods.onEdit}
                isMultiline={true}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === WHOLENUMBER && (
              <TextForm
                data={data}
                callBack={methods.onEdit}
                isNumeric={true}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === MULTIPLECHOICE && (
              <MultipleChoiceForm
                data={data}
                callBack={methods.onChoose}
                onNoteEdit={methods.onNoteEdit}
                onOtherOptionsEdit={methods.onOtherOptionsEdit}
              />
            )}
            {type === SINGLECHOICE && (
              <SingleChoiceForm 
                data={data}
                callBack={methods.onChoose}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === SINGLESELECT && (
              <SingleSelectForm 
                data={data}
                callBack={methods.onChoose}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === PRIORITYLEVEL && (
              <SelectFormComponent 
                data={data}
                callBack={methods.onEdit}
                options={QUESTION_TYPE_METADATA.prioritylevel.values}
              />
            )}
            {type === COMPLETIONDATE && (
              <SelectFormComponent 
                data={data}
                callBack={methods.onEdit}
                options={QUESTION_TYPE_METADATA.completiondate.values}
              />
            )}
            {type === YESNO && (
              <BooleanChoiceForm
                data={data}
                callBack={methods.onEdit}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === DATE && (
              <DateForm
                data={data}
                callBack={methods.onEdit}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === DECIMALNUMBER && (
              <DecimalNumberForm
                data={data}
                callBack={methods.onEdit}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
            {type === TABLE && (
              <TableForm 
                data={data}
                callBack={methods.onTableEdit}
                onNoteEdit={methods.onNoteEdit}
              />
            )}
          </>
        )}
      </FormContainer>
    </AnswerPaper>
  );
};
