import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Pagination from 'material-ui-flat-pagination';

const theme = createMuiTheme();

export const PaginationCustom = ({ limit, total, offset, callBack }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      { total === 1 ? <></> : 
      <Pagination
        limit={limit}
        offset={offset}
        total={total}
        onClick={(e, o) => callBack(o)}
      />}
    </MuiThemeProvider>
  );
};
