import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import debounce from 'debounce';

import { makeStyles } from '@material-ui/core';


import { CustomButton } from '../../../../components/Button';
import GroupSection from './GroupSection';
import GroupSectionFromTemplateModal from './GroupSectionFromTemplateModal';
import AdditionalInfoLink from '../../../../components/Links/AdditionalInfoLink';
import { PaginationCustom as Pagination } from '../../../../components/Pagination';
import noop from '../../../../helpers/noop';

const useStyles = makeStyles({
    addSectionButton: {
        marginTop: '10px'
    }
});

const GroupComponent = ({ onEdit, data }) => {
    const [hasInitialized, setHasInitialized] = useState(false);
    const {
        groupOptions: sectionOptions,
        groupQuestionsCopy,
        sectionHeading,
    } = data;
    const [sections, setSections] = useState(data.groupSections || []);

    const [selectedSection, setSelectedSection] = useState(0);

    const debouncedSetSections = debounce(setSections, 600);

    const [localSections, setLocalSections] = useState(data.groupSections || []);
    const [offset, setOffset] = useState(0);

    const onChange = (index, changedSection) => {
        const newSections = _.cloneDeep(localSections);
        newSections[index] = changedSection;

        setLocalSections(newSections);
        debouncedSetSections(newSections);
    };

    const onAdd = (section = null) => {
        const newSection = section || {
            id: uuidv4(),
            questions: _.cloneDeep(groupQuestionsCopy.questions),
            templateData: sectionHeading ? { sectionHeading } : null
        };
        
        const newSections = [...localSections];

        newSections.push(newSection);

        setLocalSections(newSections);
        debouncedSetSections(newSections);
        setSelectedSection(newSections.length - 1);
    };

    const onDelete = (index) => {
        const newSections = [...localSections];

        newSections.splice(index, 1);

        setLocalSections(newSections);
        debouncedSetSections(newSections);
    };

    const onInsertPreviousChanges = (index) => {
        const newSections = _.cloneDeep(localSections);

        const currentSection = newSections[index];
        const previousSection = newSections[index - 1];
        const previousSectionQuestions = previousSection.questions;

        currentSection.questions = currentSection.questions.map((question, index) => {
            const templateData = question.templateData;

            return {
                ...previousSectionQuestions[index],
                templateData
            };
        });

        setLocalSections(newSections);
        debouncedSetSections(newSections);

        return currentSection.questions;
    }

    useEffect(() => {
        if (hasInitialized) {
            onEdit({ data: sections, id: data.id })
        } else {
            setHasInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections]);

    const classes = useStyles();
    return (
        <div style={{ paddingRight: '50px' }}>
            {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} preLinkStyles={{ margin: '0px 8px 20px 0px' }} containerStyles={{ display: 'flex' }} />}
            <div>
            <Pagination
          offset={offset}
          total={Math.ceil(localSections.length / 10)}
          limit={1}
          callBack={setOffset}
        />
                {localSections.slice(offset * 10, (offset * 10) + 10).map((section, index) =>
                    <GroupSection
                        key={"grp" + index + section.id}
                        index={index + (offset * 10)}
                        data={section}
                        sectionOptions={sectionOptions}
                        onEdit={(changedSection) => onChange(index + (offset * 10), changedSection)}
                        onInsertPreviousChanges={index === 0 ? noop : () => onInsertPreviousChanges(index + (offset * 10))}
                        onDelete={onDelete}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                    />
                )}
                {sectionOptions?.allowUserAdd &&
                    <div className={classes.addSectionButton}>
                        {(!groupQuestionsCopy?.templateKeys) && <CustomButton color='primary' onClick={(_) => onAdd()}>{sectionOptions?.userAddLabel || 'Add new section'}</CustomButton>}
                        {(groupQuestionsCopy?.templateKeys) && <GroupSectionFromTemplateModal sectionHeading={sectionHeading} groupQuestionsCopy={groupQuestionsCopy} buttonText={sectionOptions?.userAddLabel || 'Add new section'} onSave={onAdd} />}
                    </div>}

            </div>
        </div>
    );
};

export default GroupComponent;