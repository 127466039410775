import {
  adminGetDirectories,
  adminGetWizard,
  adminUpdateWizard,
  adminPublishDirectory,
  adminUnPublishDirectory,
  adminGetSecurityCodes,
  adminRegisterSecurityCode,
  adminRefreshSecurityCode,
  adminDeleteSecurityCode,
  adminUpdateSecurityCodeName
} from '../../services/identity';

import { v4 as uuidv4 } from 'uuid';

export const setDirectories = () => async (dispatch) => {
  dispatch({ type: 'SET_DIRECTORIES_START' });
  const directories = await adminGetDirectories();
  if (directories) {
    const payload = directories.reduce((acc, item) => {
      acc[item.name] = {
        published: item.isPublished,
        id: item.id,
        name: item.name,
        displayName: item.displayName
      };

      acc[item.name].risk_assessment = item.wizards.find(
        (w) => w.name === 'riskassessment'
      );
      acc[item.name].emergency_response_plan = item.wizards.find(
        (w) => w.name === 'emergencyresponseplan'
      );
      acc[item.name].contactinfo = item.wizards.find(
        (w) => w.name === 'contactinfo'
      );

      return acc;
    }, {});
    dispatch({
      type: 'SET_DIRECTORIES_SUCCESS',
      payload,
    });
  } else {
    dispatch({ type: 'SET_DIRECTORIES_FAIL' });
  }
};

export const getWizard = ({
  directory,
  sub_directory,
  wizard_id,
}) => async (dispatch) => {
  const wizard = await adminGetWizard({ id: wizard_id });

  dispatch({
    type: 'SET_ADMIN_WIZARD',
    payload: {
      directory,
      sub_directory,
      wizard,
    },
  });
};

export const updateWizard = ({
  directory,
  sub_directory,
  wizard
}) => async (dispatch) => {
  await adminUpdateWizard({ wizard });
  dispatch({
    type: 'UPDATE_ADMIN_WIZARD_SUCCESS', payload: {
      directory,
      sub_directory
    }
  })
};

export const addCategoryAdmin = ({
  directory,
  sub_directory,
  existingCategories,
  wizardId,
}) => async (dispatch) => {
  const newCategory = {
    id: uuidv4(),
    name: '',
    orderIndex: existingCategories.length,
    questions: []
  };
  dispatch({
    type: 'ADD_CATEGORY_SUCCESS',
    payload: {
      categories: [...existingCategories, { ...newCategory, active: true }],
      directory,
      sub_directory,
    },
  });
};

export const deleteAdminCategory = ({
  directory,
  sub_directory,
  categories,
  id,
}) => async (dispatch) => {
  dispatch({
    type: 'DELETE_CATEGORY_SUCCESS',
    payload: {
      directory,
      sub_directory,
      categories,
    },
  });
};

export const editAdminCategory = ({
  directory,
  sub_directory,
  id,
  name,
  categories,
}) => async (dispatch) => {
  const updatedCategories = [...categories];
  const index = updatedCategories.findIndex(
    (c) => c.id === id
  );
  const updatedCategory = Object.assign({}, updatedCategories[index]);
  updatedCategory.name = name;
  updatedCategory.active = true;

  updatedCategories[index] = updatedCategory;

  dispatch({
    type: 'EDIT_CATEGORY_SUCCESS',
    payload: {
      directory,
      sub_directory,
      categories: updatedCategories,
    },
  });

};

export const updateCategoryQuestions = ({
  directory,
  sub_directory,
  data: { questions, categoryId, updatedCategory, categories },
}) => async (dispatch) => {
    const index = categories.findIndex((c) => c.id === categoryId);
    const updatedCategories = [...categories];
    updatedCategories[index] = {...updatedCategory, questions};
    dispatch({
      type: 'EDIT_CATEGORIES_SUCCESS',
      payload: {
        directory,
        sub_directory,
        categories: updatedCategories,
      },
    });

};

export const adminUpdateCategoriesOrderCall = ({
  directory,
  sub_directory,
  categories,
}) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_CATEGORIES_ORDER',
    payload: {
      directory,
      sub_directory,
      categories,
    }
  });
};

export const setDirectoryPublished = ({ id, status, type }) => async (
  dispatch
) => {
  status === true
    ? await adminPublishDirectory(id)
    : await adminUnPublishDirectory(id);
  dispatch({
    type: 'TOGGLE_DIRECTORY_PUBLISHED',
    payload: {
      type,
      status,
    },
  });
};

export const adminGetSecurityCodesCall = () => async (dispatch) => {
  const payload = await adminGetSecurityCodes();

  dispatch({
    type: 'GET_SECURITY_CODES',
    payload,
  });
};

export const adminRegisterSecurityCodesCall = () => async (dispatch) => {
  const payload = await adminRegisterSecurityCode();
  dispatch({
    type: 'ADD_NEW_SECURITY_CODES',
    payload,
  });
};

export const adminUpdateSecurityCodeNameCall = ({
  name,
  securityCode,
  updatedCodes,
}) => async (dispatch) => {
  await adminUpdateSecurityCodeName({ name, securityCode });
  dispatch({
    type: 'REFRESH_SECURITY_CODES',
    payload: updatedCodes,
  });
};

export const adminRefreshSecurityCodesCall = ({
  securityCode,
  codes,
}) => async (dispatch) => {
  dispatch({
    type: 'REFRESH_SECURITY_CODE_START',
    payload: securityCode,
  });
  const payload = await adminRefreshSecurityCode({ securityCode });
  if (payload.securityCode) {
    const index = codes.findIndex((c) => c.securityCode === securityCode);
    const updatedCodes = [...codes];
    updatedCodes[index] = payload;

    dispatch({
      type: 'REFRESH_SECURITY_CODE_SUCCESS',
      payload: updatedCodes,
    });
  } else {
    dispatch({
      type: 'REFRESH_SECURITY_CODE_FAIL',
    });
  }
};

export const adminDeleteSecurityCodesCall = ({
  securityCode,
  updatedCodes,
}) => async (dispatch) => {
  await adminDeleteSecurityCode({ securityCode });
  dispatch({
    type: 'DELETE_SECURITY_CODES',
    payload: updatedCodes,
  });
};
