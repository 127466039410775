import React, { useState, useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';


import { TextForm } from '../TextFormComponent';
import { MultipleChoiceForm } from '../MultipleChoiceFormComponent';
import { SingleChoiceForm } from '../SingleChoiceFormComponent';
import { DecimalNumberForm } from '../DecimalNumberFormComponent';
import { BooleanChoiceForm } from '../BooleanChoiceFormComponent';
import { DateForm } from '../DateFormComponent';
import { TableForm } from '../TableFormComponent';
import { SelectFormComponent } from '../SelectFormComponent';
import SingleSelectForm from '../SingleSelectFormComponent';
import debounce from 'debounce';

import {
    GroupQuestionTitle,
    QuestionTitleContainer,
    DescriptionTitle,
    DescriptionContainer,
  } from '../styled';

import { QUESTION_TYPES, ANSWER_TYPES } from '../../../../constants';

const useStyles = makeStyles({
    questionBlockContainer: {
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    questionContainer: {
        marginTop: '10px'
    }
  });

const GroupQuestionCard = ({ data, onEdit }) => {
    const classes = useStyles();
    const {
        LABEL,
        SIMPLETEXT,
        LONGTEXT,
        WHOLENUMBER,
        DECIMALNUMBER,
        MULTIPLECHOICE,
        SINGLECHOICE,
        SINGLESELECT,
        PRIORITYLEVEL,
        COMPLETIONDATE,
        YESNO,
        DATE,
        TABLE
    } = QUESTION_TYPES;
    const nonCompactTypes = [
        TABLE,
        DECIMALNUMBER,
        SINGLECHOICE,
        MULTIPLECHOICE,
        YESNO
    ];

    const [hasInitialized, setHasInitialized] = useState(false);
    const [question, setQuestion] = useState(data);
    const {
        type,
        question: questionText,
        description,
        templateData = {
            description: '',
            question: ''
        }
    } = question;

    const [localData, setLocalData] = useState(data);

        
    const debouncedSetQuestion = debounce(setQuestion, 600);

    const onChange = (answer) => {
        const newQuestion = {...localData};
        const {
            type
        } = newQuestion;

        switch (type) {
            case TABLE:
                newQuestion.table = answer;
                newQuestion.answer.value = ANSWER_TYPES.empty;
                break;
            case MULTIPLECHOICE:
                newQuestion.answer.options = answer;
                break;
            case SINGLECHOICE:
                newQuestion.answer.options = answer;
                break;
            case SINGLESELECT:
                newQuestion.answer.options = answer;
                break;
            default:
                newQuestion.answer.value = answer;
                break;
        }

        setLocalData(newQuestion);
        debouncedSetQuestion(newQuestion);
    };

    const onOtherOptionsChange = (otherOptions) => {
        const newQuestion = {...localData};
        newQuestion.answer.otherOptions = otherOptions;
        setLocalData(newQuestion);
        debouncedSetQuestion(newQuestion);
    };

    useEffect(() => {
        if (hasInitialized) {
            onEdit(question);
        } else {
            setHasInitialized(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question]);

    return (
        <div className={classes.questionBlockContainer}>
            {nonCompactTypes.includes(type) && (
                <div style={{ width: '90%' }} >
                    <QuestionTitleContainer>
                        <GroupQuestionTitle style={{ marginTop: '0px' }}>{templateData.question || questionText}</GroupQuestionTitle>
                    </QuestionTitleContainer>
                    {description && (
                        <DescriptionContainer>
                            <DescriptionTitle>{templateData.description || description}</DescriptionTitle>
                        </DescriptionContainer>
                    )}
                </div>
            )}
            <div className={classes.questionContainer}>
                {type === LABEL && (
                    <Typography variant="h5">{data.question}</Typography>
                )}
                {type === SIMPLETEXT && (
                    <TextForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === LONGTEXT && (
                    <TextForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        isMultiline={true}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === WHOLENUMBER && (
                    <TextForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        isNumeric={true}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === DECIMALNUMBER && (
                    <DecimalNumberForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        includeNote={false}
                    />
                )}
                {type === YESNO && (
                    <BooleanChoiceForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === DATE && (
                    <DateForm
                        data={data}
                        callBack={(generalData) => onChange(generalData.answer)}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === MULTIPLECHOICE && (
                    <MultipleChoiceForm
                        data={data}
                        callBack={(choiceData) => onChange(choiceData.options)}
                        onOtherOptionsEdit={(generalData) => onOtherOptionsChange(generalData.otherOptions) }
                        includeNote={false}
                    />
                )}
                {type === SINGLECHOICE && (
                    <SingleChoiceForm 
                        data={data}
                        callBack={(choiceData) => onChange(choiceData.options)}
                        includeNote={false}
                    />
                )}
                {type === SINGLESELECT && (
                    <SingleSelectForm
                        data={data}
                        callBack={(choiceData) => onChange(choiceData.options)}
                        includeNote={false}
                        isCompact={true}
                    />
                )}
                {type === PRIORITYLEVEL && (
                    <SelectFormComponent 
                        data={data}
                        callBack={(selectData) => onChange(selectData.answer)}
                        options={["Low", "Medium", "High"]}
                        isCompact={true}
                    />
                )}
                {type === COMPLETIONDATE && (
                    <SelectFormComponent 
                        data={data}
                        callBack={(selectData) => onChange(selectData.answer)}
                        options={["Less than 1 month", "Less than 6 months", "Less than 1 year", "When funds available", "Not Applicable"]}
                        isCompact={true}
                    />
                )}
                {type === TABLE && (
                    <TableForm 
                        data={data}
                        callBack={(tableData) => onChange(tableData.table)}
                        includeNote={false}
                    />
                )}
            </div>
        </div>
    );
}

export default GroupQuestionCard;