import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWithHeader, setWithFooter } from '../../store/common/actions';

export const PrivateRoute = ({
  children,
  withHeader = true,
  withFooter = true,
  accessData,
  exact,
  ...rest
}) => {
  const location = rest.location.pathname;
  const dispatch = useDispatch();
  dispatch(setWithHeader(withHeader));
  dispatch(setWithFooter(withFooter));

  return accessData ? (
    <Route exact={exact} {...rest} render={children} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};
