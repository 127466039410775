const getAllIndexes = (array, matchOperator) => {
    const indexes = [];

    array.forEach((ele, index) => {
        const matches = matchOperator(ele);

        if (matches) {
            indexes.push(index);
        }
    });

    return indexes;
};

export default getAllIndexes;