import React, { useState } from 'react';
import { TextField, Grid, InputLabel } from '@material-ui/core';

import {
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

export const TextForm = ({
  data,
  callBack,
  onNoteEdit,
  isMultiline = false,
  isNumeric = false,
  includeNote = true,
  isCompact = false
}) => {
  const {
    description,
    question: questionText,
    templateData = {
      description: '',
      question: ''
    },
    answer: { value, note: noteValue },
  } = data;

  const [noteOverLimit, setNoteOverLimit] = useState(null);
  const [answerOverLimit, setAnswerOverLimit] = useState(null);

  const labelMaxLength = 100;
  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  const handleChange = (value) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setAnswerOverLimit(null);
    const reduceDataLength = () => {
      if (value.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setAnswerOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return value.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };

    let dataToSend = reduceDataLength();
    if (isNumeric) {
      dataToSend = dataToSend.replace(/\D/g, '');
    }
    callBack({ answer: dataToSend, id: data.id });
  };

  const labelText = isCompact ? templateData.question || questionText : isNumeric ? 'Whole number' : 'Your answer';

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems={'flex-start'}
      spacing={3}
    >
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems={isMultiline ? 'flex-start' : 'flex-end'}
        style={{ marginBottom: 10 }}
      >
        {labelText.length > labelMaxLength && 
         <InputLabel style={{marginLeft: "10px", marginBottom:"-7px"}} shrink={true} margin="dense" color="primary">{labelText}</InputLabel>
         }
        <TextField
          id={data.id}
          value={value ?? ''}
          onChange={({ currentTarget: { value } }) => {
            handleChange(value);
          }}
          label={labelText.length > labelMaxLength ? null : labelText}
          style={{ margin: 8, width: '90%' }}
          fullWidth
          margin='normal'
          multiline={isMultiline}
          rows={isMultiline ? '4' : '1'}
          error={answerOverLimit}
          helperText={answerOverLimit || (isCompact ? templateData.description || description : '')}
          variant='outlined'
          InputLabelProps={{shrink: true}}
        />
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid container >
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            InputLabelProps={{shrink: true}}
            value={noteValue}
            multiline
            style={{ margin: 8, width: '90%' }}
            rows={isMultiline ? 3 : 4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helperText={noteOverLimit || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
