import styled from 'styled-components';
import { Paper } from '@material-ui/core';
// import List from '@material-ui/core/List';
// import { CustomButton } from '../../components/Button';
// import MenuItem from '@material-ui/core/MenuItem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 20px;
  padding: 10px 20px 40px 40px;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 1.5em;
`;
export const SecretCodeContainer = styled.div`
  display: flex;
  min-width: 150px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #4caf50;
`;

export const AlignWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 75%;
`;

export const UserContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 500px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;
