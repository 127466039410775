import React from 'react';
import { useDispatch } from 'react-redux';
//helpers
import { MSAL_Login } from '../../../services/identity/Msal_login/msalLogin';
import { history } from '../../../store';
import { login, logout, userLogout } from '../../../store/common/actions';

// components
import {
  Language,
  AccountCircle,
  PeopleAlt,
  PowerSettingsNew,
  Info as InfoIcon,
} from '@material-ui/icons';
import { IconButtonTitle } from '../styled';
import { CustomButton } from '../../../components/Button';
import { toast } from 'react-toastify';
import { Menu, MenuItem } from '@material-ui/core';
import ExternalLink from '../../../components/Links/ExternalLink';

export const Home = ({ visible }) => {
  if (!visible) {
    return <></>;
  }
  return (
    <CustomButton
      style={{ marginRight: 15 }}
      onClick={() => {
        history.push('/');
      }}
      startIcon={<Language style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>home</IconButtonTitle>
    </CustomButton>
  );
};

export const UserManagement = ({ visible }) => {
  if (!visible) {
    return <></>;
  }
  return (
    <CustomButton
      style={{ marginRight: 15 }}
      onClick={() => {
        history.push('/user-management');
      }}
      startIcon={<PeopleAlt style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>
        user management
      </IconButtonTitle>
    </CustomButton>
  );
};

export const AssociationLogin = ({ visible, history }) => {
  const dispatch = useDispatch();

  const onLogin = async (account) => {

    if (account) {
      // Don't actually store the access token in a cookie because the access token expires. The rest of the app checks for the existing of this cookie so we need to put something in there. Using the account id instead.
      await dispatch(login({ accessToken: account.accountIdentifier, userType: 'admin' }));
      history.push('/home');
    } else {
      toast.error('Unable to Login. Please try again');
    }
  };

  if (!visible) {
    return <></>;
  }

  return (
    <CustomButton
      style={{ marginRight: 15 }}
      onClick={async () => {
        const account = await MSAL_Login();
        await onLogin(account);
      }}
      startIcon={<PowerSettingsNew style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>
        Association login
      </IconButtonTitle>
    </CustomButton>
  );
};

export const Logout = ({ visible, isAdmin }) => {
  const dispatch = useDispatch();
  if (!visible) {
    return <></>;
  }
  return (
    <CustomButton
      onClick={async () => {
        if (!isAdmin) {
          await dispatch(userLogout());
        } else {
          await dispatch(logout());
        }
      }}
      startIcon={<AccountCircle style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>logout</IconButtonTitle>
    </CustomButton>
  );
};

export const Info = ({ visible, history }) => {
  if (!visible) {
    return <></>;
  }
  return (
    <CustomButton
      onClick={() => {
        history.push('/contact-information');
      }}
      startIcon={<InfoIcon style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>info</IconButtonTitle>
    </CustomButton>
  );
};

export const EPACertification = ({ visible = true, history }) => {
  if (!visible) {
    return <></>;
  }

  return (
    <CustomButton
      onClick={() => {
        history.push('/epa-certification');
      }}
      startIcon={<InfoIcon style={{ color: '#ffffff' }} />}
      variant='text'
    >
      <IconButtonTitle style={{ color: '#ffffff' }}>EPA Certification</IconButtonTitle>
    </CustomButton>
  );
};

export const Resources = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const resources = [
    {
      link: 'https://www.epa.gov/dwreginfo',
      text: 'EPA’s Drinking Water Requirements for States and Public Water Systems'
    },
    {
      link: 'https://texreg.sos.state.tx.us/public/readtac$ext.ViewTAC?tac_view=4&ti=30&pt=1&ch=217',
      text: 'Texas Administrative Code Chapter 217: Design Criteria for Domestic Wastewater Systems Since 2008'
    },
    {
      link: 'https://texreg.sos.state.tx.us/public/readtac$ext.ViewTAC?tac_view=4&ti=30&pt=1&ch=317&rl=Y',
      text: 'Texas Administrative Code Chapter 317: Design Criteria for Wastewater Systems Older than 2008'
    }
  ];

  const toggle = (value = null) => {
    setAnchorEl(value);
  };

  return (
    <>
      <CustomButton
        onClick={(event) => toggle(event.currentTarget)}
        startIcon={<InfoIcon style={{ color: '#ffffff' }} />}
        variant='text'
      >
        <IconButtonTitle style={{ color: '#ffffff' }}>Resources</IconButtonTitle>
      </CustomButton>
      <Menu
        id="resource-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => toggle()}
      >
        {resources.map(resource => {
          return (
            <MenuItem onClick={() => toggle()} key={resource.link}>
              <ExternalLink 
                text={resource.text}
                link={resource.link}
                style={{color: '#3f51b5', fontWeight: 'bold', fontSize: '20'}}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}