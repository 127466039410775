import Cookies from 'js-cookie';
import { msalInstance } from './Msal_login/msalLogin';
import { tokenRequest } from './Msal_login/msal_config';

export const getUserToken = () => {
  return Cookies.get('accessToken') ? Cookies.get('accessToken') : null;
};

export const getAdminToken = async () => {
  try {
    const isLoggedIn = await msalInstance.getAccount();
    if (isLoggedIn) {
      try {
        const token = await msalInstance.acquireTokenSilent(tokenRequest);
        return token.accessToken;
      } catch (error) {
        console.log('error on get token', error);
        // could also check if err instance of InteractionRequiredAuthError
        // if you can import the class.
        if (error.name === 'InteractionRequiredAuthError') {
          const token = await msalInstance.acquireTokenPopup(tokenRequest);
          return token.accessToken;
        }
      }
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

export const getUserSecretCode = () => {
  return Cookies.get('secretCode') ? Cookies.get('secretCode') : null;
};
