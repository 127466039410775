import React, { useState, useEffect } from 'react';
import DropDown from '../../../components/Inputs/DropDown';
import { ANSWER_TYPES, QUESTION_TYPES } from '../../../constants';


const createWizardOptions = (wizards) => wizards.map(w => ({ label: `${w.directoryName} / ${w.subDirectoryName}`, value: w.wizardId }));

const DefaultOptionsForm = ({
  wizardId,
  wizards,
  categoryId,
  optionsFrom,
  onOptionsFrom,
  error
}) => {
  const wizardOptions = createWizardOptions(wizards);

  const selectedWizardId = optionsFrom?.wizardId || wizardId;
  const selectedCategoryId = optionsFrom?.categoryId || categoryId;
  const selectedQuestionId = optionsFrom?.questionId || ANSWER_TYPES.empty;
  const selectedInnerQuestionId = optionsFrom?.innerQuestionId || ANSWER_TYPES.empty;
  const [currentCategoryOptions, setCurrentCategoryOptions] = useState([]);
  const [currentCategoryQuestions, setCurrentCategoryQuestions] = useState([]);
  const [currentInnerQuestions, setCurrentInnerQuestions] = useState([]);

  useEffect(() => {
    const categories = wizards.find(w => w.wizardId === selectedWizardId).wizard.categories;
    const newCurrentCategory = categories.find(c => c.id === selectedCategoryId);

    setCurrentCategoryOptions(categories.map(c => ({ label: c.name, value: c.id })));
    setCurrentCategoryQuestions(
      !newCurrentCategory ?
        []
        :
        newCurrentCategory.questions.filter(q => q.type === QUESTION_TYPES.GROUP).map(cCQ => ({ value: cCQ.id, label: cCQ.question }))
    );
    if (newCurrentCategory) {
      const newCurrentQuestion = newCurrentCategory.questions.find(q => q.id === selectedQuestionId);
      if (newCurrentQuestion && newCurrentQuestion.questions) {
        setCurrentInnerQuestions(newCurrentQuestion.questions.filter(q => q.type === QUESTION_TYPES.SIMPLETEXT).map(cCQ => ({ value: cCQ.id, label: cCQ.question })));
      } else {
        setCurrentInnerQuestions([]);
      }
    } else {
      setCurrentInnerQuestions([]);
    }


  }, [selectedWizardId, selectedCategoryId, selectedQuestionId, selectedInnerQuestionId, wizards])


  const onChange = (value, key) => {
    const updatedOptionsFrom = {
      wizardId: selectedWizardId,
      categoryId: selectedCategoryId,
      questionId: selectedQuestionId,
      innerQuestionId: selectedInnerQuestionId
    };

    updatedOptionsFrom[key] = value;
    if (key === 'wizardId') {
      updatedOptionsFrom.categoryId = ANSWER_TYPES.empty;
      updatedOptionsFrom.questionId = ANSWER_TYPES.empty;
      updatedOptionsFrom.innerQuestionId = ANSWER_TYPES.empty;
    }
    onOptionsFrom(updatedOptionsFrom);
  };

  return (
    <>
      <div style={error ? { border: '1px solid red' } : {}}>
        <div>
          {error ? <p style={{ color: 'red' }}>{error}</p> : <></>}
        </div>
        <h2>Where do you want the options to come from</h2>
        <DropDown
          includeEmptyOption={false}
          selectedValue={selectedWizardId}
          options={wizardOptions}
          onSelect={(value) => {
            onChange(value, 'wizardId');
          }}
          selectHelperText={"Select the wizard"}
        />
        <DropDown
          selectedValue={selectedCategoryId}
          options={currentCategoryOptions}
          onSelect={(value) => {
            onChange(value, 'categoryId');
          }}
          selectHelperText={"Select the category"}
        />
        <DropDown
          selectedValue={selectedQuestionId}
          options={currentCategoryQuestions}
          onSelect={(value) => {
            onChange(value, 'questionId');
          }}
          selectHelperText={
            selectedCategoryId === categoryId ?
              "Options from previous questions"
              :
              "Options from a question in another category"
          }
        />
        <DropDown
          selectedValue={selectedInnerQuestionId}
          options={
            currentInnerQuestions
          }
          onSelect={(value) => {
            onChange(value, 'innerQuestionId');
          }}
          selectHelperText="Use distinct values from this question"
        />
      </div>
    </>
  );
};

export default DefaultOptionsForm;