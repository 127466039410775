import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import { CardHeader } from './styled';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 6,
    boxSizing: 'border-box',
  },
});

export function OutlinedCard({ children, withHeader, cardActions, ...other }) {
  const classes = useStyles();

  return (
    <Card className={cn(classes.body)} style={other.style} variant='outlined'>
      {children}
    </Card>
  );
}
