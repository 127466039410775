import styled from 'styled-components';
import img from '../../assets/images/bg-water.jpg';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background-image: url(${img});
  background-position: center center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  &::after {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
    background: rgba(101, 47, 142, 0.64);
    background: linear-gradient(
      45deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -moz-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -webkit-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
  }
`;

export const AlignWrapper = styled.div`
  display: flex;
  width: 80%;
  height: 70%;
  align-items: center;
  justify-content: center;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
  height: 65%;
  margin: 20px;
  padding: 25px 0;
  z-index: 1;
  min-height: 280px;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  height: 150px;
`;

export const SubTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  color: #c0c1c2;
`;
