import 'date-fns';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import {
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

export const DateForm = ({ data, callBack, onNoteEdit, 
  includeNote = true, 
  isCompact = false }) => {
  const {
    answer: { value, note: noteValue },
  } = data;

  const handleDateChange = (date) => {
    callBack({ id: data.id, answer: date });
  };

  const [noteOverLimit, setNoteOverLimit] = useState(null);

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3} direction='column'>
        <Grid item>
          <KeyboardDatePicker
          variant="inline"
             inputVariant="outlined"
             InputLabelProps={{shrink: true}}
            margin='normal'
            label={isCompact ? data.question : 'Please choose your date'}
            value={value || null}
            onChange={handleDateChange}
            format='MM/dd/yyyy'
            helperText='mm/dd/yyyy'
          />
        </Grid>
        {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
        {includeNote && (
          <Grid item style={{ width: '85%' }}>
            <TextField
              label={data.moreInfoLabel || 'Note (optional)'}
              variant='outlined'
              style={{ width: '100%' }}
              value={noteValue}
              multiline
              rows={4}
              onChange={({ currentTarget }) => {
                onNoteChange({ id: data.id, note: currentTarget.value });
              }}
              error={noteOverLimit}
              helperText={noteOverLimit || ''}
            />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
