import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ANSWER_TYPES } from '../../constants';

const DropDown = ({
    selectedValue = ANSWER_TYPES.empty,
    emptyOptionLabel = ANSWER_TYPES.empty,
    includeEmptyOption = true,
    selectHelperText,
    options,
    onSelect,
    error = ''
  }) => {
    return (<FormControl error={error} style={{width: '100%'}}>
      <Select
        style={{ margin: 8, marginBottom: 0 }}
        fullWidth
        margin='normal'
        value={selectedValue === null ? ANSWER_TYPES.empty : selectedValue}
        onChange={(event) => onSelect(event.target.value)}
        disabled={!options.length}
        error={error}
      >
        {includeEmptyOption && <MenuItem value={ANSWER_TYPES.empty}>{emptyOptionLabel}</MenuItem>}
        {options.map((option) => {
          return (
            <MenuItem value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {(error || selectHelperText) && <FormHelperText style={{ marginLeft: 8}}>{error || selectHelperText}</FormHelperText>}
    </FormControl>);
  };

export default DropDown;