import React, { useState, useEffect } from 'react';
import { TextField, FormControl, FormControlLabel, Radio, FormLabel, RadioGroup } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { QUESTION_TYPES, QUESTION_TYPE_METADATA } from '../../../../constants';
import SelectMenu from '../../../../components/Button/SelectMenu';

import DefaultsFromModal from './DefaultsFromModal';
import InitialSectionsModal from './InitialSectionsModal';

import QuestionList from './QuestionList';
import TableTemplateMap from './TableTemplateMap';
import AdditionalLinkForm from '../AdditionalLinkForm';
import gateChecker from '../../../../helpers/gateChecker';

const constructQuestionObject = (type, data = null) => {
  const optionsIncluded = data !== null && (type === QUESTION_TYPES.SINGLECHOICE || type === QUESTION_TYPES.MULTIPLECHOICE || type === QUESTION_TYPES.SINGLESELECT);

  let questionObj = {
    id: data?.id || uuidv4(),
    type,
    question: data?.question || QUESTION_TYPE_METADATA[type].defaultQuestion,
    description: data?.description || '',
    waterSystemSourcePath: data?.waterSystemSourcePath,
    defaultFrom: data?.defaultFrom,
    table: data?.table || null,
    options: data?.options || null,
    optionsFromPreviousAnswers: data?.optionsFromPreviousAnswers || false,
    optionsFrom: data?.optionsFrom,
    additionalLink: data?.additionalLink || null,
    displayOptions: optionsIncluded ? data.options.map(o => ({ id: uuidv4(), value: o })) : null,
    isGate: data?.isGate || false,
    isOptional: data?.isOptional || false,
    ignoreGate: data?.ignoreGate || false,
    inGate: data?.inGate || false,
    key: data?.key || null
  };

  if (data === null) {
    switch (type) {
      case QUESTION_TYPES.MULTIPLECHOICE:
        questionObj.options = [];
        questionObj.displayOptions = [];
        break;
      case QUESTION_TYPES.SINGLECHOICE:
        questionObj.options = [];
        questionObj.displayOptions = [];
        break;
      case QUESTION_TYPES.SINGLESELECT:
        questionObj.options = [];
        questionObj.displayOptions = [];
        break;
      case QUESTION_TYPES.TABLE:
        questionObj.table = {
          columns: []
        };
        break;
      default:
        break;
    }
  }

  return questionObj;
};

const pullDefaultFromGroupOptions = (defaultsFrom = [], wizards = []) => {
  const fromGroups = [];

  if (!!defaultsFrom?.length && !!wizards?.length) {
    defaultsFrom.forEach((defaultFrom) => {
      const wizard = wizards.find((w) => w.wizardId === defaultFrom.wizardId);

      if (!!wizard && !!wizard?.wizard?.categories?.length) {
        const category = wizard.wizard.categories.find((c) => c.id === defaultFrom.categoryId);

        if (!!category && !!category?.questions?.length) {
          const question = category.questions.find((q) => q.id === defaultFrom.questionId);

          if (question?.type === QUESTION_TYPES.GROUP) {
            fromGroups.push({ question, defaultFrom });
          }
        }
      }
    });
  };

  return fromGroups;
}

const GroupForm = ({
  onChange,
  questionData,
  formData,
  categoryId,
  wizardId,
  wizards
}) => {
  const validQuestionTypes = Object.values(QUESTION_TYPES).filter(qT => qT !== QUESTION_TYPES.GROUP);
  const [questions, setQuestions] = useState(!!questionData.questions ? questionData.questions.map(qD => constructQuestionObject(qD.type, qD)) : []);

  const groupOptions = questionData.groupOptions ? questionData.groupOptions : {
    isRepeatable: false,
    allowUserAdd: false,
    userAddLabel: "Add New",
    defaultsFrom: null,
    initialSections: null
  };

  const [defaultFromGroupOptions, setDefaultFromGroupOptions] = useState(pullDefaultFromGroupOptions(groupOptions.defaultsFrom, wizards));

  const addQuestion = (type) => {
    const newQuestionObj = constructQuestionObject(type);
    const newQuestions = [...questions];

    newQuestions.push(newQuestionObj);
    if (groupOptions.initialSections && groupOptions.initialSections.length > 0) {
      const newGroupOptions = { ...groupOptions };
      newGroupOptions.initialSections = groupOptions.initialSections.map((s) => 
                ({answers: [...s.answers,  {answer: '', waterSystemSourcePath: ''}]}));
      onChange(newGroupOptions, 'groupOptions');
    }

    const checkedQuestions = gateChecker(newQuestions);

    setQuestions(checkedQuestions);
  };

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    if (groupOptions.initialSections && groupOptions.initialSections.length > 0) {
      const newGroupOptions = { ...groupOptions };
      newGroupOptions.initialSections = groupOptions.initialSections.map((s) => {
        const newAnswers = [...s.answers];
        newAnswers.splice(index,1);
        return {answers: newAnswers};
      });
      onChange(newGroupOptions, 'groupOptions');
    }
    newQuestions.splice(index, 1);

    const checkedQuestions = gateChecker(newQuestions);

    setQuestions(checkedQuestions);
  };

  const editQuestion = (newQuestion, index) => {
    const newQuestions = [...questions];

    newQuestions[index] = newQuestion;

    const checkedQuestions = gateChecker(newQuestions);

    setQuestions(checkedQuestions);
  }

  const handleGroupOptionsChange = (value, key) => {
    const newGroupOptions = { ...groupOptions };
    newGroupOptions[key] = value;

    if (key === 'defaultsFrom') setDefaultFromGroupOptions(pullDefaultFromGroupOptions(value, wizards));
    if (key === 'isRepeatable' && !value) newGroupOptions.defaultsFrom = null;

    onChange(newGroupOptions, 'groupOptions');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(questions, 'questions'), [questions]);

  return (
    <div>
      <TextField
        value={questionData.question}
        onChange={({ currentTarget: { value } }) => {
          onChange(value, 'question');
        }}
        error={formData.questionError}
        helperText={formData.questionError || 'Type your question group name here'}
        disabled={!formData.active}
        label='Group Name'
        style={{ margin: 8, marginLeft: 0 }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant='outlined'
        margin='dense'
      />
      {/* TODO: Uncomment when we start using tags */}
      {/* <div style={{ marginBottom: '20px' }}>
        <DropDown
          options={QUESTION_TAGS}
          selectedValue={questionData.tag}
          onSelect={(value) => {
            onChange(value, 'tag');
          }}
          selectHelperText="Question Tag (optional)"
        />
      </div> */}
      <TextField
        value={questionData.waterSystemSourcePath}
        onChange={({ currentTarget: { value } }) => {
          onChange(value, 'waterSystemSourcePath');
        }}
        label='Water System Source Path (optional)'
        style={{ marginBottom: '20px' }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        margin='dense'
        disabled={!formData.active}
      />
      <AdditionalLinkForm
        linkStyles={{margin: '20px 20px 20px 0px'}}
        textStyles={{margin: '20px 0px 20px 8px'}}
        disabled={!formData.active} additionalLink={questionData.additionalLink} onChange={(value) => onChange(value, 'additionalLink')} />
      <FormControl component="fieldset">
        <FormLabel component="legend">Can this group be repeated?</FormLabel>
        <RadioGroup row aria-label="isRepeatable" name="isRepeatable" value={groupOptions.isRepeatable} onChange={({ currentTarget: { value } }) => {
          handleGroupOptionsChange(value === 'true', 'isRepeatable');
        }}>
          <FormControlLabel value={true} control={<Radio color='primary' />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color='primary' />} label="No" />
        </RadioGroup>
      </FormControl>
      {groupOptions.isRepeatable && (
        <div style={{ marginLeft: 35 }} >
          <FormControl component="fieldset">
            <FormLabel component="legend">Can the user add rows to this group?</FormLabel>
            <RadioGroup row aria-label="allowUserAdd" name="allowUserAdd" value={groupOptions.allowUserAdd} onChange={({ currentTarget: { value } }) => {
              handleGroupOptionsChange(value === 'true', 'allowUserAdd');
            }}>
              <FormControlLabel value={true} control={<Radio color='primary' />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color='primary' />} label="No" />
            </RadioGroup>
          </FormControl>
          {groupOptions.allowUserAdd && <TextField
            value={groupOptions.userAddLabel}
            onChange={({ currentTarget: { value } }) => {
              handleGroupOptionsChange(value, 'userAddLabel');
            }}
            helperText={formData.questionError || 'Type the text that will be displayed on the Add button'}
            disabled={!formData.active}
            label='Add Button Label'
            style={{ margin: 8, marginLeft: 0 }}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 25
            }}
            variant='outlined'
            margin='dense'
          />}
        </div>
      )}
      <div style={{ display: 'flex' }}>
        <SelectMenu
          style={{ margin: '8px', marginLeft: '0px' }}
          buttonText="Add Question"
          options={validQuestionTypes.map((vQT) => ({ icon: QUESTION_TYPE_METADATA[vQT].icon, label: QUESTION_TYPE_METADATA[vQT].label, action: () => addQuestion(vQT) }))}
        />
        {groupOptions.isRepeatable && (
          <DefaultsFromModal
            type={questionData.type}
            categoryId={categoryId}
            wizardId={wizardId}
            wizards={wizards}
            defaultsFrom={groupOptions.defaultsFrom}
            localOptions={formData.localOptions}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            margin='dense'
            onChange={(value) => handleGroupOptionsChange(value, 'defaultsFrom')}
          />
        )}
        {groupOptions.isRepeatable && (
          <InitialSectionsModal
            questions={questions}
            initialSections={groupOptions.initialSections}
            onChange={(value) => handleGroupOptionsChange(value, 'initialSections')}
          />
        )}
      </div>
      {!!groupOptions.defaultsFrom?.length && <TableTemplateMap defaultsFrom={groupOptions.defaultsFrom} localOptions={formData.localOptions} wizards={wizards} />}
      {!!groupOptions.isRepeatable && (
        <TextField
          value={questionData.sectionHeading}
          onChange={({ currentTarget: { value } }) => {
            onChange(value, 'sectionHeading');
          }}
          helperText={'Type your section heading here (optional). Use the format {1} to insert answer to questions in the section heading.'}
          disabled={!formData.active}
          label='Section Heading'
          style={{ margin: 8, marginTop: 30, marginLeft: 0 }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant='outlined'
          margin='dense'
        />
      )}
      {!questions.length && formData.submitted && (
        <div>
          <p style={{ color: 'red' }}>You need to add questions</p>
        </div>
      )}
      <QuestionList
        categoryId={categoryId}
        wizardId={wizardId}
        wizards={wizards}
        formData={formData}
        showWaterSystemSourcePath={!!questionData.waterSystemSourcePath}
        isRepeatable={groupOptions.isRepeatable}
        onEdit={editQuestion}
        onDelete={deleteQuestion}
        questions={questions}
        defaultFromGroupOptions={defaultFromGroupOptions}
      />
    </div>
  );
};

export default GroupForm;