import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { init } from './common/thunks';
import { Common } from './common/reducer';
import { Admin } from './admin/reducers';
import { User } from './user/reducers';
import { updateWizard } from './admin/actions';
import debounce from 'debounce';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  Common,
  Admin,
  User,
  router: connectRouter(history),
});


const autoSaveActions = [
  "ADD_CATEGORY_SUCCESS",
  "DELETE_CATEGORY_SUCCESS",
  "EDIT_CATEGORIES_SUCCESS",
  "EDIT_CATEGORY_SUCCESS",
  "UPDATE_CATEGORIES_ORDER"
];

const flushSaveActions = [
  "SET_BRANCH_DATA"
];

/**
 * Schedules actions with { meta: { delay: N } } to be delayed by N milliseconds.
 * Makes `dispatch` return a function to cancel the timeout in this case.
 */
const autoSaveMiddleware = store => next => action => {
  if (flushSaveActions.indexOf(action.type) > -1) {
    debouncedSaveAdminWizard.flush();
  }

  let result = next(action)
  if (autoSaveActions.indexOf(action.type) > -1) {
    const currentState = store.getState();

    const directory = currentState.Common.currentDirectoryName
    const sub_directory = currentState.Common.currentSubDirectoryName;
    if (directory && sub_directory) {
      const currentSubDirectory = currentState.Admin[directory][sub_directory];
      if (currentSubDirectory.wizard) {
        debouncedSaveAdminWizard(directory, sub_directory, currentSubDirectory.wizard);
      }
    }
  }

  return result
}

const saveAdminWizard = async (directory, sub_directory, wizard) => {
  await store.dispatch(updateWizard({
    directory,
    sub_directory,
    wizard
  }));
}

const debouncedSaveAdminWizard = debounce(saveAdminWizard, 3000);

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, autoSaveMiddleware, routerMiddleware(history)))
);


store.dispatch(init());

// If the window is closing, make sure any pending saves are pushed
window.addEventListener('beforeunload', () => {
  debouncedSaveAdminWizard.flush();
});
