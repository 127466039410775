import React from 'react';
import Link from '@material-ui/core/Link';

const LastLocationLink = ({
    visable = true,
    lastLocation,
    onClick,
    color = '#3f51b5'
}) => {
    return (
    !!lastLocation && visable &&
        <Link style={{ margin: '0 20px', marginBottom: '20px', color, cursor: 'pointer' }} onClick={() => onClick({
            currentWizardId: lastLocation.wizardId,
            currentCategoryId: lastLocation.categoryId,
            currentCategoryName: lastLocation.categoryName,
            currentQuestionId: lastLocation.questionId
        })}>
            Would you like to pick up where you last left off?
        </Link>
    );
};

export default LastLocationLink;