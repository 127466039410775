/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { USER_TYPES } from '../../constants';
import { Login } from '../Login';
import { PrivateRoute } from './PrivateRoute';
import { CustomRoute } from './CustomRoute';
import { Questions } from '../Questions';
import { Answers } from '../Answers';
import { Home } from '../Home';
import { UserManagement } from '../UserManagement';
import { UserCategories, AdminCategories } from '../Categories';
import { NotFound } from '../NotFound';
import EPACertification from '../EPACertification';

export const Router = () => {
  const userType = useSelector((store) => store.Common.userType);
  const accessToken = useSelector((store) => store.Common.accessToken);

  return (
    <Switch>
      <CustomRoute path='/login' component={Login} withHeader={true} />
      <CustomRoute exact path='/' component={Login} withHeader={true} />
      <CustomRoute path='/epa-certification' component={EPACertification} withHeader={true} withFooter={true} />
      <PrivateRoute
        path='/home'
        accessData={!!accessToken}
        component={Home}
        withFooter={true}
        withHeader={true}
      />
      <PrivateRoute
        path='/user-management'
        accessData={!!accessToken && userType === USER_TYPES.ADMIN}
        component={UserManagement}
        withFooter={true}
        withHeader={true}
      />
      <PrivateRoute
        exact
        path='/questions'
        accessData={!!accessToken}
        component={Questions}
        withFooter={true}
      />
      <PrivateRoute
        exact
        path='/categories'
        accessData={!!accessToken}
        component={userType === USER_TYPES.ADMIN ? AdminCategories : UserCategories}
        withFooter={true}
      />
      <PrivateRoute
        path='/answers'
        accessData={!!accessToken}
        component={Answers}
        withFooter={true}
      />
      <CustomRoute
        path='*'
        component={NotFound}
        withFooter={false}
        withHeader={false}
      />
    </Switch>
  );
};
