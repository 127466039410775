import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PreloaderContainer } from './styled';

export const Preloader = ({ size = 25, color, value }) => {
  return (
    <PreloaderContainer>
      <CircularProgress size={size} value={value} />
    </PreloaderContainer>
  );
};
