import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Radio, TextField, FormControlLabel, Grid } from '@material-ui/core';

import {
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

export const SingleChoiceForm = ({
  data,
  callBack,
  onNoteEdit,
  includeNote = true
}) => {
  const {
    id,
    answer: { options: answerOptions, note: noteValue },
  } = data;

  const [noteOverLimit, setNoteOverLimit] = useState(null);

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  const handleChange = ({ option, value }) => {
    const index = answerOptions.findIndex((o) => o.option === option);
    const updatesOptions = [...answerOptions].map((o) => ({ option: o.option, value: false }));
    updatesOptions[index] = { option, value };
    callBack({
      options: updatesOptions,
      id,
    });
  };

  return (
    <Grid container direction='column' spacing={3}>
      <Grid 
        container 
        direction='column'
        style={{ margin: 8, marginLeft: 12, marginBottom: 10 }}
      >
        {answerOptions.map((o) => {
          return (
            <FormControlLabel
              key={o?.id || uuidv4()}
              value={o.option}
              control={<Radio checked={o.value} color='primary' />}
              label={o.option}
              labelPlacement='end'
              onChange={() => {
                handleChange({ option: o.option, value: !o.value });
              }}
            />
          );
        })}
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid item style={{ width: '90%' }}>
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            value={noteValue}
            multiline
            style={{ width: '100%' }}
            rows={4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helperText={noteOverLimit || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
