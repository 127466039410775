import React from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    makeStyles
} from '@material-ui/core';
import _ from 'lodash';
import AdditionalInfoToolTip from '../../../../components/Tooltips/AdditionalInfoToolTip';
import { QUESTION_TYPES, QUESTION_TYPE_METADATA } from '../../../../constants';

const useStyles = makeStyles({
    tableContainer: {
        maxWidth: '710px',
        marginTop: '30px',
        display: 'flex',
        alignItems: 'flex-start'
    }
});

const constructKeyValueTable = (defaultsFrom, localOptions = [], wizards = []) => {
    const allColumns = [];

    defaultsFrom.forEach((defaultFrom, defaultsFromIndex) => {
        const localQuestion = localOptions.find((lO) => lO.id === defaultFrom.questionId);

        let selectedQuestion = null;
    
        if (localQuestion) {
            selectedQuestion = localQuestion;
        } else {
            const wizard = wizards.find((wiz) => wiz.wizardId === defaultFrom.wizardId);
            const category = wizard.wizard.categories.find((cat) => cat.id === defaultFrom.categoryId);
            const question = category.questions.find((que) => que.id === defaultFrom.questionId);
    
            selectedQuestion = question;
        }
    
        if (!selectedQuestion) {
            console.error('We were unable to find the question');
            return <></>;
        }

        const type = selectedQuestion.type;

        const reducer = (accumulator, currentValue) => accumulator + currentValue.length;
        const startIndexModifier = defaultsFromIndex === 0 ? 
                1 
            :
                _.cloneDeep(allColumns).splice(0, defaultsFromIndex).reduce(reducer, 1);
        let columns = [];
    
        switch (type) {
            case QUESTION_TYPES.TABLE:
                columns = selectedQuestion.table.columns.map((col, index) => ({ value: col.name, key: `[${index + startIndexModifier}]` }));
              break;
            case QUESTION_TYPES.GROUP:
                const validQuestionTypes = selectedQuestion.questions.filter((que) => QUESTION_TYPE_METADATA.group.allowedTemplateFillers.includes(que.type));
                columns = validQuestionTypes.map((que, index) => ({ value: que.question, key: `[${index + startIndexModifier}]` }));
              break;
            case QUESTION_TYPES.MULTIPLECHOICE:
            case QUESTION_TYPES.SINGLECHOICE:
            case QUESTION_TYPES.SINGLESELECT:
                columns.push({ value: selectedQuestion.question, key: `[${startIndexModifier}]` });
              break;
            default:
                console.log('Table Template Map: we don\'t handle that type yet');
              break;
        };

        allColumns.push(columns);
    });

    const constructHeadCell = (col) => (<TableCell>{col.value}</TableCell>);
    const constructBodyCell = (col) => (<TableCell>{col.key}</TableCell>);

    return (
        <TableContainer component={Paper}>
            {allColumns.map(columns => {
                return (
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map(constructHeadCell)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {columns.map(constructBodyCell)}
                            </TableRow>
                        </TableBody>
                    </Table>
                );
            })}
        </TableContainer>
    );
}

const TableTemplateMap = ({ defaultsFrom, localOptions = [], wizards = [] }) => {  
    const classes = useStyles();

    return (<div className={classes.tableContainer}>
        {constructKeyValueTable(defaultsFrom, localOptions, wizards)}
        <AdditionalInfoToolTip info="The template keys can be inserted into the questions and when rendered on the user side will be replaced with the values in the specified column" />
    </div>);
};

export default TableTemplateMap;