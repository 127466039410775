import styled from 'styled-components';
import img from '../../assets/images/bg-water.jpg';
import AppBar from '@material-ui/core/AppBar';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
  background-image: url(${img});
  background-position: center center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  &::after {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
    background: rgba(101, 47, 142, 0.64);
    background: linear-gradient(
      45deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -moz-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
    background: -webkit-linear-gradient(
      135deg,
      rgba(64, 141, 228, 0.88) 0%,
      rgba(10, 88, 176, 0.45) 100%
    );
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  z-index: 1;
`;

export const CardContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 0 20px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const FormSwitcherContainer = styled.div`
  display: flex;
  width: 80%;
  height: 60px;
  flex-direction: column;
  padding: 35px 0px 10px 0px;
  align-items: center;
  justify-content: center;
`;

export const AppBarStyled = styled(AppBar)`
  position: absolute;
  display: flex;
  width: 95% !important;
  height: 65px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: -60px;
  font-weight: 700;
  font-family: 'Roboto Slab', 'Times New Roman', serif;
`;

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  place-self: flex-start;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 20px;
  padding: 25px 0;
  z-index: 1;
  min-height: 180px;
`;
