import { trim } from 'lodash';
import { QUESTION_TYPES, COLUMN_AGGREGATE_TYPES, COLUMN_TYPES } from '../../constants';
import gateChecker from '../../helpers/gateChecker';
import { constructQuestionAnswer } from '../Answers/Forms/helpers';

const baseXCoordinate = 5;
const baseYCoordinate = 10;
const tabSizes = {
  start: baseXCoordinate,
  small: 5,
  medium: 8,
  large: 12,
  gargantuan: 16
};
const lineSizes = {
  start: baseYCoordinate,
  small: 7,
  medium: 10,
  large: 15,
  gargantuan: 20
};
const padding = {
  right: 40,
  left: 30,
  bottom: 10
};
const fontSizes = {
  title: 30,
  subTitle: 12,
  normal: 8
};
const textStyles = {
  bold: {
    title: {
      fontSize: fontSizes.title,
      color: '#3D9BBF',
      fontFamily: 'helvetica',
      textAlign: 'center',
      padding: {
        right: -40,
        left: -40
      }
    },
    subTitle: {
      fontSize: fontSizes.subTitle,
      color: 'black',
      fontFamily: 'helvetica',
      padding
    },
    bannerTitle: {
      fontSize: fontSizes.subTitle,
      color: 'white',
      fontFamily: 'helvetica',
      padding
    },
    default: {
      fontSize: fontSizes.normal,
      color: 'black',
      fontFamily: 'helvetica',
      padding
    }
  },
  normal: {
    red: {
      fontSize: fontSizes.normal,
      color: 'red',
      fontFamily: 'helvetica',
      padding
    },
    default: {
      fontSize: fontSizes.normal,
      color: 'black',
      fontFamily: 'helvetica',
      padding: (xCoordinate) => {
        return {
          right: 20 - xCoordinate,
          left: 15 - xCoordinate
        };
      }
    }
  }
}
const bannerStyles = {
  color: '#3D9BBF',
  height: 10
};
const contactTitle = 'CONTACT INFO';

const constructTitle = ({ directory, subDirectory }) => {
  let dir = '';
  let sub_dir = '';

  if (directory) {
    dir = directory.replace(/[`~!@#$%^&*()_|+\-=?;:'",/.<>]/gi, ' ');
    dir = dir.charAt(0).toUpperCase() + dir.slice(1);
  }

  if (subDirectory) {
    sub_dir = subDirectory.split('_').reduce((acc, item) => {
      acc = `${acc} ${item.charAt(0).toUpperCase() + item.slice(1)}`;
      return acc;
    }, '');
    sub_dir = sub_dir.replace(/[`~!@#$%^&*()_|+\-=?;:'",/.<>]/gi, ' ');
  }

  return `${dir} Water ${sub_dir ? '- ' + sub_dir : ''}`.toUpperCase();
};

const mapGroupQuestionFromData = (defaultFrom, wizards) => {
  const wizard = wizards.find((wiz) => wiz.wizardId === defaultFrom.wizardId);

  if (!wizard.wizardAnswers) {
    console.log('Unable to find the wizard or its\' wizard answers');
    return null;
  }

  const category = wizard.wizardAnswers.categories.find((cat) => cat.categoryId === defaultFrom.categoryId);

  if (!category) {
    console.log('Unable to find the category to pull the answer from');
    return null;
  }

  const question = category.questions.find((que) => que.questionId === defaultFrom.questionId);

  if (!question) {
    console.log('The question no longer exists or we are unable to find it');
    return null;
  }

  switch (question.type) {
    case QUESTION_TYPES.TABLE:
      return { type: question.type, data: question.table, filter: defaultFrom?.filter, isLocal: false };
    case QUESTION_TYPES.GROUP:
      return { type: question.type, data: question.groupSections, filter: defaultFrom?.filter, isLocal: false };
    case QUESTION_TYPES.MULTIPLECHOICE:
      return { type: question.type, meta: { question: question.question }, data: question.answer.options, filter: defaultFrom?.filter, isLocal: false };
    default:
      console.log('mapGroupQuestionFromData: does not handle that type', question.type);
      return null;
  }
};
const mapQuestionDefaultFromData = (question, categories, defaultFrom) => {
  const category = categories.find((cat) => cat.categoryId === defaultFrom.categoryId);
  try {
    const fromQuestion = category.questions.find((que) => que.questionId === defaultFrom.questionId);

    return {
      ...question,
      answer: {
        ...question.answer,
        value: fromQuestion?.answer?.value || null
      }
    };
  } catch (e) {
    console.log(e);
  }
};
const mapQuestionsDefaultData = (questions, wizards) => {
  const newQuestions = questions.map(question => {
    if (question.type === QUESTION_TYPES.GROUP) {
      if (!question?.groupOptions?.defaultsFrom) {
        return question;
      } else {
        const groupFromData = question.groupOptions.defaultsFrom.map((defaultFrom) => mapGroupQuestionFromData(defaultFrom, wizards));

        if (groupFromData.find(gFD => gFD === null)) {
          return question
        } else {
          const filledQuestion = {
            ...question,
            groupFromData
          };

          return constructQuestionAnswer(filledQuestion);
        }
      }
    } else {
      if (!question.defaultFrom || !!question.answer.value) {
        return question;
      } else {
        const wizard = wizards.find((wiz) => wiz.wizardId === question.defaultFrom.wizardId);

        return !!wizard?.wizardAnswers ? mapQuestionDefaultFromData(question, wizard.wizardAnswers.categories, question.defaultFrom) : question;
      }
    }
  });

  return newQuestions;
};

const drawText = async (doc, { text, coordinates, methods, callback, textStyle = textStyles.normal.default, availableSpaceModifier = 0 }) => {
  const textPadding = typeof textStyle.padding === 'function' ?
    textStyle.padding(coordinates.x)
    : textStyle.padding;
  const pageWidth = doc.internal.pageSize.width;
  const lMargin = typeof textStyle.padding === 'function' ?
    textPadding.left
    : textPadding.left + coordinates.x;
  const rMargin = textPadding.right;
  const availableSpace = ((pageWidth - lMargin) - rMargin) + availableSpaceModifier;
  const splitText = doc.splitTextToSize(text, availableSpace)
    .map(sT => {
      const newLinesMatch = new RegExp(/\r?\n|\r/g);

      if (!trim(sT)) {
        return sT;
      }

      return trim(sT.replace(newLinesMatch, ' '));
    });

  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;

    if (callback) callback(x, y);
  }

  for (let i = 0; i < splitText.length; i++) {
    const textItem = splitText[i];
    const lastIndex = i + 1 === splitText.length;
    let lineSize = lineSizes.small;

    if (splitText.length > 1) {
      lineSize = (textStyle.fontSize / 2.5) + (lastIndex ? 3 : 0);
    }

    doc.setFontSize(textStyle.fontSize);
    doc.setFont(textStyle.fontFamily);
    doc.setTextColor(textStyle.color);

    doc.text(localCurrentX, localCurrentY, textItem, { align: textStyle?.textAlign || 'left' });
    await methods.nextLine(setLocalValue, lineSize, lastIndex ? tabSizes.start : localCurrentX);
  }
};
const drawBanner = async (doc, { text, coordinates, methods, callback }) => {
  const pageWidth = doc.internal.pageSize.width - 12;

  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;

    if (callback) callback(x, y);
  }

  await methods.nextLine(setLocalValue, lineSizes.small);
  await methods.tab(setLocalValue, tabSizes.medium);

  await doc.setFillColor(bannerStyles.color);
  await doc.rect(localCurrentX - tabSizes.medium + 3, localCurrentY - 6, pageWidth - 6, bannerStyles.height, 'F');

  await drawText(doc, {
    text,
    coordinates: { x: localCurrentX, y: localCurrentY },
    methods,
    callback: setLocalValue,
    textStyle: textStyles.bold.bannerTitle
  });
  await methods.nextLine(setLocalValue, lineSizes.small);
};
const drawTable = async (doc, { table, coordinates, methods, callback }) => {
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentY = y;

    if (callback) callback(x, y);
  }

  const constructRowCells = (row, columns) => {
    const rowCells = [];

    row.values.forEach((rV, index) => {
      const column = columns[index];
      const halignRight = column.type === COLUMN_TYPES.number || column.type === COLUMN_TYPES.calculated;

      const rowCell = {
        content: rV?.value || '',
        styles: {
          halign: halignRight ? 'right' : 'left'
        }
      };

      rowCells.push(rowCell);
    });

    return rowCells;
  }
  const constructFootCell = (column, columnIndex) => {
    try {
      const footCell = {
        content: '',
        styles: {
          halign: 'right'
        }
      };

      switch (column.aggregateType) {
        case COLUMN_AGGREGATE_TYPES.sum:
          footCell.content = 'Total: ' + table.rows.map((r) => r.values[columnIndex].value)
            .filter((value) => !Number.isNaN(Number.parseFloat(value)))
            .map((value) => Number.parseFloat(value))
            .reduce((a, b) => a + b, 0).toFixed(2);
          break;
        case COLUMN_AGGREGATE_TYPES.average:
          footCell.content = 'Average: ' + (table.rows.map((r) => r.values[columnIndex].value)
            .filter((value) => !Number.isNaN(Number.parseFloat(value)))
            .map((value) => Number.parseFloat(value))
            .reduce((a, b) => a + b, 0) / table.rows.length).toFixed(2);
          break;
        default:
          break;
      }

      return footCell;
    } catch {
      return {
        content: '',
        styles: {
          halign: 'right'
        }
      };
    }
  }

  const autoTableData = {
    startY: localCurrentY,
    showHead: 'firstPage',
    head: [table.columns.map(col => col.name)],
    body: table?.rows ?
      table.rows
        .map((row) => constructRowCells(row, table.columns))
      : [],
    foot: table?.rows && (table.columns.findIndex(c => c.aggregateType !== COLUMN_AGGREGATE_TYPES.none) > -1)
      ? [table.columns.map(constructFootCell)] : [],
    headStyle: {
      fillColor: '#73A5C6'
    },
    footStyles: {
      fillColor: '#91BAD6'
    },
    didParseCell: (hookData) => {
      const cell = hookData.cell;
      const section = hookData.section;

      if (section === 'head') {
        const columnIndex = table.columns.findIndex(col => col.name === cell.raw);
        const column = table.columns[columnIndex];
        const halignRight = column.type === COLUMN_TYPES.number || column.type === COLUMN_TYPES.calculated;

        cell.styles.halign = halignRight ? 'right' : 'left';
      }
    }
  };

  await doc.autoTable(autoTableData);
  await methods.setCoordinates(baseXCoordinate, doc.lastAutoTable.finalY + lineSizes.medium, setLocalValue);
};
const drawContactInfoBlock = async (doc, { contactInfoItem, coordinates, methods }) => {
  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;
  }

  await methods.tab(setLocalValue);
  await drawText(doc, {
    text: contactInfoItem.title,
    coordinates: { x: localCurrentX, y: localCurrentY },
    methods,
    callback: setLocalValue,
    textStyle: textStyles.bold.subTitle
  });

  for (let i = 0; i < contactInfoItem.items.length; i++) {
    const item = contactInfoItem.items[i];
    const text = `${item.question}: ${item.answer?.value || ''}`

    await methods.tab(setLocalValue, tabSizes.medium);
    await drawText(doc, {
      text,
      coordinates: { x: localCurrentX, y: localCurrentY },
      methods,
      callback: setLocalValue
    });
  }
};
const drawQuestionBlock = async (doc, { question, index, coordinates, methods, callback, baseTabSpace = tabSizes.small, includeQuestionPrefix = true }) => {
  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;

    if (callback) callback(x, y);
  }

  let questionText = '';
  let description = '';

  if (!!question.templateData) {
    questionText = question.templateData.question;
    description = question.templateData.description;
  } else {
    questionText = question.question ? question.question.replace(/[\u0250-\ue007]/g, '') : '';
    description = question.description ? question.description?.replace(/[\u0250-\ue007]/g, '') : '';
  }

  const answer = question.answer;
  const table = question.table;

  const value = answer?.value ? answer.value.replace(/[\u0250-\ue007]/g, '') : '';
  const note = answer?.note ? answer.note.replace(/[\u0250-\ue007]/g, '') : '';
  const options = answer?.options?.length ? answer?.options : [];
  const otherOptions = answer?.otherOptions ? answer.otherOptions.replace(/[\u0250-\ue007]/g, '') : '';

  switch (question?.type) {
    case QUESTION_TYPES.TABLE:
      await methods.tab(setLocalValue, baseTabSpace + tabSizes.small);
      await drawText(doc, {
        text: `${includeQuestionPrefix ? `Question ${index + 1}: ` : ''}${questionText}`,
        coordinates: { x: localCurrentX, y: localCurrentY },
        methods,
        callback: setLocalValue,
        textStyle: textStyles.bold.subTitle
      });

      if (!!description) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Description: ${description}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
        });
      }


      if (!!note) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Note: ${note}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
        });
      }
      await drawTable(doc, {
        table,
        methods,
        coordinates: { x: localCurrentX, y: localCurrentY },
        callback: setLocalValue,
      });
      break;
    case QUESTION_TYPES.SINGLECHOICE:
    case QUESTION_TYPES.SINGLESELECT:
    case QUESTION_TYPES.MULTIPLECHOICE:
      const allOptions = options
        .filter(o => o.value)
        .map(o => o.option)
        .concat(
          otherOptions
            .split('\n')
            .filter(o => !!o)
        );

      await methods.tab(setLocalValue, baseTabSpace + tabSizes.small);
      await drawText(doc, {
        text: `${includeQuestionPrefix ? `Question ${index + 1}: ` : ''}${questionText}`,
        coordinates: { x: localCurrentX, y: localCurrentY },
        methods,
        callback: setLocalValue,
        textStyle: textStyles.bold.subTitle
      });
      if (!!description) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Description: ${description}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
        });
      }

      await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
      await drawText(doc, {
        text: `Selected Answer(s):`,
        coordinates: { x: localCurrentX, y: localCurrentY },
        methods,
        callback: setLocalValue,
      });
      for (let i = 0; i < allOptions.length; i++) {
        const option = allOptions[i];
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.large);
        await drawText(doc, {
          text: `- ${option}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
          textStyle: textStyles.normal.red
        });
      }
      if (!!note) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Note: ${note}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
        });
      }

      break;
    default:
      await methods.tab(setLocalValue, baseTabSpace + tabSizes.small);
      await drawText(doc, {
        text: `${includeQuestionPrefix ? `Question ${index + 1}: ` : ''}${questionText}`,
        coordinates: { x: localCurrentX, y: localCurrentY },
        methods,
        callback: setLocalValue,
        textStyle: textStyles.bold.subTitle
      });

      if (!!description) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Description: ${description}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue
        });
      }

      await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
      await drawText(doc, {
        text: `Answer: ${value || 'NONE'}`,
        coordinates: { x: localCurrentX, y: localCurrentY },
        methods,
        callback: setLocalValue,
        availableSpaceModifier: !!description ? -75 : 0 // Handle Overflow error
      });

      if (!!note) {
        await methods.tab(setLocalValue, baseTabSpace + tabSizes.medium);
        await drawText(doc, {
          text: `Note: ${note}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
          availableSpaceModifier: -75 // Handle Overflow error
        });
      }

      break;
  }
};
const drawCategoryBlock = async (doc, { category, coordinates, methods }) => {
  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;
  }

  await drawBanner(doc, { text: category.title, coordinates: { x: localCurrentX, y: localCurrentY }, methods, callback: setLocalValue });

  for (let i = 0; i < category.items.length; i++) {
    const index = i;
    const item = category.items[index];
    const sections = item.groupSections;

    let questionText = '';

    if (!!item.templateData) {
      questionText = item.templateData.question;
    } else {
      questionText = item.question ? item.question.replace(/[\u0250-\ue007]/g, '') : '';
    }

    switch (item?.type) {
      case QUESTION_TYPES.GROUP:
        await methods.tab(setLocalValue, tabSizes.medium);
        await drawText(doc, {
          text: `Question ${index + 1}: ${questionText}`,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue,
          textStyle: textStyles.bold.subTitle
        });
        for (let j = 0; j < sections.length; j++) {
          const sectionIndex = j;
          const section = sections[sectionIndex];
          const sectionQuestions = gateChecker(section.questions, true);
          let sectionHeading = sections.length > 1 ? `Section ${sectionIndex + 1}: ` : '';

          if (!!section.sectionHeading) {
            sectionHeading += section.sectionHeading;
          } else if (!!section.templateData?.sectionHeading) {
            sectionHeading += section.templateData.sectionHeading;
          }

          if (sectionHeading.length > 0) {
            await methods.tab(setLocalValue, tabSizes.large);
            await drawText(doc, {
              text: sectionHeading,
              coordinates: { x: localCurrentX, y: localCurrentY },
              methods,
              callback: setLocalValue,
              textStyle: textStyles.bold.subTitle
            });
          }

          for (let q = 0; q < sectionQuestions.length; q++) {
            if (!sectionQuestions[q]?.hidden) {
              await drawQuestionBlock(doc, {
                question: sectionQuestions[q],
                index: q,
                includeQuestionPrefix: false,
                coordinates: { x: localCurrentX, y: localCurrentY },
                methods,
                callback: setLocalValue,
                baseTabSpace: tabSizes.large
              });
            }
          }
        }
        break;
      default:
        await drawQuestionBlock(doc, {
          question: item,
          index,
          coordinates: { x: localCurrentX, y: localCurrentY },
          methods,
          callback: setLocalValue
        });
        break;
    }
  }
};
const drawCoverPage = async (doc, { title, coordinates, methods }) => {
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  let localCurrentX = coordinates.x;
  let localCurrentY = coordinates.y;

  const setLocalValue = (x, y) => {
    localCurrentX = x;
    localCurrentY = y;
  }

  await methods.setCoordinates(pageWidth / 2, pageHeight / 2, setLocalValue);
  if (title === contactTitle) {
    await drawText(doc, { text: title, coordinates: { x: localCurrentX, y: localCurrentY }, methods, textStyle: textStyles.bold.title });
  } else {
    const splitTitle = title.split('-');
    await drawText(doc, { text: splitTitle[0], coordinates: { x: localCurrentX, y: localCurrentY - 20 }, methods, textStyle: textStyles.bold.title });
    await drawText(doc, { text: splitTitle[1], coordinates: { x: localCurrentX, y: localCurrentY }, methods, textStyle: textStyles.bold.title });
  }
  await methods.setCoordinates(pageWidth / 2, pageHeight - 15, setLocalValue);
  await drawText(doc, {
    text: 'generated by WRAPT', // Really it was by the jsPDF package and the mad genius of some dude named Drake
    coordinates: {
      x: localCurrentX,
      y: localCurrentY
    },
    methods,
    textStyle: {
      ...textStyles.bold.default,
      textAlign: 'center',
      padding: {
        right: 0,
        left: 0
      }
    }
  });
  await methods.newPage();
};

const drawPDF = async (doc, {
  categories,
  contactinfo,
  directory,
  subDirectory,
  wizards
}) => {
  const pageHeight = doc.internal.pageSize.height;
  let currentX = baseXCoordinate;
  let currentY = baseYCoordinate;
  let coordinates = {
    x: currentX,
    y: currentY
  };
  let onNewPage = false;

  const nextLine = (callback, size = lineSizes.small, tab = tabSizes.start) => {
    currentY += size;
    currentX = tab;

    coordinates = {
      x: currentX,
      y: currentY
    };

    // Include additional space
    if (currentY + padding.bottom > pageHeight) {
      currentY = lineSizes.start;
      onNewPage = true;
      doc.addPage();
    } else if (onNewPage) {
      onNewPage = false;
    }

    if (callback) callback(currentX, currentY);
  };
  const newPage = (callback) => {
    if (!onNewPage) {
      currentY = lineSizes.start;
      currentX = tabSizes.start;
      onNewPage = true;

      coordinates = {
        x: currentX,
        y: currentY
      };

      doc.addPage();

      if (callback) callback(currentX, currentY);
    }
  }
  const tab = (callback, size = tabSizes.small) => {
    currentX += size;

    coordinates = {
      x: currentX,
      y: currentY
    };

    if (callback) callback(currentX, currentY);
  }
  const setCoordinates = (newX, newY, callback) => {
    currentX = newX;
    currentY = newY;
    coordinates = {
      x: newX,
      y: newY
    }

    if (callback) callback(currentX, currentY);
  }

  const methods = {
    nextLine,
    tab,
    setCoordinates,
    newPage
  }
  const mappedCategories = categories.filter(x => x.questions).map((category) => {
    const mappedQuestions = mapQuestionsDefaultData(category.questions, wizards);

    return {
      ...category,
      questions: mappedQuestions
    };
  });
  const inContactInfo = mappedCategories?.length && contactinfo?.categories?.length && mappedCategories[0].categoryId === contactinfo.categories[0].categoryId;

  // DRAW PDF
  await drawCoverPage(doc, { title: inContactInfo ? contactTitle : constructTitle({ directory, subDirectory }), coordinates, methods });
  if (contactinfo?.categories) {
    for (let i = 0; i < contactinfo.categories.length; i++) {
      const cat = contactinfo.categories[i];
      await drawContactInfoBlock(doc, { contactInfoItem: { title: cat.categoryName, items: cat.questions }, methods, coordinates });
    }
  }
  if (mappedCategories?.length && !inContactInfo) {
    for (let i = 0; i < mappedCategories.length; i++) {
      const cat = mappedCategories[i];

      if (!onNewPage) await methods.newPage();

      await drawCategoryBlock(doc, { category: { title: cat.categoryName, items: cat.questions }, methods, coordinates });
    }
  }
};

export default drawPDF;