import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

const useStyles = makeStyles({
  whole: {
    '& $div': {
      justifyContent: 'flex-end',
      '& $input': {
        width: '60%',
      },
    },
    '& $p': {
      alignSelf: 'center',
    },
  },
  decimal: {
    '& $div': {
      justifyContent: 'flex-end',
      '& $input': {
        width: '75%',
      },
    },
    '& $p': {
      alignSelf: 'center',
    },
  },
});

export const DecimalNumberForm = ({ data, callBack, onNoteEdit, includeNote = true }) => {
  const {
    answer: { value, note: noteValue },
  } = data;
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [noteOverLimit, setNoteOverLimit] = useState(null);

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  useEffect(() => {
    const valuesData = {
      whole: value?.split('.')[0] || '',
      decimal: value?.split('.')[1] || '',
    };
    setValues(valuesData);
  }, [value, noteValue]);

  const handleChange = (values) => {
    setValues(values);
    if (values.whole && values.decimal) {
      callBack({ id: data.id, answer: `${values.whole}.${values.decimal}` });
    }
  };

  return (
    <Grid container spacing={3} direction='column'>
      <Grid item>
        <TextField
          className={classes.whole}
          style={{ width: 100 }}
          placeholder='00'
          value={values.whole}
          onChange={({ currentTarget }) => {
            handleChange({
              ...values,
              whole: currentTarget.value.replace(/\D/g, ''),
            });
          }}
        />
        .
        <TextField
          className={classes.decimal}
          style={{ width: 30 }}
          placeholder='00'
          value={values.decimal}
          onChange={({ currentTarget }) => {
            handleChange({
              ...values,
              decimal: currentTarget.value.replace(/\D/g, ''),
            });
          }}
        />
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid item style={{ width: '90%' }}>
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            value={noteValue}
            multiline
            style={{ width: '100%' }}
            rows={4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helerText={noteOverLimit || ''}
          />
        </Grid>      
      )}
    </Grid>
  );
};
