import React from 'react';
import { CustomButton } from '../../components/Button';
import { WizardTitle } from './styled';
import { Preloader } from '../../components/Preloader';
import { TextField, Grid } from '@material-ui/core';

const buttonStyle = {
  marginBottom: 10,
  marginLeft: 10
};

export const UserStartActions = ({
  startWithPwsId,
  pwsSearchError,
  pwsSearching,
  pwsSearchResult,
  searchPwsId,
  startNew,
  loading,
}) => {

  const [pwsId, setPwsId] = React.useState('');

  const handlePwsIdChange = (event) => {
    setPwsId(event.target.value);
  }

  if (loading) {
    return <Preloader size={50} />;
  }


  return (
    <>
      <p>
        Get started by searching for your PWS ID
          </p>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <TextField
          id="pws-id"
          error={!!pwsSearchError}
          helperText={pwsSearchError}
          label="PWS ID"
          variant="outlined"
          value={pwsId}
          onChange={handlePwsIdChange}
          size="small"

        />
        <CustomButton
          disabled={pwsSearching}
          onClick={() => {
            searchPwsId({ pwsId });
          }}
          style={buttonStyle}
          color='primary'
          variant='contained'
        >
          Search by PWS ID
      </CustomButton>
      </Grid>
          {pwsSearchResult &&
              <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <p>
        Water Station Found: {pwsSearchResult.waterSystemName}
          </p>
              <CustomButton
                disabled={pwsSearching}
                onClick={() => {
                  startWithPwsId({ pwsId });
                }}
                style={buttonStyle}
                color='primary'
                variant='contained'
              >
                Start with this Water Station
            </CustomButton>
            </Grid>
       
          }
  
      <p>
        Or start new
          </p>
      <CustomButton
        onClick={() => {
          startNew();
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        Start New
      </CustomButton>
    </>
  );
};

export const UserContactInfoActions = ({
  setCurrentBranch,
  isContactInfoPublished,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }

  if (!isContactInfoPublished) {
    return (
      <WizardTitle style={{ color: '#ff9800' }}>
        wizard is not published yet
      </WizardTitle>
    );
  }

  return (
    <>
      {/* <WizardTitle style={{ color: '#4caf50' }}>wizard completed</WizardTitle> */}
      <CustomButton
        onClick={() => {
          setCurrentBranch({
            directory: 'contactinfo',
            sub_directory: 'contactinfo',
          });
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        edit contact information
      </CustomButton>
    </>
  );
};

export const UserDrinkinWaterActions = ({
  setCurrentBranch,
  isDrinkingPublished,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }

  if (!isDrinkingPublished) {
    return (
      <WizardTitle style={{ color: '#ff9800' }}>
        wizard is not published yet
      </WizardTitle>
    );
  }

  return (
    <>
      {/* <WizardTitle style={{ color: '#4caf50' }}>wizard completed</WizardTitle> */}
      <CustomButton
        onClick={() => {
          setCurrentBranch({
            directory: 'drinking',
            sub_directory: 'risk_assessment',
          });
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        edit risk assessment
      </CustomButton>
      <CustomButton
        onClick={() => {
          setCurrentBranch({
            directory: 'drinking',
            sub_directory: 'emergency_response_plan',
          });
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        edit emergency response plan
      </CustomButton>
    </>
  );
};

export const UserWasteWaterActions = ({
  setCurrentBranch,
  isWastePublished,
  loading,
}) => {
  if (loading) {
    return <Preloader size={50} />;
  }

  if (!isWastePublished) {
    return (
      <WizardTitle style={{ color: '#ff9800' }}>
        wizard is not published yet
      </WizardTitle>
    );
  }

  return (
    <>
      {/* <WizardTitle style={{ color: '#4caf50' }}>wizard completed</WizardTitle> */}
      <CustomButton
        onClick={() => {
          setCurrentBranch({
            directory: 'waste',
            sub_directory: 'risk_assessment',
          });
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        edit risk assessment
      </CustomButton>
      <CustomButton
        onClick={() => {
          setCurrentBranch({
            directory: 'waste',
            sub_directory: 'emergency_response_plan',
          });
        }}
        style={buttonStyle}
        color='primary'
        variant='contained'
      >
        edit emergency response plan
      </CustomButton>
    </>
  );
};
