import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {
  INPUT_LIMITS
} from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';
import AdditionalInfoLink from '../../../components/Links/AdditionalInfoLink';

export const BooleanChoiceForm = ({
  data,
  callBack,
  onNoteEdit,
  includeNote = true
}) => {
  const {
    answer: { value: booleanValue, note: noteValue },
  } = data;

  const handleRadioChange = (value) => {
    callBack({ id: data.id, answer: value });
  };

  const [noteOverLimit, setNoteOverLimit] = useState(null);

  const onNoteChange = ({ id, note }) => {
    const {
      MAX_CHARACTERS_UPPER_LIMIT
    } = INPUT_LIMITS;
    setNoteOverLimit(null);
    const reduceNoteLength = () => {
      if (note.length > MAX_CHARACTERS_UPPER_LIMIT) {
        setNoteOverLimit(constructInputLimitErrorMessage(MAX_CHARACTERS_UPPER_LIMIT));
      }
      return note.slice(0, MAX_CHARACTERS_UPPER_LIMIT);
    };
    onNoteEdit({ id, note: reduceNoteLength() });
  };

  return (
    <Grid container direction='column' spacing={3}>
      <Grid item>
        <RadioGroup
          value={booleanValue}
          onChange={({ target }) => {
            handleRadioChange(target.value);
          }}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value='Yes'
            control={<Radio color='primary' />}
            label='Yes'
          />
          <FormControlLabel
            value='No'
            control={<Radio color='primary' />}
            label='No'
          />
          <FormControlLabel
            value='N/A'
            control={<Radio color='primary' />}
            label='N/A'
          />
        </RadioGroup>
      </Grid>
      {data?.additionalLink && <AdditionalInfoLink additionalLink={data.additionalLink} />}
      {includeNote && (
        <Grid item style={{ width: '85%' }}>
          <TextField
            label={data.moreInfoLabel || 'Note (optional)'}
            variant='outlined'
            value={noteValue}
            multiline
            style={{ width: '100%' }}
            rows={4}
            onChange={({ currentTarget }) => {
              onNoteChange({ id: data.id, note: currentTarget.value });
            }}
            error={noteOverLimit}
            helperText={noteOverLimit || ''}
          />
        </Grid>
      )}
    </Grid>
  );
};
