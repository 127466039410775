import {
  userGetDirectories,
  userGetWizardAnswers,
  userGetCategoryAnswers,
  userStartNewWizardAnswers,
  userSearchPwsId,
  userUpdateCategoryAnswers,
  userSetLastVisitedQuestion
} from '../../services/identity';

import { ERROR_TYPES, WIZARD_TYPES } from '../../constants';

export const userSetDirectories = () => async (dispatch) => {
  dispatch({ type: 'SET_USER_DIRECTORIES_START' });
  const directories = await userGetDirectories();
  if (directories && directories.directories && directories.directories.length) {
    const payload = directories.directories.reduce((acc, item) => {
      acc[item.name] = {
        published: item.isPublished,
        id: item.id,
        name: item.name,
        displayName: item.displayName
      };

      acc[item.name].risk_assessment = item.wizards.find(
        (w) => w.name === 'riskassessment'
      );
      acc[item.name].emergency_response_plan = item.wizards.find(
        (w) => w.name === 'emergencyresponseplan'
      );
      acc[item.name].contactinfo = item.wizards.find(
        (w) => w.name === 'contactinfo'
      );

      if (!acc.waste) {
        acc.waste = {};
      }
      if (!acc.drinking) {
        acc.drinking = {};
      }
      if (!acc.contactinfo) {
        acc.contactinfo = {};
      }

      return acc;
    }, {});

    dispatch({
      type: 'SET_USER_DIRECTORIES_SUCCESS',
      payload: {
        ...payload,
        lastLocation: directories.lastLocation,
        hasStarted: directories.hasStarted
      },
    });
  } else {
    dispatch({ type: 'SET_USER_DIRECTORIES_FAIL' });
  }
};

export const getWizardAnswers = ({
  directory,
  sub_directory,
  wizard_id,
}) => async (dispatch) => {
  // dispatch({type: 'SET_USER_WIZARD_ANSWERS_START'});

  const wizardAnswers = await userGetWizardAnswers({ wizard_id });

  dispatch({
    type: 'SET_USER_WIZARD_ANSWERS_SUCCESS',
    payload: {
      directory,
      sub_directory,
      wizardAnswers,
    },
  });
};

export const getAllCategoryAnswersForWizard = ({
  directory,
  sub_directory,
  wizard_id,
  wizard_answers
}) => async (dispatch) => {
  // dispatch({type: 'SET_ALL_ANSWERS_FOR_ALL_WIZARDS_START'});
  let promises = [];
  let category_ids = [];
  let categories = [];
  const wizardAnswers = wizard_answers ?? await userGetWizardAnswers({ wizard_id });

  for(const id of wizardAnswers.categoryOutlines.map(x=>x.categoryId)){
    promises.push(userGetCategoryAnswers({ wizard_id, category_id: id }));
    category_ids.push(id);
  }

  await Promise.all(promises);
  for(var i = 0; i< category_ids.length; i++)
  {
    categories.push({categoryId: category_ids[i], categoryAnswers: await promises[i] });
  }

  dispatch({type: 'SET_ALL_ANSWERS_FOR_ALL_WIZARDS_SUCCESS', payload:{
    directory, 
    sub_directory,
    categories
  }});
};

export const getAllWizardAnswersForDirectory = (directory) => async (dispatch) => {
  const {
    emergencyresponseplan,
    riskassessment,
    contactinfo: contactinfoWizType
  } = WIZARD_TYPES;

  // The directory isn't loaded yet
  if (!directory.name) {
    return;
  } else {
    dispatch({ type: 'GET_USER_DIRECTORIES_START' });

    if (directory[riskassessment] && !directory[riskassessment].wizardAnswers) {
      dispatch(getWizardAnswers({ directory: directory.name, sub_directory: riskassessment, wizard_id: directory[riskassessment].id }))
    }
    if (directory[contactinfoWizType] && !directory[contactinfoWizType].wizardAnswers) {
      dispatch(getWizardAnswers({ directory: directory.name, sub_directory: contactinfoWizType, wizard_id: directory[contactinfoWizType].id }))
    }
    if (directory[emergencyresponseplan] && !directory[emergencyresponseplan].wizardAnswers) {
      dispatch(getWizardAnswers({ directory: directory.name, sub_directory: emergencyresponseplan, wizard_id: directory[emergencyresponseplan].id }))
    }
  }
};

export const getAllCategoryAnswersForDirectory = (directory) => async (dispatch) => {
  const {
    emergencyresponseplan,
    riskassessment,
    contactinfo: contactinfoWizType
  } = WIZARD_TYPES;

  // The directory isn't loaded yet
  if (!directory.name) {
    return;
  } else {
    if (directory[riskassessment] && directory[riskassessment].wizardAnswers && !directory[riskassessment].wizardAnswers.categories?.length)
      await dispatch(getAllCategoryAnswersForWizard({ 
        directory: directory.name,
        sub_directory: riskassessment,
        wizard_id: directory[riskassessment].id,
        wizard_answers: directory[riskassessment].wizardAnswers
      }));
    if (directory[contactinfoWizType] && directory[contactinfoWizType].wizardAnswers && !directory[contactinfoWizType].wizardAnswers.categories?.length)
      await dispatch(getAllCategoryAnswersForWizard({ 
        directory: directory.name,
        sub_directory: contactinfoWizType,
        wizard_id: directory[contactinfoWizType].id,
        wizard_answers: directory[contactinfoWizType].wizardAnswers
      }))
    if (directory[emergencyresponseplan] && directory[emergencyresponseplan].wizardAnswers && !directory[emergencyresponseplan].wizardAnswers.categories?.length)
      await dispatch(getAllCategoryAnswersForWizard({ 
        directory: directory.name,
        sub_directory: emergencyresponseplan,
        wizard_id: directory[emergencyresponseplan].id,
        wizard_answers: directory[emergencyresponseplan].wizardAnswers
      }))

    dispatch({ type: 'GET_USER_DIRECTORIES_SUCCESS' })
  }
};

export const startNewWizardAnswersByPwsId = ({
  pwsId
}) => async (dispatch) => {
  dispatch({ type: 'START_NEW_USER_ANSWERS_START' });

  try {
    await userStartNewWizardAnswers({pwsId});

    dispatch({
      type: 'START_NEW_USER_ANSWERS_SUCCESS'
    });
  } catch (e) {
    let errorMessage = "Error locating PWS ID";
    if (e && e.errorType === ERROR_TYPES.NOT_FOUND) {
      errorMessage = 'PWS ID not found';
      
    } 
    dispatch({
      type: 'START_NEW_USER_ANSWERS_FAIL',
      payload: {
       errorMessage
      }
    });
  }
};

export const searchPwsId = ({
  pwsId
}) => async (dispatch) => {
  dispatch({ type: 'SEARCH_PWS_ID_START' });

  try {
    const result = await userSearchPwsId({pwsId});

    dispatch({
      type: 'SEARCH_PWS_ID_SUCCESS',
      payload: {
        pwsSearchResult: result
      }
    });
  } catch (e) {
    let errorMessage = "Error locating PWS ID";
    if (e && e.errorType === ERROR_TYPES.NOT_FOUND) {
      errorMessage = 'PWS ID not found';
      
    } 
    dispatch({
      type: 'SEARCH_PWS_ID_FAIL',
      payload: {
       errorMessage
      }
    });
  }
};

export const startNewWizardAnswers = () => (dispatch) => {
  dispatch({
    type: 'START_NEW_USER_ANSWERS_SUCCESS'
  });
};

export const updateCategoryAnswers = ({
  directory,
  sub_directory,
  questions,
  wizard_id,
  category_id, 
  category_name
}) => async (dispatch, getState) => {
  dispatch({ type: 'UPDATE_USER_ANSWERS_START' });

  const questionsWithLastLocation = questions.filter(q => !!q?.lastLocation);
  const mostRecentQuestionWithLastLocation = questionsWithLastLocation[questionsWithLastLocation.length - 1];

  const newWizardAnswers = {
    lastLocationQuestionId: mostRecentQuestionWithLastLocation.questionId,
    wizardId: wizard_id,
    categoryId: category_id,
    categoryName: category_name,
    questions: questions
  };
  await userUpdateCategoryAnswers({categoryAnswers: newWizardAnswers});
  await userSetLastVisitedQuestion({wizardId: wizard_id, questionId : mostRecentQuestionWithLastLocation.questionId, categoryId: category_id});

  dispatch({
    type: 'UPDATE_USER_ANSWERS_SUCCESS',
    payload: {
      category_id,
      directory,
      sub_directory,
      categoryAnswers: questions,
    },
  });

  dispatch(getWizardAnswers({ directory, sub_directory, wizard_id }));
};

export const updateMultipleCategoryAnswers = ({
  directory,
  sub_directory,
  categories,
  wizard_id,
}) => async (dispatch, getState) => {
  dispatch({ type: 'UPDATE_USER_ANSWERS_START' });

  await Promise.all(categories.map(async (category) => {
    const newWizardAnswers = {
      wizardId: wizard_id,
      categoryId: category.categoryId,
      categoryName: category.categoryName,
      questions: category.questions
    }

    return await userUpdateCategoryAnswers({categoryAnswers: newWizardAnswers})
  }));

  dispatch({ 
    type: 'UPDATE_All_USER_ANSWERS_SUCCESS',
    payload: {
      directory,
      sub_directory,
      categories
    },
  });

  dispatch(getWizardAnswers({ directory, sub_directory, wizard_id }));
};