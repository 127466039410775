import React from 'react';
import { 
    Grid,
    TextField
} from '@material-ui/core';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import {
    GridContainer,
    GridItemIcon,
    AddOptionButton,
    MultiChoiceButtonContainer,
    MultiChoiceErrorText,
} from './styled';
import { INPUT_LIMITS  } from '../../../constants';
import constructInputLimitErrorMessage from '../../../helpers/constructInputLimitErrorMessage';

const OptionsForm = ({
  options,
  onOptionChange,
  onOptionAdd,
  onOptionRemove,
  active,
  isMultyChoiceError,
  optionError,
}) => {
  const helperText = ({ value, id }) => {
    if (isMultyChoiceError && value === '') {
      return 'please fill this option';
    }
    if (optionError && optionError.id === id) {
      return constructInputLimitErrorMessage(INPUT_LIMITS.MAX_CHARACTERS_UPPER_LIMIT);
    }
  };

  return (
    <div>
      <div>
        {options?.length > 0 &&
          options.map((option, i) => {
            return (
              <GridContainer container>
                <GridItemIcon item>
                  <FiberManualRecordOutlinedIcon />
                </GridItemIcon>
                <Grid item style={{ width: '80%' }}>
                  <TextField
                    value={option.value}
                    onChange={({ currentTarget: { value } }) => {
                      if (active) {
                        onOptionChange({ ...option, value });
                      }
                    }}
                    error={
                      (isMultyChoiceError && option.value === '') ||
                      (optionError && optionError.id === option.id)
                    }
                    helperText={helperText({
                      value: option.value,
                      id: option.id,
                    })}
                    disabled={!active}
                    label={`Option ${i + 1}`}
                    style={{ margin: 8 }}
                    fullWidth
                    margin='normal'
                  />
                </Grid>
                <GridItemIcon
                  item
                  style={{ justifyContent: 'center', cursor: 'pointer' }}
                >
                  <DeleteOutlinedIcon
                    onClick={() => {
                      if (active) {
                        onOptionRemove(option.id);
                      }
                    }}
                  />
                </GridItemIcon>
              </GridContainer>
            );
          })}
      </div>
      <MultiChoiceButtonContainer>
        <AddOptionButton
          color='primary'
          size='small'
          variant='contained'
          disabled={!active}
          onClick={onOptionAdd}
        >
          add option
        </AddOptionButton>
        {isMultyChoiceError && (
          <MultiChoiceErrorText>
            You need to add at least 2 filled options
          </MultiChoiceErrorText>
        )}
      </MultiChoiceButtonContainer>
    </div>
  );
};

export default OptionsForm;