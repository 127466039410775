import { push } from 'connected-react-router';
//import { login } from '../../services/identity';
//import { logout } from '../../services/identity';
// import {
//   login as loginActionCreator,
//   logout as logoutActionCreator,
//   resetCurrentUser
// } from '../currentUser/actions';

//import { AppState } from '..';

export const init = () => async (dispatch, getState) => {
  try {
    //await dispatch(push('/somePage'));
    //console.log('call some action', getState());
    //await dispatch(login());
    //await dispatch(fetchAssociations());
  } catch (e) {
    // console.error(e);
    // const { pathname } = getState().router.location;
    // if (pathname !== '/signup' && pathname !== '/resetpassword') {
    //   await dispatch(replace('/login'));
    // }
  }
};

export const fetchLogout = () => async (dispatch) => {
  //await logout();
  await dispatch(push('/login'));
};

export const fetchLogin = (username, password) => async (dispatch) => {
  try {
    await dispatch(init());
  } catch {}
};
