import React from 'react';
import { QuestionForm } from '../QuestionForm';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { QUESTION_DEFAULT_FROM_TYPE_FILTERS } from '../../../constants';

export const QuestionsList = ({
  forms,
  categoryId,
  wizardId,
  wizards,
  onSortEnd,
  onEdit,
  setActive,
  onSave,
  onDelete,
  submitted,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((el, i) => {
      el.orderindex = i;
      return el;
    });
    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(forms, result.source.index, result.destination.index);
    onSortEnd(items);
  };

  const createDefaultFromOptions = (index, type) => {
    const typeFilters = QUESTION_DEFAULT_FROM_TYPE_FILTERS[type];

    if (!typeFilters) {
      return [];
    }

    const itemsBeforeCurrent = forms.map((form, fIndex) => ({ ...form, index: fIndex })).slice(0, index);
    const itemsFilteredBasedOnValidTypes = itemsBeforeCurrent.filter((item) => typeFilters.includes(item.type));

    return itemsFilteredBasedOnValidTypes;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {forms.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <QuestionForm
                      categoryId={categoryId}
                      wizardId={wizardId}
                      wizards={wizards}
                      onEdit={onEdit}
                      onSave={onSave}
                      setActive={setActive}
                      onDelete={onDelete}
                      data={item}
                      i={index + 1}
                      index={index}
                      key={item.id}
                      submitted={submitted}
                      defaultFromOptions={createDefaultFromOptions(index, item.type)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
